export const containerStyles = {
  width: "100%",
  maxWidth: "1148px",
  margin: "auto",
  height: "auto",
}

export const typeTabs = {
  fontWeight: 700,
  fontFamily: "Montserrat",
  color: "#000",
  textTransform: "none",
};

export const tabStyles = {
  marginBottom: "21px",
  "& .MuiTabs-scroller .MuiTabs-flexContainer": {
    justifyContent: "flex-end"
  }
}