import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button from "@mui/material/Button";
import FileViewer from "react-file-viewer";
import CancelIcon from "@mui/icons-material/Cancel";
import "../DesignSystem/styles/fileviewerCss.css";
import { Icon, IconButton } from "@mui/material";
import { Logger } from "logging-library";
import { CustomErrorComponent } from "custom-error";
import ImageZoom from "./ImageZoom";
import { btnExitStyles } from "./styles/ModalProfile.styles";
import CloseIcon from "@mui/icons-material/Close";
import RatingStars from "./RatingStars";
import ExpandedImg from "./ExpandedImg";
import ReactImageMagnify from "react-image-magnify";
import { borderRadius } from "@mui/system";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { ProgressBar } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { SelectionMode } from "@react-pdf-viewer/selection-mode";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  borderRadius: "10px",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function FileViewerModalDelivery({
  visibility,
  url,
  type,
  nameFile,
  styles,
  profileIMG,
  img,
  stars,
  email,
  address,
  phone_number,
  vehiculo,
  referralName,
}) {
  // console.log("🚀 ~ file: DeliveryFileViewer.jsx ~ line 36 ~  img, stars, email, address, phone_number, vehiculo, referralName",  img, stars, email, address, phone_number, vehiculo, referralName)
  const [open, setOpen] = useState(visibility);

  useEffect(() => {
    setOpen(visibility);
  }, [visibility]);

  const handleClose = () => {
    setOpen(false);
  };

  const toolbarPluginInstance = toolbarPlugin({
    searchPlugin: {
      keyword: "PDF",
    },
    selectionModePlugin: {
      selectionMode: SelectionMode.Hand,
    },
  });
  const { Toolbar } = toolbarPluginInstance;
  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: "80%", height: "90%" }}>
          <div
            style={{
              width: "95%",
              height: "25%",
              flexDirection: "row",
              display: "flex",
              backgroundColor: "#F5F8FF",
              marginBottom: "2%",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                width: "50%",
                height: "100%",
                flexDirection: "row",
                display: "flex",
              }}
            >
              <div
                style={{
                  width: "40%",
                  height: "auto",
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  padding: "14px",
                }}
              >
                <ReactImageMagnify
                  {...{
                    smallImage: {
                      src: profileIMG === null ? img : profileIMG,
                      height: 190,
                      width: 150,
                    },
                    largeImage: {
                      src: profileIMG === null ? img : profileIMG,
                      height: 1000,
                      width: 800,
                      borderRadius: "25%",
                      display: "flex",
                    },
                    enlargedImageContainerDimensions: {
                      width: "200%",
                      height: 500,
                    },
                  }}
                />
              </div>
              &nbsp; &nbsp;
              <div
                style={{
                  width: "60%",
                  height: "auto",
                  flexDirection: "column",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <p>
                  Documento:{" "}
                  {nameFile === "circulation"
                    ? "TARJETA DE CIRCULACIÓN"
                    : nameFile === "ine"
                    ? "INDENTIFICACIÓN"
                    : nameFile === "drive"
                    ? "LICENCIA DE PARA CONDUCIR"
                    : nameFile === "back_ine"
                    ? "PARTE TRASERA INE"
                    : nameFile === "rfc"
                    ? "RFC"
                    : nameFile}
                </p>

                <p className={styles.info}>{email || "Correo no disponible"}</p>
                <p className={styles.info}>
                  {address || "Dirección no disponible"}
                </p>
                <p className={styles.info}>
                  {phone_number || "Número celular no disponible"}
                </p>
                <p className={styles.info}>
                  {vehiculo || "Vehiculo no disponible"}
                </p>
                <p className={styles.info}>
                  {referralName || "No ha sido referido por nadie"}
                </p>
              </div>
            </div>

            <div
              style={{
                width: "30%",
                height: "10%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton sx={btnExitStyles} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          {type === "pdf" ? (
            <div
              style={{
                height: "60%",
                justifyContent: "center",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "110%",
                  }}
                >
                  <Toolbar />
                  <Viewer
                    theme="auto"
                    fileUrl={url}
                    plugins={[toolbarPluginInstance]}
                    renderLoader={(percentages: number) => (
                      <div style={{ width: "240px" }}>
                        <ProgressBar progress={Math.round(percentages)} />
                      </div>
                    )}
                  />
                </div>
              </Worker>
            </div>
          ) : (
            <div
              style={{
                marginLeft: "7%",
                height: "60%",
                width: "80%",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                zIndex: -1,
                position: "absolute",
              }}
            >
              <ImageZoom path={url} />
            </div>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
}
