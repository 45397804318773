import { Bar } from "react-chartjs-2";

export default function StatementAccountChart({info}) {

  const data = {
    labels: ["00", "00", "00", "00", "00", "00"],
    datasets: [
      {
        /* label: "Mujeres", */
        label: "Personas",
        backgroundColor: ["#BDBDBD"],
        borderColor: "none",
        maxBarThickness: 9,
        barThickness: 20,
        data: [126.19, 50.12, 89.6, 89.6, 23.22, 66.54],
        /* data: [126.19, 50.12, 89.6, 89.6, 23.22, 66.54], */
      },
      /* {
        label: "Hombres",
        backgroundColor: "#004990",
        borderColor: "none",
        maxBarThickness: 7,
        barThickness: 20,
        data: [327.16, 126.19, 50.12, 89.6, 23.22, 66.54],
      } */
    ],
  };

  const opciones = {
    maintainAspecRatio: false,
    responsive: true,
    cutout: "90%",
    plugins: {
      tooltip: {
        // usePointStyle: true,
        // backgroundColor: "#FFF",
        boxWidth: 10,
        boxHeight: 10,
        textDirection: "rtl",
        bodyColor: "#FFF",
        caretSize: 10,
        bodyFont: {
          family: "Montserrat"
        }
      },
      legend: {
        display: false,
        labels:{
          font: {
            size: 14,
            family: "Montserrat"
          }
        }
      },
    },
  };

  return (
    <div style={{ width: "100%", height: "393px", background: "#FFFFFF", display: "flex", justifyContent: "center", alignItems: "center", padding: "0px 20px"}}>
      <Bar data={data} options={opciones} />
    </div>
  );
}
