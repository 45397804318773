import { makeStyles } from "@mui/styles";

export const container = {
  minHeight: "80vh",
  maxHeight: "99.99vh",
  backgroundColor: "#FFF",
  display: "grid",
  gridTemplateColumns: "1fr 1.25fr",
};

export const outlineBtn = {
  color: "#EA5B13",
  borderRadius: "10px",
  height: "45px",
  marginTop: "20px",
  fontFamily: "Montserrat",
  fontSize: "1.085rem",
  fontWeight: 500,
  lineHeight: "29px",
};

export const fieldBtn = {
  borderRadius: "10px",
  height: "45px",
  marginTop: "20px",
  fontFamily: "Montserrat",
  fontSize: "1.085rem",
  fontWeight: 500,
  lineHeight: "29px",
};

const useStyles = makeStyles((theme) => ({
  blueContainer: {
    borderRadius: "0px 40px 40px 0px",
    backgroundColor: "#004990",
    //minHeight: "100vh",
    minHeight: "90vh",
    maxHeight: "99.99vh",
    [theme.breakpoints.up("md")]: {
      minHeight: "90vh",
      maxHeight: "99.99vh",
    },
  },
  rightContainer: {
    minHeight: "80vh",
    maxHeight: "99.99vh",
    backgroundColor: "#FFF",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  backImage: {
    minHeight: "80vh",
    maxHeight: "99.99vh",
    minWidth: "100%",
    resizeTo: "stretch",
    //minWidth: "30vw"
  },
  title: {
    fontWeight: 700,
    fontSize: "3rem",
    lineHeight: "59px",
    color: "#333333",
    textAlign: "left",
    marginBottom: "20px",
    [theme.breakpoints.up("md")]: {
      fontSize: "2.85rem",
    },
  },
  subtitle: {
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: "29px",
    textAlign: "left",
  },
  link: {
    color: theme.palette.secondary.main,
    fontWeight: 700,
    textDecoration: "none",
    textAlign: "left",
    fontSize: "1rem",
    marginTop: "5px",
    lineHeight: "22px",
    "&:hover": {
      color: theme.palette.secondary.dark,
    },
  },
  formStyles: {
    width: "60%",
    minHeight: "70vh",
    maxHeight: "70vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  logo: {
    position: "absolute",
    left: "4%",
    right: "51.14%",
    top: "79%",
    bottom: "4.6%",
    height: "124px",
    width: "302px",
    [theme.breakpoints.up("md")]: {
      height: "100px",
      width: "250px",
    },
  },
}));

export { useStyles };
