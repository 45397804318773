import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles({
  root: ({ widthBtn }) => ({
    width: widthBtn,
    height: "36px",
  }),
});

export default function FlatButton({
  title,
  typeButton,
  variant,
  icon,
  iconEnd,
  widthBtn,
  onClick,
  customStyles,
  isDisable,
}) {
  const classes = useStyles({ widthBtn });

  return (
    <Button
      sx={customStyles}
      variant={variant}
      color="secondary"
      type={typeButton}
      className={classes.root}
      startIcon={icon}
      endIcon={iconEnd}
      onClick={onClick}
      disabled={isDisable}
    >
      {title}
    </Button>
  );
}

FlatButton.defaultProps = {
  icon: null,
  iconEnd: null,
  onClick: () => null,
  typeButton: "button",
  variant: "contained",
  widthBtn: "200px",
  customStyles: {},
};

FlatButton.propTypes = {
  icon: PropTypes.element,
  iconEnd: PropTypes.element,
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  typeButton: PropTypes.string,
  variant: PropTypes.string,
  widthBtn: PropTypes.string,
  customStyles: PropTypes.object,
};
