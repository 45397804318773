import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "20px",
    width: "709px",
    height: "418px",
    background: "#FFFFFF",
    border: "1px solid #F2F2F2",
    borderRadius: "10px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 600,
    textAlign: "left",
  },
  subtitleChart: {
    fontSize: "0.875rem",
    fontWeight: 400,
    width: "190px",
    textAlign: "left",
  },
  rectext: {
    color: "#828282",
    fontSize: "0.875rem",
  },
  rectangle: {
    width: "20px",
    height: "20px",
    background: "#004990",
  },
  rectangleM: {
    width: "20px",
    height: "20px",
    background: "#BDBDBD",
  },
  container: {
    display: "flex",
    justifyContent: "space-between",
    height: "80px",
    width: "626px",
    borderBottom: "1px solid #E7E7FC",
  },
  reactangleContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "102px",
  },
  barChartInfo: {
    padding: "31px 41px 30px 42px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexDirection: "column",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
}));

export { useStyles };
