import Box from '@mui/material/Box';
import BreadCrumb from './BreadCrumb';
import { containerStyles, headerContainerStyles} from "./styles/BasicHeader.styles";

export default function BasicHeader({children, tab}){

  return(
    <Box sx={containerStyles}>
      <Box sx={headerContainerStyles}>  
        <BreadCrumb/>
        {tab}
      </Box>
      {children}
    </Box>
  );
}