import { GetUserInfoService, getPhotoProfileService, getListEmployeesService, searchByUserNameService, getDeliveryRatingService, getShopsByAdviserService, getCollectsByDeliveryService } from "../Infrastructure/User.service";

export async function GetUserInfoLogic(id) {
 
  
  return GetUserInfoService(id);
}

export async function getPhotoProfileLogic(url){
  return getPhotoProfileService(url);
}

export async function getListEmployeesLogic(info){
  return getListEmployeesService(info);
}

export async function searchByUserNameLogic(info){
  return searchByUserNameService(info);
}

export async function getDeliveryRatingLogic(id){
  return getDeliveryRatingService(id);
}

export async function getCollectsByDeliveryLogic(data){
  return getCollectsByDeliveryService(data);
}

export async function getShopsByAdviserLogic(data){
  return getShopsByAdviserService(data);
}