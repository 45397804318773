import { useState } from "react";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import arrowDown from "../../assets/arrowDown.svg";
import arrowUp from "../../assets/arrowUp.svg";
import FlatButton from "../../DesignSystem/FlatButton";
import ProductCard from "../../DesignSystem/ProductCard";
import iconBag from "../../assets/shoppingBag.svg";

const productsContainer = {
  background: "white",
  borderRadius: {xs: "0px", md: "10px"},
  maxWidth: "709px",
  minHeight: "265px",
  width: "100%",
  padding: { xs: "60px 0px", sm: "20px 0px", md: "18px 40px 18px" },
  marginTop: { xs: "0px", md: "20px", lg: "20px" },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const hiddenButton = {
  color: "#EA5B13",
  fontWeight: 700,
  fontSize: "0.875rem",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1.20fr 2fr",
    justifyItems: "stretch",
    alignItems: "stretch",
    width: "90%",
    position: "relative",
    top: "16%",
    left: "13.5%",
    marginTop: "auto",
    marginLeft: "-50px",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
    /* [theme.breakpoints.down("md")]:{
      postition: "static",
      alignSelf: "start",
      //width: "100%",
    } */
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  productsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  businessImage: {
    borderRadius: "50%",
    objectFit: "cover",
    width: 66,
    height: 66,
    maxWidth: 66,
    maxHeight: 66,
    marginRight: "20px",
  },
  iconBag: { marginRight: "20px" },
  subtitle: {
    color: "#333333",
    fontWeight: 400,
    fontSize: "1.5rem",
    marginBottom: "10px",
  },
  listProductsDesktop: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "20px 60px",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  listProductsMobile: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: "20px 60px",
    },
  },
  products: { height: "265px" },
  address: {
    height: "46px",
    width: "311px",
    marginTop: "10px",
  },
  offersContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    width: "100%",
    maxWidth: "710px",
    height: "auto",
    minHeight: "312px",
    background: "#FFFFFF",
    padding: "18px 45px 23px",
    marginTop: 20,
    borderRadius: "10px",
  },
  titleInfo: {
    lineHeight: "29.05px",
    fontWeight: 600,
    color: "#333333",
    fontSize: "1.5rem",
  },
}));

export default function BusinessProducts({ business }) {
  const classes = useStyles();
  const [subProductOpen, setProductOpen] = useState(false);

  const handleContentProducts = () => {
    setProductOpen(!subProductOpen);
  };

  return (
    <Box sx={productsContainer}>
      <div className={classes.productsHeader}>
        <div className={classes.header}>
          <img src={iconBag} alt="icon" className={classes.iconBag} />
          <h2>Productos</h2>
        </div>
        <FlatButton
          title={subProductOpen ? "Ver menos" : "Ver más"}
          variant="text"
          widthBtn="140px"
          customStyles={hiddenButton}
          onClick={handleContentProducts}
          iconEnd={
            <img src={subProductOpen ? arrowUp : arrowDown} alt="iconArrow" />
          }
        />
      </div>
      <Divider sx={{ margin: "10px 0px", display: {xs:"none", md:"flex"} }} />
      <h3 className={classes.subtitle}> </h3>

      <Box className={classes.listProductsDesktop}>
        {business.products.items.map((product, index) =>
          index < 2 ? (
            <ProductCard
              key={index}
              id={index}
              name={product.product_name}
              description={product.description}
              price={product.price}
              image={product.url_image}
            />
          ) : (
            <Collapse key={index} in={subProductOpen} timeout="auto" unmountOnExit>
              <ProductCard
                id={index}
                name={product.product_name}
                description={product.description}
                price={product.price}
                image={product.url_image}
              />
            </Collapse>
          )
        )}
      </Box>
      <Box className={classes.listProductsMobile}>
        {business.products.items.map((product, index) => (
          <ProductCard
            key={index}
            id={index}
            name={product.product_name}
            description={product.description}
            price={product.price}
            image={product.url_image}
          />
        ))}
      </Box>
    </Box>
  );
}
