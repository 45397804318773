import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";
import iconLocation from "../../assets/iconLocation.svg";
import arrowDown from "../../assets/arrowDown.svg";
import arrowUp from "../../assets/arrowUp.svg";
import FlatButton from "../../DesignSystem/FlatButton";
import { getGeoLocationShop } from "../Infrastructure/Business.presenter";
import MapContainer from "../../DesignSystem/Map";
import { useStyles, locationContainer, hiddenButton, simulatorMaps } from "./styles/BusinessLocation.styles";

export default function BusinessLocation({id,address}) {
  const classes = useStyles();
  const [coords, setCoords] = useState({lat: 0, lng: 0});
  const [subLocationOpen, setLocationOpen] = useState(false);
  const handleContentLocation = () => {
    setLocationOpen(!subLocationOpen);
  };

  useEffect(() => {
    if (id) {
      getLocation(id);
    }
  }, [id]);

  async function getLocation(id){
    try {
      const {lat, lng} = await getGeoLocationShop(id);
      setCoords({lat, lng})
    } catch (error) {
      console.log("Error al obtener coordenadas", error);
    }
  }

  return (
    <Box sx={locationContainer}>
      <div className={classes.productsHeader}>
        <div className={classes.header}>
          <img src={iconLocation} alt="iconLocation" className={classes.iconBag}/>
          <h2>Ubicación</h2>
        </div>
        <FlatButton
          title={subLocationOpen ? "Ver menos" : "Ver más"}
          variant="text"
          widthBtn="140px"
          customStyles={hiddenButton}
          onClick={handleContentLocation}
          iconEnd={
            <img src={subLocationOpen ? arrowUp : arrowDown} alt="iconArrow" />
          }
        />
      </div>
      <p className={classes.address}>{address}</p>
      <Collapse in={subLocationOpen} timeout="auto" unmountOnExit>
        <Divider sx={{ margin: "20px 0px" }} />
          <Box sx={simulatorMaps}>
            <MapContainer lat={coords.lat} lng={coords.lng}/>
          </Box>
      </Collapse>
    </Box>
  );
}