import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import ListItemText from "@mui/material/ListItemText";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import FolderIcon from "@mui/icons-material/Folder";
import PreviewIcon from "@mui/icons-material/Preview";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";
import GetDocuments from "../hooks/getDocsS3";
/* import FileViewer from "react-file-viewer"; */
import AlertDialog from "./BusinessModalAccept";
import { API, graphqlOperation, Storage } from "aws-amplify";
import VerifiedIcon from "@mui/icons-material/Verified";
import DoNotTouchIcon from "@mui/icons-material/DoNotTouch";
import CircularProgress from "@mui/material/CircularProgress";
import { Alert, Button, Divider, Snackbar, Stack, Switch } from "@mui/material";
import {
  updateAccountDocs,
  CreateHistoryLevel,
  updateDeliveryStatusApproved,
} from "../graphql/myMutations";
import { listApproved } from "../graphql/myQueries";
import DatePiker from "./DatePicker";
import FileViewerModalDelivery from "./DeliveryFileViewer";

/* import CalendarTodayIcon from "@mui/icons-material/CalendarToday"; */

export default function ControlledAccordions(docs) {
  // console.log("🚀 ~ file: Accordion.jsx ~ line 39 ~ ControlledAccordions ~ docs", docs)
  const { getDocs, getDocUri } = GetDocuments();
  const [expanded, setExpanded] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [uri, setUri] = React.useState("");
  const [typeFile, setTypeFile] = React.useState("");
  const [nameDocS3, setNameDocS3] = React.useState("");
  /*   const [docName, setNameDoc] = React.useState(false) */
  const [complete, setComplete] = React.useState(false);
  const [chargingDocs, setChargingDocs] = React.useState(false);
  const [validated, setValidated] = React.useState(false);
  const [Snack, setSnack] = React.useState(false);
  const [dateStart, setDateStart] = React.useState("");
  const [dateEnd, setDateEnd] = React.useState("");
  const [gerApproved, setApproved] = React.useState(false);
  const [checked, setChecked] = React.useState(true);
  const [deliveryStatus, setDeliveryStatus] = React.useState(false);
  const [machine, setTypeVehicle] = React.useState("");
  const [driveDate, setValueDrive] = React.useState(new Date());
  const [ineDate, setValueINE] = React.useState(new Date());

  const date = new Date();
  const stylesToHeader = docs.styles;
  const imageBd = docs.profileIMG;
  const defaultImg = docs.img;

  var documents = docs.data;

  var user = docs.user;
  var typeVehicle = docs.vehicle;
  const stars = docs.stars;
  const email = docs.email;
  const address = docs.address;
  const phone_number = docs.phone_number;
  const vehiculo = docs.vehiculo;
  const referralName = docs.referralName;
  const dataBank = docs.registerDataBank;

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#E82E19"),

    backgroundColor: "#E82E19",
    "&:hover": {
      backgroundColor: "#E82E19",
    },
  }));

  const Revalidatebtn = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#F5F8FF"),
    width: "100%",
    marginLeft: "-10%",
    backgroundColor: "#F5F8FF",
    "&:hover": {
      backgroundColor: "#F5F8FF",
    },
  }));
  const openAlert = () => {
    setSnack(true);
  };
  const closeSnak = (ev, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnack(false);
    setDeliveryStatus(false);
  };
  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  const CntList = styled("div")(({ theme }) => ({
    backgroundColor: "#F5F8FF",
    borderRadius: "5%",
    width: 700,
  }));

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  async function getDoc(uri) {
    const res = await getDocs(uri);
    downloadBlob(res.Body, uri);
  }

  async function OnchangeModalView(uri, nameDoc) {
    setNameDocS3(nameDoc);
    const res = await getDocUri(uri);
    const resType = await getDocs(uri);
    console.log(resType.Body.type);
    if (resType.Body.type === "application/pdf") {
      setOpen(false);
      setTypeFile("pdf");
    } else if (
      resType.Body.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setOpen(false);
      setTypeFile("xlsx");
    } else if (resType.Body.type === "image/jpeg") {
      setOpen(false);
      setTypeFile("jpeg");
    } else if (resType.Body.type === "image/jpg") {
      setOpen(false);
      setTypeFile("jpg");
    } else if (resType.Body.type === "image/png") {
      setOpen(false);
      setTypeFile("png");
    } else if (resType.Body.type === "image/gif") {
      setOpen(false);
      setTypeFile("gif");
    } else if (resType.Body.type === "image/bmp") {
      setOpen(false);
      setTypeFile("bmp");
    } else {
      setOpen(false);
      setTypeFile("");
    }

    setOpen(true);
    setUri(res);
  }

  async function uploadFile(e, fileNam) {
    setComplete(false);
    const responseUri = e.target.files[0];

    const res = await Storage.put(fileNam, responseUri, {
      contentType: e.target.files[0].type,
    });

    if (res.key != "") {
      setComplete(true);
    }
  }

  async function refreshFiles(docs) {
    setChargingDocs(true);
    var newDocs = [];
    for (var i in docs) {
      newDocs.push(JSON.stringify(docs[i]));
    }
    var data = {
      id: user,
      delivery_docs: newDocs,
    };

    try {
      const refresh = await API.graphql(
        graphqlOperation(updateAccountDocs, { input: data })
      );

      setChargingDocs(false);
      await checkDocumentation();
      openAlert();
      /*       await changeBtn(true); */
    } catch (e) {
      setChargingDocs(false);
      console.log(e);
    }
  }

  async function validateItem(item, msj) {
    var arreTemp = [];
    var value = item;

    for (var i = 0; i < documents.length; i++) {
      arreTemp.push(documents[i]);
    }
    for (var i = 0; i < arreTemp.length; i++) {
      if (arreTemp[i].doc === value.doc) {
        arreTemp[i].status = msj;

        if (arreTemp[i].doc === "ine") {
          arreTemp[i] = {
            doc: arreTemp[i].doc,
            status: arreTemp[i].status,
            url: arreTemp[i].url,
            dateExpiration: ineDate.toISOString(),
          };
        } else if (arreTemp[i].doc === "drive") {
          arreTemp[i] = {
            doc: arreTemp[i].doc,
            status: arreTemp[i].status,
            url: arreTemp[i].url,
            dateExpiration: driveDate.toISOString(),
          };
        }
      }
    }

    await refreshFiles(arreTemp);
  }

  const changeSwitch = async (event) => {
    if (dataBank != null && dataBank != "") {
      await registerHistoryLevel();
      await getApproved();
      setChecked(event.target.checked);
    } else {
      alert(
        "No puedes activarlo si no tiene datos bancarios registrados previamente..."
      );
    }
  };

  async function checkDocumentation() {
    var circulation = "",
      drive = "",
      rfc = "",
      ine = "",
      backToIne = "";

    for (var i in documents) {
      if (documents[i].doc === "circulation") {
        if (documents[i].status === "validated") {
          circulation = "validated";
        }
      } else if (documents[i].doc === "drive") {
        if (documents[i].status === "validated") {
          drive = "validated";
        }
      } else if (documents[i].doc === "rfc") {
        if (documents[i].status === "validated") {
          rfc = "validated";
        }
      } else if (documents[i].doc === "ine") {
        if (documents[i].status === "validated") {
          ine = "validated";
        }
      } else if (documents[i].doc === "back-ine") {
        if (documents[i].status === "validated") {
          backToIne = "validated";
        }
      }
    }

    if (typeVehicle != "Bicicleta") {
      // console.log("🚀 ~ file: Accordion.jsx ~ line 286 ~ checkDocumentation ~ typeVehicle", typeVehicle)

      if (
        circulation !== "" &&
        drive !== "" &&
        ine !== "" &&
        backToIne !== ""
      ) {
        setValidated(true);
        setTypeVehicle("/Motorizado");
      } else {
        setValidated(false);
        setTypeVehicle("/Motorizado");
        // console.log("Faltan documentos por validar o agregar  motorizado");
      }
    } else {
      if (ine !== "" && backToIne !== "") {
        setValidated(true);
        /*         console.log("Vehiculo no motorizado autorizado"); */
        setTypeVehicle("/No motorizado");
      } else {
        setValidated(false);
        setTypeVehicle("/No motorizado"); /* 
        console.log("Faltan documentos por validar o agregar no motorizado"); */
      }
    }
  }

  async function getApproved() {
    try {
      const get = await API.graphql(
        graphqlOperation(listApproved, { id: user })
      );

      setApproved(get.data.getAccount.register_approved);
    } catch (e) {
      console.log("error");
    }
  }

  React.useEffect(async () => {
    await checkDocumentation();
  }, [documents]);

  React.useEffect(async () => {
    setDateStart(date.toISOString().substring(0, 11) + "00:00:00.000Z");
    let ant = 90 * 86399.9; //dias en segundos
    let finalDate = new Date(date.setSeconds(ant));
    setDateEnd(finalDate.toISOString().substring(0, 11) + "23:59:59.000Z");
  });

  React.useEffect(async () => {
    await getApproved();
  });

  async function registerHistoryLevel() {
    const createInput = {
      historyLevelId_deliverymanId: user,
      historyLevelId_levelId: "8bca8340-f925-4aa2-83e7-aec4b7841403",
      start_date: dateStart,
      status: true,
      duration: 90,
      expiration_date: dateEnd,
    };

    const dataUpdate = {
      id: user,
      register_approved: checked,
    };
    try {
      const register = await API.graphql(
        graphqlOperation(CreateHistoryLevel, { input: createInput })
      );

      const updateStatus = await API.graphql(
        graphqlOperation(updateDeliveryStatusApproved, {
          input: dataUpdate,
        })
      );
      setDeliveryStatus(true);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div>
      <Accordion
        expanded={true}
        // expanded={expanded === "panel1"}
        onChange={handleChange("panel1")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Typography sx={{ width: "33%", flexShrink: 0 }}>
            Visualizar documentos
          </Typography>
          {!validated ? (
            <Typography sx={{ color: "red" }}>Faltan documentos</Typography>
          ) : (
            <Typography sx={{ color: "green" }}>
              Documentos validados
            </Typography>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            Como administrador puedes registrar, actualizar, vizualizar y
            descargar los documentos del repartidor que tengas seleccionado.
          </Typography>

          <Grid item xs={12} md={6}>
            <Typography sx={{ mt: 4, mb: 2 }} variant="h6" component="div">
              Documentos registrados
            </Typography>

            <CntList>
              <List dense={false}>
                {documents.length > 0 ? (
                  documents.map((item, index) => {
                    return (
                      <ListItem
                        key={index}
                        secondaryAction={
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "center",
                              alignContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <>
                              {item.doc === "ine" ? (
                                item.status === "validated" ? (
                                  <div>
                                    {/*   <p>Fecha exp.</p>
                                    <p>
                                      {item.dateExpiration.substring(0, 10)}
                                    </p> */}
                                  </div>
                                ) : (
                                  <div>
                                    <p>Fecha exp.</p>
                                    <p>
                                      {ineDate.toISOString().substring(0, 10)}
                                    </p>
                                  </div>
                                )
                              ) : null}
                              {item.doc === "drive" ? (
                                item.status === "validated" ? (
                                  <div>
                                    {/* <p>Fecha exp.</p>
                                    <p>
                                      {item.dateExpiration.substring(0, 10)}
                                    </p> */}
                                  </div>
                                ) : (
                                  <div>
                                    <p>Fecha exp.</p>
                                    <p>
                                      {driveDate.toISOString().substring(0, 10)}
                                    </p>
                                  </div>
                                )
                              ) : null}
                              {item.doc === "ine" || item.doc === "drive" ? (
                                item.status != "validated" ? (
                                  <div>
                                    <DatePiker
                                      driveDate={driveDate}
                                      setValueDrive={setValueDrive}
                                      ineDate={ineDate}
                                      setValueINE={setValueINE}
                                      type={item.doc}
                                    />
                                  </div>
                                ) : null
                              ) : null}
                              <IconButton
                                onClick={async () =>
                                  await OnchangeModalView(item.url, item.doc)
                                }
                                edge="end"
                                aria-label="delete"
                              >
                                <PreviewIcon />
                              </IconButton>
                              &nbsp; &nbsp;
                              <IconButton
                                onClick={() => {
                                  getDoc(item.url);
                                }}
                                edge="end"
                                aria-label="download"
                              >
                                <SimCardDownloadIcon />
                              </IconButton>
                              &nbsp; &nbsp;
                              <IconButton
                                edge="end"
                                aria-label="upload picture"
                                component="label"
                              >
                                <input
                                  type="file"
                                  hidden
                                  onChange={async (e) =>
                                    await uploadFile(e, item.url)
                                  }
                                />
                                <UploadFileIcon />
                              </IconButton>
                              <div
                                style={{
                                  width: "200px",
                                  display: "flex",
                                  flexdirection: "row",
                                  marginLeft: "10%",
                                }}
                              >
                                <div
                                  style={{
                                    width: "50%",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  {item.status === "validated" ||
                                  item.status === "non-validated" ? (
                                    chargingDocs ? (
                                      <CircularProgress color="success" />
                                    ) : (
                                      <Revalidatebtn
                                        onClick={async () =>
                                          await validateItem(item, "sended")
                                        }
                                        variant="contained"
                                      >
                                        Revalidar
                                      </Revalidatebtn>
                                    )
                                  ) : item.doc === "ine" ? (
                                    <Button
                                      variant="contained"
                                      color="success"
                                      onClick={async () =>
                                        await validateItem(item, "validated")
                                      }
                                    >
                                      Validar
                                    </Button>
                                  ) : item.doc === "drive" ? (
                                    <Button
                                      variant="contained"
                                      color="success"
                                      onClick={async () =>
                                        await validateItem(item, "validated")
                                      }
                                    >
                                      Validar
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      color="success"
                                      onClick={async () =>
                                        await validateItem(item, "validated")
                                      }
                                    >
                                      Validar
                                    </Button>
                                  )}
                                </div>
                                <div
                                  style={{
                                    width: "50%",
                                    justifyContent: "center",
                                    display: "flex",
                                  }}
                                >
                                  {item.status === "validated" ||
                                  item.status === "non-validated" ? null : (
                                    <ColorButton
                                      onClick={async () =>
                                        await validateItem(
                                          item,
                                          "non-validated"
                                        )
                                      }
                                      variant="contained"
                                    >
                                      Incorrecto
                                    </ColorButton>
                                  )}
                                </div>
                              </div>
                              &nbsp; &nbsp;
                            </>
                          </div>
                        }
                      >
                        <ListItemAvatar>
                          <Avatar sx={{ bgcolor: "#FFFFFF", fontSize: 45 }}>
                            {item.status === "validated" ? (
                              <VerifiedIcon
                                sx={{ color: "green", fontSize: 35 }}
                              />
                            ) : item.status === "non-validated" ? (
                              <DoNotTouchIcon
                                sx={{ color: "red", fontSize: 35 }}
                              />
                            ) : (
                              <FolderIcon
                                sx={{ color: "#F8C100", fontSize: 35 }}
                              />
                            )}
                          </Avatar>
                        </ListItemAvatar>

                        <ListItemText
                          primary={
                            item.doc === "circulation"
                              ? "TARJETA DE CIRCULACIÓN"
                              : item.doc === "drive"
                              ? "LICENCIA"
                              : item.doc === "rfc"
                              ? "RFC"
                              : item.doc === "ine"
                              ? "INE"
                              : item.doc === "back-ine"
                              ? "INE-PARTE TRASERA"
                              : item.doc
                          }
                          secondary={
                            item.status === "validated"
                              ? "VERIFICADO"
                              : item.status === "non-validated"
                              ? "SIN VERIFICAR"
                              : "SIN REVISAR"
                          }
                        />
                      </ListItem>
                    );
                  })
                ) : (
                  <Typography sx={{ color: "text.secondary" }}>
                    No cuenta con documentos registrados
                  </Typography>
                )}
              </List>
            </CntList>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {complete ? <AlertDialog visibility={true} /> : null}

      <FileViewerModalDelivery
        visibility={open}
        url={uri}
        type={typeFile}
        nameFile={nameDocS3}
        styles={stylesToHeader}
        profileIMG={imageBd}
        img={defaultImg}
        stars={stars}
        email={email}
        address={address}
        phone_number={phone_number}
        vehiculo={vehiculo}
        referralName={referralName}
      />
      <Snackbar open={Snack} autoHideDuration={6000} onClose={closeSnak}>
        <Alert onClose={closeSnak} severity="success" sx={{ width: "100%" }}>
          Se ha actualizado el status de los documentos correctamente!
        </Alert>
      </Snackbar>
      <Snackbar
        open={deliveryStatus}
        autoHideDuration={6000}
        onClose={closeSnak}
      >
        <Alert onClose={closeSnak} severity="success" sx={{ width: "100%" }}>
          ¡Se ha activado al repartidor correctamente!
        </Alert>
      </Snackbar>

      <Stack
        direction="row"
        spacing={2}
        divider={<Divider orientation="vertical" flexItem />}
        style={{ marginTop: "5%" }}
      >
        <Stack
          direction="row"
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "text.primary", fontSize: 18 }}>
            {validated
              ? gerApproved
                ? "Activado"
                : "Habilitar repartidor"
              : "Faltan documentos" + machine}
          </Typography>
          {validated ? (
            gerApproved ? (
              <Switch
                disabled
                checked={gerApproved}
                onChange={changeSwitch}
                inputProps={{ "aria-label": "controlled" }}
              />
            ) : (
              <Switch
                checked={gerApproved}
                onChange={changeSwitch}
                inputProps={{ "aria-label": "controlled" }}
              />
            )
          ) : null}
        </Stack>
        <Stack
          direction="row"
          spacing={2}
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography sx={{ color: "text.primary", fontSize: 18 }}>
            {validated
              ? gerApproved
                ? "Para desactivar al usuario deberas comunicarte con soporte Niku."
                : "¡Ya puedes activarlo"
              : "Aun no puede habilitar al repartidor"}
          </Typography>
        </Stack>
      </Stack>
      {/* <Revalidatebtn onClick={async () => await registerHistoryLevel()}>
        hola
      </Revalidatebtn> */}
    </div>
  );
}
