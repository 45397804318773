import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles({
  itemButton: {
    width: "100%",
    height: "100%",
    marginRight: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    boxShadow: "none",
    border: "none",
    color: "#FFFFFF",
    backgroundColor: "#004990",
    "&:hover": {
      cursor: "pointer",
      //backgroundColor: "#013669",
    },
    "&:focus": {
      backgroundColor: "#013669",
    },
  },
});

export default function ListItemDrawer({ goTo, children}) {
  const classes = useStyles();

  return(
      <button className={classes.itemButton} onClick={goTo}>
        {children}
      </button>
  );
}

ListItemDrawer.defaultProps = {
  children: null,
  goTo: ()=>null,
};

ListItemDrawer.propTypes = {
  goTo: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object]),
};
