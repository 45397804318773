import { createTheme } from "@mui/material/styles";
import ThemePalette from "./theme.palette";
import ThemeComponents from "./theme.components";

const theme = createTheme({
  palette: ThemePalette,
  components: ThemeComponents,
  breakpoints: {
    values: {
      xs: 0,
      sm: 375,
      md: 768,
      lg: 1024,
      xlg: 1440
    },
  },
  typography: {
    h1: {
      fontSize: "4.5rem",
      fontWeight: "700",
      marginBottom: "1rem",
      marginTop: "1rem",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "0.875rem",
    }
  },
});

export default theme;