import { makeStyles } from "@mui/styles";

export const avatarStyles = {
  width: "54px",
  height: "54px",
}

const useStyles = makeStyles({
  root: {
    boxShadow: "none",
    fontSize: 18,
    marginBottom: "40px",
    width: "100%",
    height: "102px",
    padding: " 17px 152px 21px 146px",
    backgroundColor: "#004990",
    color: "#FFF",
    borderRadius: "0px 0px 15px 15px",
    overflow: "hidden",
  },
  headerList: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  logoStyles:{
    width: "108px",
    height: "42px",
    "&:hover":{
      cursor: "pointer"
    }
  },
  navIcon:{
    width: 34,
    height: 22,
  }
});

export {useStyles}