import Rating from '@mui/material/Rating';
import Stack from '@mui/material/Stack';
import StarBorderRoundedIcon from '@mui/icons-material/StarBorderRounded';
import StarRoundedIcon from '@mui/icons-material/StarRounded';

const starStyles = {
  color: "#4F4F4F",
  height: "14px",
  width: "14px",
  paddingBottom: "1px",
};

export default function RatingStars({rating}){
  return(
    <Stack>
      <Rating max={5} name="rating" value={parseFloat(rating)} defaultValue={0} precision={0.5} readOnly  size="small" icon={<StarRoundedIcon sx={starStyles}/>} emptyIcon={<StarBorderRoundedIcon sx={starStyles}/>}/>
    </Stack>
  )
}