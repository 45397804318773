import Amplify, { Auth } from "aws-amplify";
import { API, graphqlOperation, Storage } from "aws-amplify";
import {
  listAccountsByRol,
  searchBarAccounts,
  ZoneByAddress,
  ListShopsByZone,
  ListCategoriesShop,
  ListShopsByZoneAndCategory,
  ListShopsByZoneAndName,
  getReviewsNotesByID,
  GetShopProfile,
  listOffertProducts,
  getReviewsByEvaluatedID,
  OrdersCommissions,
  getCollectsByDelivery,
  GetShopsBetweenDates,
  GetShopsPublicSexAndAge,
  GetShopsPublicIDs,
  OrderswithFilters,
  affiliations,
  states,
  GetAllFinalizedOrdersByZone,
  GetAllFinalizedOrdersByShop,
  ZoneByStates,
  GetShopInterClb,
} from "./graphql/myQueries.js";
import { getAccount } from "./graphql/queries";
import { UpdateShopVisibility } from "./graphql/myMutations.js";
import Location from "aws-sdk/clients/location";
import awsconfig from "./aws-exports";

//Iniciar sesión
async function signIn(username, password) {
  try {
    const user = await Auth.signIn(username, password);
    return user;
  } catch (error) {
    console.log("error signing in", error);
    return error;
  }
}

// Obtener la información de una cuenta
async function getOne(userId) {

  try {
    const response = await API.graphql({
      query: getAccount,
      variables: { id: userId },
    });

    return response.data.getAccount;
    
  } catch (error) {
/*     console.log("🚀 ~ file: Provider.jsx ~ line 55 ~ getOne ~ error", error) */
    return error.data.getAccount;
    /* throw new Error(error); */
  }
}

//Listado de estados
async function getCountries() {
  let nextToken = null;
  let data = [];
  let items = [];

  try {
    do {
      const response = await API.graphql(
        graphqlOperation(states, {
          nextToken: nextToken,
        })
      );
      nextToken = response["data"]["listZones"]["nextToken"];
      data = data.concat(response["data"]["listZones"]["items"]);
    } while (nextToken != null);
    for (var i = 0; i < data.length; i++) {
      if (data[i]["zone_state"] != null) {
        items = items.concat(data[i]);
      }
    }
    return items;
  } catch (error) {
    console.log(error);
  }
}

// Productos en oferta de un negocio
async function getOfferProducts(id) {
  try {
    const response = await API.graphql(
      graphqlOperation(listOffertProducts, { id: id })
    );
    return response.data.getShop.products;
  } catch (error) {
    console.log(error);
    return error;
  }
}

// Mostrar información de un negocio
async function getShopProfile(id) {
  try {
    const response = await API.graphql(
      graphqlOperation(GetShopProfile, { id: id })
    );
    return response.data.getShop;
  } catch (error) {
    console.log(error);
  }
}

async function consumeS3Image(url_photoProfile) {
  const response = await Storage.get(url_photoProfile)
    .then((result) => {
      return result;
    })
    .catch((err) => console.log(err));
  return response;
}

// Filtrar usuarios por rol y zona
async function getListAccounts({ role, zone }) {
  let nextToken = null;
  let items = [];

  try {
    do {
      const response = await API.graphql(
        graphqlOperation(listAccountsByRol, {
          role,
          nextToken: nextToken,
        })
      );
      nextToken = response["data"]["listAccounts"]["nextToken"];
      items = items.concat(response["data"]["listAccounts"]["items"]);
    } while (nextToken != null);
    return items;
  } catch (error) {
    throw new Error(error);
  }
}

// Filtrar usuarios por rol, zona y nombre
async function searchBar({ role, name }) {
  // console.log("🚀 ~ file: Provider.jsx ~ line 148 ~ searchBar ~ role, zone, name", role, name)
  let nextToken = null;
  let items = [];

  try {
    do {
      const response = await API.graphql(
        graphqlOperation(searchBarAccounts, {
          role,
          name,
          nextToken: nextToken,
        })
      );
      nextToken = response["data"]["listAccounts"]["nextToken"];
      items = items.concat(response["data"]["listAccounts"]["items"]);
    } while (nextToken != null);
    return items;
  } catch (error) {
    throw new Error(error);
  }
}

// Listar zonas por estados
async function zonesByState(country) {
  let nextToken = null;
  let data = [];

  try {
    do {
      const response = await API.graphql(
        graphqlOperation(ZoneByStates, {
          zone_state: country,
          nextToken: nextToken,
        })
      );
      nextToken = response["data"]["listZones"]["nextToken"];
      data = data.concat(response["data"]["listZones"]["items"]);
    } while (nextToken != null);
    return data;
  } catch (error) {
    console.log(error);
  }
}

// Lista de tiendas por zona
async function getListShopsByZone(zone) {
  var nextToken = null;
  var shops = [];
  do {
    const response = await API.graphql({
      query: ListShopsByZone,
      variables: { zone_id: zone, nextToken: nextToken },
    });
    nextToken = response["data"]["listShops"]["nextToken"];
    shops = shops.concat(response["data"]["listShops"]["items"]);
  } while (nextToken != null);
  return shops;
}

// Obtener la lista de categorías de negocios
async function GetCategories() {
  var nextToken = null;
  var categories = [];
  var response;

  do {
    response = await API.graphql({
      query: ListCategoriesShop,
      variables: { nextToken: nextToken },
    });
    nextToken = response["data"]["listCategorys"]["nextToken"];
    categories = categories.concat(response["data"]["listCategorys"]["items"]);
  } while (nextToken != null);
  return categories;
}

// Lista de tiendas por zona y categoria
async function listShopsByZoneAndCategory(zone, category) {
  var nextToken = null;
  var shops = [];
  var response;

  do {
    response = await API.graphql({
      query: ListShopsByZoneAndCategory,
      variables: { zone_id: zone, category: category, nextToken: nextToken },
    });
    nextToken = response["data"]["listShops"]["nextToken"];
    shops = shops.concat(response["data"]["listShops"]["items"]);
  } while (nextToken != null);
  return shops;
}

// Lista de tiendas por zona y nombre
async function searchBarShopsByName(zone, name) {
  let nextToken = null;
  let shops = [];
  let response;

  try {
    do {
      response = await API.graphql({
        query: ListShopsByZoneAndName,
        variables: {
          zone_id: zone,
          shop_name: name,
          nextToken: nextToken,
        },
      });
      nextToken = response["data"]["listShops"]["nextToken"];
      shops = shops.concat(response["data"]["listShops"]["items"]);
    } while (nextToken != null);
    return shops;
  } catch (error) {
    console.log(error);
  }
}

async function getRating(id) {
  let nextToken = null;
  let notes = [];
  let response;

  try {
    do {
      response = await API.graphql({
        query: getReviewsNotesByID,
        variables: { id: id, nextToken: nextToken },
      });
      nextToken = response["data"]["listReviews"]["nextToken"];
      response["data"]["listReviews"]["items"].forEach((note) => {
        notes.push(note["note"]);
      });
    } while (nextToken != null);
    var rating = 0;
    notes.forEach((note) => {
      rating += note;
    });

    return rating / notes.length;
  } catch (error) {
    console.log(error);
  }
}

async function geoLocationShop(id_shop) {
  let _shop = null;
  let adress = null;
  let latitud = null;
  let longitud = null;

  //Crear Usuario que permite el acceso al servicio de Locación de AWS
  const credentials = await Auth.currentCredentials();
  _shop = new Location({
    credentials,
    region: awsconfig.aws_project_region,
  });

  //Query para obtener la información de un negocio
  const response = await API.graphql({
    query: GetShopProfile,
    variables: { id: id_shop },
  });
  adress = response["data"]["getShop"]["address_shop"];

  //Valores para buscar la dirección y obtener los puntos
  const params = {
    IndexName: "NikuPlaceIndex", //Nombre del servicio de AWS
    Text: adress, //Dirección del negocio
  };

  return new Promise((resolve) => {
    _shop.searchPlaceIndexForText(params, (err, data) => {
      if (err) console.log("error en search place", err);
      if (data) {
        [latitud, longitud] =
          data["Results"]["0"]["Place"]["Geometry"]["Point"];
        resolve({ lat: longitud, lng: latitud });
      }
    });
  });
}

// Obtener los comentarios de un negocio por ID (el ID puede ser de un deliveryman o una shop)
async function GetReviewsByEvaluatedID(id) {
  var nextToken = null;
  var reviews = [];
  var response;

  try {
    do {
      response = await API.graphql({
        query: getReviewsByEvaluatedID,
        variables: { id: id, nextToken: nextToken },
      });
      nextToken = response["data"]["listReviews"]["nextToken"];
      reviews = reviews.concat(response["data"]["listReviews"]["items"]);
    } while (nextToken != null);
    return reviews;
  } catch (error) {
    console.log(error);
  }
}

//Ordenes por tienda
async function GetOrdersCommissions(id) {
  let nextToken = null;
  let orders = [];

  try {
    do {
      const response = await API.graphql({
        query: OrdersCommissions,
        variables: { id: id },
      });
      nextToken = response["data"]["getShop"]["orders"]["nextToken"];
      orders = orders.concat(response["data"]["getShop"]["orders"]["items"]);
    } while (nextToken != null);
    return orders;
  } catch (error) {
    return error;
  }
}

// Extraer datos para la gráfica de público por negocio
async function getPublicData(shop_idToSort) {
  let nextToken = null;
  let publicIds = [];
  let ids = [];
  let response;

  // OBTENER IDS DE CLIENTES
  try {
    do {
      // Se obtienen todas las compras en la tienda
      response = await API.graphql({
        query: GetShopsPublicIDs,
        variables: {
          shop_idToSort: shop_idToSort,
          nextToken: nextToken,
        },
      });
      response["data"]["ordersByShopId"]["items"].forEach((user) => {
        publicIds.push(user["customer_id"]["id"]);
      });
      nextToken = response["data"]["ordersByShopId"]["nextToken"];
    } while (nextToken != null);
    // Se obtienen los id de los clientes que han comprado
    // al menos una vez en la tienda (sin repetirlos)
    ids = [...new Set(publicIds)];
    //console.log(ids);
  } catch (error) {
    console.log(error);
  }

  // OBTENER SEXO Y EDAD DE LOS CLIENTES
  let men_dict = {};
  // let women_dict = {};

  men_dict = {
    "18-24": [],
    "25-34": [],
    "35-44": [],
    "45-54": [],
    "55-64": [],
    "65+": [],
  };

  /* women_dict = {
    '18-24': [],
    '25-34': [],
    '35-44': [],
    '45-54': [],
    '55-64': [],
    '65+': [],
  }; */

  try {
    for (let i = 0; i < ids.length; i++) {
      let id = ids[i];
      let infoUser = await getSexAndAge(id);

      /* if (infoUser['sexo'] === 'Masculino'){ */
      if (infoUser["age"] >= 18 && infoUser["age"] <= 24) {
        men_dict["18-24"].push(id);
      } else if (infoUser["age"] >= 25 && infoUser["age"] <= 34) {
        men_dict["25-34"].push(id);
      } else if (infoUser["age"] >= 35 && infoUser["age"] <= 44) {
        men_dict["35-44"].push(id);
      } else if (infoUser["age"] >= 45 && infoUser["age"] <= 54) {
        men_dict["45-54"].push(id);
      } else if (infoUser["age"] >= 55 && infoUser["age"] <= 64) {
        men_dict["55-64"].push(id);
      } else if (infoUser["age"] >= 65) {
        men_dict["65+"].push(id);
      }
      /* }else if (infoUser['sexo'] === 'Femenino'){
          if (infoUser['age'] >= 18 && infoUser['age'] <= 24){
            women_dict['18-24'].push(id);
          }else if (infoUser['age'] >= 25 && infoUser['age'] <= 34){
            women_dict['25-34'].push(id);
          }else if (infoUser['age'] >= 35 && infoUser['age'] <= 44){
            women_dict['35-44'].push(id);
          }else if (infoUser['age'] >= 45 && infoUser['age'] <= 54){
            women_dict['45-54'].push(id);
          }else if (infoUser['age'] >= 55 && infoUser['age'] <= 64){
            women_dict['55-64'].push(id);
          }else if (infoUser['age'] >= 65){
            women_dict['65+'].push(id);
          }
        } */
    }
    return men_dict;
  } catch (error) {
    console.log(error);
  }
}

async function getSexAndAge(id) {
  let response;

  response = await API.graphql({
    query: GetShopsPublicSexAndAge,
    variables: {
      id: id,
    },
  });

  let age = calculateAge(response["data"]["getAccount"]["birthday"]);
  let sexo = response["data"]["getAccount"]["sexo"];

  return { age: age, sexo: sexo };
}

function calculateAge(birthday) {
  let birth = new Date(birthday);
  //calculate month difference from current date in time
  let month_diff = Date.now() - birth.getTime();
  //convert the calculated difference in date format
  let age_dt = new Date(month_diff);
  //extract year from date
  let year = age_dt.getUTCFullYear();
  //now calculate the age of the user
  let age = Math.abs(year - 1970);
  return age;
}

// obtener número de repartos entre dos fechas
async function getCollectsBetweenDates({ id, startDate, endDate }) {
  let numberOfCollects = 0;
  /* const date1 = '2021-12-10T00:00:00Z', date2 = '2021-12-14T23:59:59Z', deliveryman_id = "d1e87d9c-2f7e-4183-8f80-abf2ac218800"; */
  const response = await API.graphql({
    query: getCollectsByDelivery,
    variables: { id: id, betweenDates: [startDate, endDate] },
  });
  numberOfCollects =
    response["data"]["getAccount"]["deliveryman_collects"]["items"].length;
  return numberOfCollects;
}

// obtener número de tiendas afiliadas entre dos fechas
async function getShopsBetweenDates({ id, startDate, endDate }) {
  let nextToken = null;
  let numberOfShops = 0;
  let response;
  do {
    response = await API.graphql({
      query: GetShopsBetweenDates,
      variables: {
        affiliator_id: id,
        betweenDates: [startDate, endDate],
        nextToken: nextToken,
      },
    });
    numberOfShops += response["data"]["listShops"]["items"].length;
    nextToken = response["data"]["listShops"]["nextToken"];
  } while (nextToken != null);
  return numberOfShops;
}

// Ordenes por zona, fecha y estatus
async function OrderswithFilter(zone, updatedAt, status) {
  let nextToken = null;
  let items = [];

  try {
    do {
      const response = await API.graphql(
        graphqlOperation(OrderswithFilters, {
          zone: zone,
          updatedAt: updatedAt,
          status: status,
          nextToken: nextToken,
        })
      );

      response["data"]["listShops"]["items"].forEach((item) => {
        if (item["orders"]["items"].length > 0) {
          items.push(item);
        }
      });

      nextToken = response["data"]["listShops"]["nextToken"];
    } while (nextToken != null);
    return items;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
}

async function Afiliaciones(zone, betweenDates) {
  let nextToken = null;
  let items = [];
  // let betweenDates = ['2021-04-04T24:00:00.868Z', '2021-12-31T24:00:00.868Z'];
  // var zone = 'e83d7dba-a598-416c-a359-bcfe1bdd1977';

  do {
    const response = await API.graphql(
      graphqlOperation(affiliations, {
        zone: zone,
        betweenDates: betweenDates,
        nextToken: nextToken,
      })
    );
    nextToken = response["data"]["listShops"]["nextToken"];
    items = items.concat(response["data"]["listShops"]["items"]);
  } while (nextToken != null);
  for (var i = 0; i < items.length; i++) {
    if (items[i]["status"] === true) {
      items[i]["status"] = "Completo";
    }
    if (items[i]["status"] === false) {
      items[i]["status"] = "Incompleto";
    }
  }
  return items;
}

// Ganacias por zona
async function calculateProfitByZone({ id, dates }) {
  let profit = 0;
  try {
    const response = await API.graphql({
      query: GetAllFinalizedOrdersByZone,
      variables: {
        zone_id: id,
        betweenDates: dates,
      },
    });
    response["data"]["listShops"]["items"].forEach((shop) => {
      let category = shop["category"];
      let percentageByCategory = 0.06;
      if (category === "Restaurantes") {
        percentageByCategory = 0.08;
      } else if (category === "Gas") {
        percentageByCategory = 0.01;
      }
      shop["orders"]["items"].forEach((order) => {
        let subtotal = order["total"] - order["discount"];
        let nikuComission = subtotal * percentageByCategory;
        profit =
          profit +
          order["com_shop"] +
          order["com_customer"] +
          nikuComission * 0.5;
      });
    });
    return profit;
  } catch (error) {
    console.log(error);
  }
}

//Calcular ganancias por tienda
async function calculateProfitByShop({ id, dates }) {
/*   console.log("calculateProfitByShop", id); */
  const items = [];

  try {
    const response = await API.graphql({
      query: GetAllFinalizedOrdersByShop,
      variables: {
        id: id,
        betweenDates: dates,
      },
    });

    return response;
  } catch (error) {
    console.log(error);
  }
}

async function updateShopVisibility({ id, visibility }) {
  try {
    const response = await API.graphql({
      query: UpdateShopVisibility,
      variables: {
        id,
        visibility,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
}

async function getInterClb(idShop) {
  try {
    const response = await API.graphql(
      graphqlOperation(GetShopInterClb, { id: idShop })
    );
    return response;
  } catch (error) {
    console.log(error);
    throw new Error(error.message);
  }
}

export {
  signIn,
  getOne,
  getCountries,
  getListAccounts,
  searchBar,
  consumeS3Image,
  zonesByState,
  listShopsByZoneAndCategory,
  getListShopsByZone,
  GetCategories,
  searchBarShopsByName,
  getRating,
  getShopProfile,
  getOfferProducts,
  geoLocationShop,
  getCollectsBetweenDates,
  getShopsBetweenDates,
  GetReviewsByEvaluatedID,
  GetOrdersCommissions,
  getPublicData,
  OrderswithFilter,
  calculateProfitByZone,
  calculateProfitByShop,
  Afiliaciones,
  updateShopVisibility,
  getInterClb
};
