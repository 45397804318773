import {
  getShopProfile,
  geoLocationShop,
  getRating,
  getOfferProducts,
  GetReviewsByEvaluatedID,
  GetOrdersCommissions,
  getListShopsByZone,
  listShopsByZoneAndCategory,
  searchBarShopsByName,
  GetCategories,
  getPublicData,
  OrderswithFilter,
  calculateProfitByShop,
  updateShopVisibility,
  getInterClb
} from "../../Provider";

export function getCLBService(id){
 return getInterClb(id);
}

export function getBusinessInfoService(id) {
  return getShopProfile(id);
}

export async function getListOfferProductsService(id) {
  return getOfferProducts(id);
}

export async function getGeoLocationService(id) {
  return geoLocationShop(id);
}

export async function getRatingShopService(id) {
  return getRating(id);
}

export async function getListReviewsService(id) {
  return GetReviewsByEvaluatedID(id);
}

export async function getOrdersByShopLogicService(id) {
  return GetOrdersCommissions(id);
}

export function GetBusinessListService(zone) {
  return getListShopsByZone(zone);
}

export function GetBusinessListByCategoryService(zone, category) {
  return listShopsByZoneAndCategory(zone, category);
}

export function GetBusinessListByNameService(zone, name) {
  return searchBarShopsByName(zone, name);
}

export function GetListCategoriesService() {
  return GetCategories();
}

export function GetPublicDataService(id_shop) {
  return getPublicData(id_shop);
}

export function GetOrdersWithFilterService(zone, updatedAt, status){
  return OrderswithFilter(zone, updatedAt, status);
}

export function getProfitShopService(data){
  return calculateProfitByShop(data);
}

export function UpdateShopStatusService(data){
  return updateShopVisibility(data);
}