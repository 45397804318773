import { IconButton, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateRangeIcon from "@mui/icons-material/DateRange";
import React from "react";

export default function DatePikerShop({
    ineDate = null,
    setValueINE,
    type,
    disabled
}) {
    // console.log("🚀 ~ file: DatePickerShop.jsx ~ line 13 ~ ineDate", ineDate.getDate())
    const [openDate, setOpendate] = React.useState(false);

    return (
        <div>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  
                    open={openDate}
                    onClose={() => setOpendate(false)}
                    value={type === "identificacion" ? ineDate : ""}
                    minDate={new Date()}
                    onChange={(newValue) => {
                        setValueINE(newValue.$d);
                    }}
                    renderInput={(params) => (
                        <div>
                            <TextField
                                style={{
                                    opacity: 0,
                                    width: 0,
                                    height: 0,
                                }}
                                {...params}
                            />
                            <IconButton
                              disabled={disabled}
                                onClick={() => setOpendate((openDate) => !openDate)}
                                edge="end"
                                aria-label="download"
                            >
                                <DateRangeIcon
                                    color={ineDate.getDate() > new Date().getDate() ? "success" : "disabled"}
                                />
                            </IconButton>
                        </div>
                    )}
                />
            </LocalizationProvider>
        </div>
    );
}
