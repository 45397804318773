import { useState, useEffect } from "react";
import BarChart from "../../DesignSystem/BarChart";
import { useStyles } from "./styles/BusinessPublicChart.styles";
import {GetPublicData} from "../Infrastructure/Business.presenter";

export default function BusinessPublicChart({ businessId }) {
  const classes = useStyles();
  const [publicData, setPublicData] = useState({});

  useEffect(() => {
    if (businessId) {
      fetchGetPublicData(businessId);
    }
  }, [businessId]);

  async function fetchGetPublicData(id){
    try{
      const info = await GetPublicData(id);
      const result = [];
      result.push(info['18-24'].length);
      result.push(info['25-34'].length);
      result.push(info['35-44'].length);
      result.push(info['45-54'].length);
      result.push(info['55-64'].length);
      result.push(info['65+'].length);
      setPublicData(result);
    }catch(error){
      console.log(error);
    }
  }

  return (
    <div className={classes.root}>
      <div className={classes.barChartInfo}>
        <div className={classes.container}>
          <div>
            <h3 className={classes.title}>Público</h3>
            <p className={classes.subtitleChart}>
              Rango de público por edades
            </p>
          </div>
          {/* <div className={classes.subContainer}>
            <div className={classes.reactangleContainer}>
              <span className={classes.rectangle} />{" "}
              <p className={classes.rectext}>Hombres</p>
            </div>
            <div className={classes.reactangleContainer}>
              <span className={classes.rectangleM} />{" "}
              <p className={classes.rectext}>Mujeres</p>
            </div>
          </div> */}
        </div>
        {publicData && <BarChart info={publicData} />}
      </div>
    </div>
  );
}
