export const tableContainerStyles = {
  paddingRight: "80px",
  border: "1px solid #F2F2F2",
  borderRadius: "5px",
  gridColumn: "1/-1",
  boxShadow: "none"
}

export const tableHeadCellStyles = {
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "18px",
  lineHeight: "22px",
  color: "#828282"
}

export const tableCellStyles = {
  fontFamily: "Montserrat",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  color: "#333333"
}