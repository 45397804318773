import { useState } from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";

const textFiledStyles = {
  width: "100%",
  height: "45px",
  "& .MuiFilledInput-root": {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    color: "#4F4F4F",
    fontWeight: 400,
    border: "none",
    borderRadius: "10px",
  },
  "& .MuiFilledInput-root:before": {
    fontFamily: "Montserrat",
    fontSize: "1rem",
    color: "#4F4F4F",
    fontWeight: 400,
    border: "none",
    borderRadius: "10px",
  },
};

const inputStyles = {
  height: "45px",
  width: "100%",
  "& .MuiInputBase-input": {
    paddingBottom: "20px",
    maxHeight: "4px",
    minHeight: "4px",
    borderRadius: "10px"
  },
};

const useStyles = makeStyles((theme) =>({
  inputStyles: {
    width: "100%",
    marginTop: "20px",
  },
  labelStyles: {
    fontSize: "1.5rem",
    fontWeight: 700,
    lineHeight: "29px",
    textAlign: "left",
    color: "#4F4F4F",
    [theme.breakpoints.up("md")]:{
      fontSize: "1.085rem",
    }
  },
}));

export default function InputOutlined({
  labelTitle,
  placeholderText,
  onChange,
  name,
  type,
  value,
  isPassword,
}) {
  const classes = useStyles();
  const [values, setValues] = useState({ showPassword: false });

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className={classes.inputStyles}>
      {labelTitle && <p className={classes.labelStyles}>{labelTitle}</p>}
      {isPassword ? (
        <TextField
          id={name}
          onChange={onChange}
          sx={textFiledStyles}
          name={name}
          value={value}
          variant="filled"
          type={values.showPassword ? "text" : "password"}
          placeholder={placeholderText}
          InputProps={{
            sx: inputStyles,
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
            disableUnderline: true,
          }}
        />
      ) : (
        <TextField
          id={name}
          onChange={onChange}
          sx={textFiledStyles}
          name={name}
          value={value}
          type={type}
          variant="filled"
          placeholder={placeholderText}
          InputProps={{
            sx: inputStyles,
            disableUnderline: true,
          }}
        />
      )}
    </div>
  );
}

InputOutlined.defaultProps = {
  type: "text",
  labelTitle: "",
  placeholderText: "",
  onChange: () => null,
  isPassword: false,
};

InputOutlined.propTypes = {
  type: PropTypes.string,
  labelTitle: PropTypes.string,
  value: PropTypes.string.isRequired,
  placeholderText: PropTypes.string,
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  isPassword: PropTypes.bool,
};
