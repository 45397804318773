import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    border: "1px solid #E5E5E5",
    maxWidth: 467,
    minHeight: 78,
    height: "auto",
    width: "100%",
    "&:hover":{
      cursor: "pointer",
      background: "#E0E0E0"
    },
    margin: "10px auto"
  },
  infoContainer:{
    width: "100%",
    textAlign: "left"
  },
  businessName:{
    fontSize: "1.5rem",
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    color: "#333333",
  },
  businessDescription:{
    fontSize: "1.125rem",
    color: "#828282",
    fontWeight: 400,
    height: 43,
    width: 197,
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  hours:{
    fontSize: "1.125rem",
    color: "#828282",
    fontWeight: 400,
    display: "flex",
    alignItems: "flex-end",
    marginTop: 10,
  }
}));

export {useStyles};