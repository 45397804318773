import { makeStyles } from "@mui/styles";

export const searchFormControl = {
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  alignItems: { xs: "center", md: "baseline" },
  flexDirection: { xs: "column", md: "row" },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "50px",
    height: "50px",
    maxHeight: "50px",
    minHeight: "50px",
    backgroundColor: "#000",
  },
  buttonDrawer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  text: {
    color: "#000",
  },
  inputStyles: {
    border: "1px solid #E0E0E0",
    borderRadius: 10,
    height: 35,
    padding: "0px 10px",
    fontWeight: 500,
    background: "#FFF",
  },
}));

export {useStyles};