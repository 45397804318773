import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "user",

  initialState: {
    userModel: null,
  },

  reducers: {
    setUserModel: (state, { payload: userModel }) => ({
      ...state,
      userModel,
    }),
  },
});

export const { setUserModel } = slice.actions;

export default slice.reducer;

export const getUserModel = (store) => store.user.userModel;