import { API, graphqlOperation, Storage } from "aws-amplify";
import { useState } from "react";
import { updateAccountDocs } from "../graphql/myMutations";

function GetDocuments() {
  const [state, setState] = useState(false);

  async function getDocs(uriS3) {
    const response = await Storage.get(uriS3, { download: true });
    return response;
  }
  async function getDocUri(uriS3) {
    const response = await Storage.get(uriS3);
    return response;
  }

  async function changeBtn(status) {
    setState(status);
  }

  return {
    changeBtn,
    getDocs,
    getDocUri,
    state,
  };
}
export default GetDocuments;
