import { GetListZonesLogic, GetListCountriesLogic, GetProfitByZoneLogic } from "../Application/GetZoneInfo.logic";

export async function GetListZones(country){
  return GetListZonesLogic(country);
}

export async function GetListCountries(){
  return GetListCountriesLogic();
}

export function GetProfitByZone(info){
  return GetProfitByZoneLogic(info);
}