import React, { useState, useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { useStyles } from "./styles/ZonesList.styles";
import {  centarlContainer } from "./styles/ZonesList.styles";

import {
  GetListZones,
  GetProfitByZone,
} from "../Infrastructure/Zone.presenter";

import { sub } from "date-fns";
import { Chart, ArcElement } from "chart.js";
import "chart.js/auto";
import UsersChatListStart from "../../User/UI/UserListStart.ui";
Chart.register(ArcElement);

let ganancias = [];

export default function DeliveryList() {
  const { estado } = useParams();
  const [firstDate, setFirstDate] = useState(sub(new Date(), { years: 1 }));
  const [secondDate, setSecondDate] = useState(new Date());
  const [typeDate, setTypeDate] = useState("Anual");
  const [labels, setLabels] = useState([]);
  const [colors, setColors] = useState([]);
  const [completeData, setCompleteData] = useState([]);

  const [isTooltipActive, setTooltip] = useState(false);
  const [color, setColor] = useState("#FFFFFF");
  const [theLabel, setTheLabel] = useState();
  const [total, setTotal] = useState();
  const [thePercentage, setThePercentage] = useState();
  const [gainTotal, setGainTotal] = useState(0);
  const getBetweenDates = (value) => {
    setFirstDate(moment(value[0]).utc().format("YYYY-MM-DD"));
    setSecondDate(moment(value[1]).utc().format("YYYY-MM-DD"));
  };

  const getSortType = (value) => {
    setTypeDate(value);
  };

  function generarCaracter() {
    var caracter = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    var numero = (Math.random() * 15).toFixed(0);
    return caracter[numero];
  }

  function colorHEX() {
    var color = "";
    for (let i = 0; i < 6; i++) {
      color = color + generarCaracter();
    }
    return "#" + color;
  }

  function generarColores(total) {
    var colores = [];
    for (let i = 0; i < total; i++) {
      colores.push(colorHEX());
    }
    setColors(colores);
    return colores;
  }

  const dataB = {
    labels: labels,
    datasets: [
      {
        data: ganancias,
        backgroundColor: colors,
      },
    ],
  };
  
  useEffect(() => {
    async function fetchDataZones() {
      try {
        const infoZone = await GetListZones(estado);
        var zonas = [];
        var ids = [];
        var infoSocio = [];
        ganancias = [];
        infoZone.forEach((item) => {
          zonas.push(item.zone_name);
          ids.push(item.id);
          infoSocio.push(item.franchisse_id);
        });

        ganancias = await Promise.all(
          infoZone.map(async (item) => {
            const response = await GetProfitByZone({
              id: item.id,
              dates: [firstDate, secondDate],
            });
            return response.toFixed(2);
          })
        );

        let sum = 0;
        for (let i = 0; i < ganancias.length; i++) {
          sum += parseFloat(ganancias[i]);
        }

        setLabels(zonas);
        setGainTotal(sum);

        const colores = generarColores(zonas.length);
        const complete = [];
        for (let i = 0; i < zonas.length; i++) {
          complete.push({
            id: ids[i],
            partner: infoSocio[i],
            zone: zonas[i],
            percentage: ganancias[i],
            color: colores[i],
          });
        }
        setCompleteData(complete);
      } catch (error) {
        console.log("error", error);
      }
    }

    fetchDataZones();
  }, [firstDate, secondDate, typeDate]);

  return (
    <Box sx={centarlContainer}>
      <UsersChatListStart role = "delivery" />
    </Box>
  );
}
