const ThemePalette = {
  primary: {
    main: "#004990",
    dark: "#013669",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#EA5B13",
    dark: "#E64020",
    contrastText: "#ffffff",
  },
  black: {
    main: "#000000",
  },
  white: {
    main: "#ffffff",
  },
  gray: {
    main: "#BDBDBD",
    dark: "#333333",
    contrastText: "#4F4F4F",
  }
};

export default ThemePalette;
