import * as React from "react";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { Grid, IconButton } from "@mui/material";
import DatePikerShop from "./DatePickerShop";
import PreviewIcon from "@mui/icons-material/Preview";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import SimCardDownloadIcon from "@mui/icons-material/SimCardDownload";

function IfoDocs({
  document,
  display = () => null,
  updateFile = () => null,
  validationDoc = () => null,
  download = () => null,
  charging,
  ineDate,
  setValueINE,
}) {
  const doc = document;

  const ValidateBtn = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#03651D"),
    backgroundColor: doc.url !== "" ? "#03651D" : "rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "#03651D",
    },
  }));

  const IncorrectBtn = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#C00000"),
    backgroundColor: doc.url !== "" ? "#C00000" : "rgba(0, 0, 0, 0.1)",
    "&:hover": {
      backgroundColor: "#C00000",
    },
  }));

  const styles = {
    item: {
      marginTop: 3,
      flexdirection: "row",
      display: "flex",
      justifyContent: "space-between",
      width: "100%" /* 
      backgroundColor: "#03651D", */,
      padding: "5px",
      alignItems: "center",
      margin: "10px solid #000000",
    },
    contentBtn: {
      flexdirection: "row",
      width: "45%",
      justifyContent: "space-evenly",
      display: "flex" /* 
      backgroundColor: "yellow", */,
    },
    docAvailavility: {
      flexdirection: "column",
    },
  };

  return (
    <Grid style={styles.item}>
      <div style={styles.docAvailavility}>
        <h1 style={{ fontSize: "15px" }}>{doc.doc.toUpperCase()}</h1>
        <h1 style={{ fontSize: "12px", fontWeight: "500" }}>
          {doc.url !== "" ? "Registrado" : "Sin registrar"}
        </h1>
      </div>

      {doc.doc.toUpperCase() === "IDENTIFICACION_REPRESENTANTE" ? (
        <DatePikerShop
          ineDate={ineDate}
          setValueINE={setValueINE}
          type={"identificacion"}
          disabled={doc.url !== "" ? false : true}
        />
      ) : null}
      <div style={styles.contentBtn}>
        <IconButton
          disabled={doc.url !== "" ? false : true}
          aria-label="delete"
          onClick={() => display(doc.url, doc.doc)}
        >
          <PreviewIcon />
        </IconButton>
        <IconButton
          disabled={doc.url !== "" ? false : true}
          aria-label="upload picture"
          component="label"
        >
          <input type="file" hidden onChange={(e) => updateFile(e, doc.url)} />
          <UploadFileIcon />
        </IconButton>
        <IconButton
          disabled={doc.url !== "" ? false : true}
          aria-label="delete"
          onClick={() => {
            download(doc.url, doc.doc);
          }}
        >
          <SimCardDownloadIcon />
        </IconButton>
        {charging ? (
          <CircularProgress />
        ) : (
          <>
            <ValidateBtn
              onClick={() => {
                validationDoc(doc, "validated");
              }}
              disabled={
                (doc.url !== "" ? false : true) || doc.status === "validated"
                  ? true
                  : false
              }
              sx={{
                backgroundColor:
                  doc.status === "validated" ? "rgba(0, 0, 0, 0.1)" : null,
              }}
            >
              Aceptado
            </ValidateBtn>
            <IncorrectBtn
              onClick={() => {
                validationDoc(doc, "non-validated");
              }}
              disabled={
                (doc.url !== "" ? false : true) ||
                doc.status === "non-validated"
                  ? true
                  : false
              }
              sx={{
                backgroundColor:
                  doc.status === "non-validated" ? "rgba(0, 0, 0, 0.1)" : null,
              }}
            >
              Rechazado
            </IncorrectBtn>
          </>
        )}
      </div>
    </Grid>
  );
}
export default IfoDocs;
