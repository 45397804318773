import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const columnStyle = {
  color: "#828282",
  align: "right",
  fontWeight: 500,
  minWidth: 170,
  maxWidth: 170,
  fontSize: "1.125rem",
  fontFamily: "Montserrat",
};

const textStyle = {
  fontWeight: 500,
  fontSize: "1.125rem",
  fontFamily: "Montserrat",
};

const captionStyle = {
  background: "#004990",
  color: "#FFF",
  borderRadius: "10px",
  fontFamily: "Montserrat",
};

const columns = [
  { id: "name", label: "Negocio" },
  { id: "order", label: "Orden" },
  { id: "date", label: "Fecha" },
  { id: "day", label: "Día" },
  { id: "hour", label: "Hora" },
  { id: "amountCl", label: "Monto que pago el cliente" },
  { id: "tasOpp", label: "2.9% Tasa Opp/Cliente" },
  { id: "comOpp", label: "Comisión NXN (Openpay)" },
  { id: "IVAUPcom", label: "IVA Opp sobre comisión." },
  { id: "finalCom", label: "Comisión Final" },
  { id: "moneyInOpp", label: "Dinero en pasarela" },
  { id: "method_pay", label: "Forma de pago" },
  { id: "moneyInOpplow", label: "Dinero en pasarela - comisión de envio" },
  {
    id: "moneyInOpphigh",
    label: "Dinero en pasarela sin comisión de envio + Descuento de cliente",
  },
  { id: "Importe", label: "Importe de venta" },
  { id: "DiscountCl", label: "Descuento del cliente" },
  { id: "mcc", label: "Monedero" },
  { id: "subtotal", label: "Subtotal" },
  { id: "ComisionNk", label: "Comisión NK" },
  { id: "SubTSinIva", label: "Subtotal sin IVA" },
  { id: "IVA", label: "Retención IVA" },
  { id: "ISR", label: "Retención ISR" },
  { id: "comSend", label: "Comisión de envio" },
  { id: "comClient", label: "Comisión de envio de cliente" },
  { id: "propine", label: "Propina" },
  { id: "Delivery", label: "Repartidor asignado por orden" },
  { id: "dispersion", label: "Total a depositar" },
  { id: "collect", label: "Total a cobrar" },
];


export default function CommissionByShopTable({ businessName, data }) {
  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 706 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ fontFamily: "Montserrat" }}
        >
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} sx={columnStyle} align="center">
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                  <TableCell key={"name"} align={"center"} sx={textStyle}>
                    {businessName}
                  </TableCell>
                  <TableCell key={"order"} align={"center"} sx={textStyle}>
                    {row.id}
                  </TableCell>
                  <TableCell key={"date"} align={"center"} sx={textStyle}>
                    {row.creacion}
                  </TableCell>
                  <TableCell key={"day"} align={"center"} sx={textStyle}>
                    {row.Fecha}
                  </TableCell>
                  <TableCell key={"hour"} align={"center"} sx={textStyle}>
                    {row.Hora}
                  </TableCell>
                  <TableCell key={"amountCl"} align={"center"} sx={textStyle}>
                    ${row.importpluscomcustomer}
                  </TableCell>
                  <TableCell key={"tasOpp"} align={"center"} sx={textStyle}>
                    ${row.tasaOpp}
                  </TableCell>
                  <TableCell key={"comOpp"} align={"center"} sx={textStyle}>
                    ${row.comNXN}
                  </TableCell>
                  <TableCell key={"IVAUPcom"} align={"center"} sx={textStyle}>
                    ${row.Ivaopp}
                  </TableCell>
                  <TableCell key={"finalCom"} align={"center"} sx={textStyle}>
                    ${row.com_opp}
                  </TableCell>
                  <TableCell key={"moneyInOpp"} align={"center"} sx={textStyle}>
                    ${row.moneyInopp}
                  </TableCell>
                  <TableCell key={"method_pay"} align={"center"} sx={textStyle}>
                    {row.Formadepago}
                  </TableCell>
                  <TableCell
                    key={"moneyInOpplow"}
                    align={"center"}
                    sx={textStyle}
                  >
                    ${row.less_send_opp}
                  </TableCell>
                  <TableCell
                    key={"moneyInOpphigh"}
                    align={"center"}
                    sx={textStyle}
                  >
                    ${row.less_more_discount}
                  </TableCell>
                  <TableCell key={"Importe"} align={"center"} sx={textStyle}>
                    ${row.Importventa}
                  </TableCell>
                  <TableCell key={"DiscountCl"} align={"center"} sx={textStyle}>
                    ${row.Descuentocl}
                  </TableCell>
                  <TableCell key={"mcc"} align={"center"} sx={textStyle}>
                    {row.MCC}
                  </TableCell>
                  <TableCell key={"subtotal"} align={"center"} sx={textStyle}>
                    ${row.Subtotal}
                  </TableCell>
                  <TableCell key={"ComisionNk"} align={"center"} sx={textStyle}>
                    ${row.ComisionNk}
                  </TableCell>
                  <TableCell key={"SubTSinIva"} align={"center"} sx={textStyle}>
                    ${row.Subsiniva}
                  </TableCell>
                  <TableCell key={"IVA"} align={"center"} sx={textStyle}>
                    ${row.IVA8}
                  </TableCell>
                  <TableCell key={"ISR"} align={"center"} sx={textStyle}>
                    ${row.ISR1}
                  </TableCell>
                  <TableCell key={"comSend"} align={"center"} sx={textStyle}>
                    ${row.Comsend}
                  </TableCell>
                  <TableCell key={"comClient"} align={"center"} sx={textStyle}>
                    ${row.ComsendCustomer}
                  </TableCell>
                  <TableCell key={"propine"} align={"center"} sx={textStyle}>
                    ${row.propine}
                  </TableCell>
                  <TableCell key={"Delivery"} align={"center"} sx={textStyle}>
                    {row.deliveryman}
                  </TableCell>
                  <TableCell key={"dispersion"} align={"center"} sx={textStyle}>
                    ${row.TotalADepositarporOrden}
                  </TableCell>
                  <TableCell key={"collect"} align={"center"} sx={textStyle}>
                    ${row.Domicializacion}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
          {/* <caption style={captionStyle}>dd/mm/aaaa</caption> */}
        </Table>
      </TableContainer>
    </Paper>
  );
}
