import React from "react";
import ReactPanZoom from "react-image-pan-zoom-rotate";

const ImageZoom = (data) => {
  /*   console.log("🚀 ~ file: ImageZoom.jsx ~ line 5 ~ ImageZoom ~ path", data); */
  return (
    <div
      style={{
        overflow: "hidden",
        position: "relative",
        height: "100%",
        width: "70%",
      }}
    >
      <ReactPanZoom
       
        image={data.path}
        alt="Image alt text"
      />
    </div>
  );
};

export default ImageZoom;
