export const affiliationsContainer = {
  position: "relative",
  width: "1147px",
  minHeight: "391px",
  height: "auto",
  background: "#FFFFFF",
  border: "1px solid #F2F2F2",
  boxShadow: "0px 4px 4px rgba(219, 219, 219, 0.25)",
  borderRadius: "5px",
  display: "flex",
  alignContent: "center",
  justifyContent: "center",
};

export const columnStyle = {
  color: "#828282",
  align: "left",
  fontWeight: 500,
  minWidth: 210,
  maxWidth: 210,
  minHeight: 67,
  fontSize: "1.125rem",
  fontFamily: "Montserrat",
};

export const columnIndexStyle = {
  color: "#828282",
  align: "left",
  fontWeight: 500,
  minWidth: 70,
  maxWidth: 70,
  minHeight: 67,
  fontSize: "1.125rem",
  fontFamily: "Montserrat",
};

export const columnDateStyle = {
  color: "#828282",
  align: "left",
  fontWeight: 500,
  minWidth: 100,
  maxWidth: 100,
  minHeight: 67,
  fontSize: "1.125rem",
  fontFamily: "Montserrat",
};

export const textStyle = {
  fontWeight: 500,
  fontSize: "1.125rem",
  fontFamily: "Montserrat",
};

export const success = {
  width: "98%",
  overflowY: "hidden",
  boxShadow: "none",
};

export const error = {
  width: "90%",
  overflowY: "hidden",
  boxShadow: "none",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const columnExcelStyles = {
  alignment: { 
    vertical: 'middle', 
    horizontal: 'center' 
  }
}