//import dotenv from "dotenv";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import customMarker from "../assets/markerNiku.png";

//dotenv.config();

const simulatorMaps = {
  height: "278px",
  width: "619px",
  borderRadius: "10px",
  position: "static",
};

export function MapContainer({google, lat, lng, customStyles = simulatorMaps, isDraggable=true, mapTypeControl=true}) {
  return (
    <Map
      google={google}
      zoom={17}
      style={customStyles}
      containerStyle={{ position: "static" }}
      initialCenter={{ lat, lng }}
      fullscreenControl={false}
      draggable={isDraggable}
      mapTypeControl={mapTypeControl}
    >
      <Marker
        icon={{ url: customMarker, scaledSize: new google.maps.Size(70, 70) }}
        position={{ lat, lng }}
      />
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBL9WpmsTlsd5PdPJO9SLZWVOuRGBaitBg",
  //apiKey: process.env.REACT_APP_API_KEY || "",
})(MapContainer);
