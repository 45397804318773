import { makeStyles } from "@mui/styles";

const useStyles = makeStyles({
  breadCrumbText:{
    fontSize: "1.125rem",
    lineHeight: "21.94px",
    textTransform: "capitalize"
  },
  linkStyles:{
    fontWeight: 400,
    color: "#828282",
    textDecoration: "none",
    "&:hover":{
      textDecoration: "underline"
    }
  },
  activePosition:{
    fontWeight: 500,
    color: "#000000",
  }
})

export {useStyles};