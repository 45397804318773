import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import CheckBoxRoundedIcon from "@mui/icons-material/CheckBoxRounded";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 48 * 4.5 + 8,
      width: 375,
      background: "#F2F2F2",
      borderRadius: "10px",
    },
  },
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "36px",
    maxHeight: "36px",
    minHeight: "36px",
    backgroundColor: "#F2F2F2",
    display: "flex",
    alignItems: "center",
  }
}));

export default function CheckSelect({ options, defaultText, handleValue }) {
  const [value, setValue] = useState(defaultText);
  const classes = useStyles();

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    let newValue = typeof value === "string" ? value.split(",") : value;

    console.log("Lo que llega en value: ", newValue);

    setValue(typeof value === "string" ? value.split(",") : value);
    handleValue(newValue);
  };

  return (
    <Box>
      <FormControl fullWidth className={classes.root}>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          className={classes.root}
          color={"gray"}
          value={value[0]}
          onChange={handleChange}
          renderValue={(selected) => (value[0])}
          MenuProps={MenuProps}
          style={{ display: "flex" }}
          sx={{ fontFamily: "Montserrat" }}
        >
          {options.map((categoria, index) => (
            <MenuItem
              key={index}
              value={categoria}
              sx={{ background: "#F2F2F2", borderBottom: "1px solid #FFF" }}
            >
              <Checkbox
                checked={value[0].indexOf(categoria) > -1}
                tabIndex={-1}
                disableRipple
                icon={<SquareRoundedIcon />}
                checkedIcon={<CheckBoxRoundedIcon />}
                sx={{
                  color: "#FFF",
                  "&.Mui-checked": {
                    color: "#333",
                  },
                }}
              />
              <ListItemText id={index} primary={categoria} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
}

CheckSelect.defaultProps = {
  defaultText: ["Miscelánea"]
};

CheckSelect.propTypes = {
  options: PropTypes.array.isRequired,
  defaultText: PropTypes.array,
};