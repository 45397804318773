import { makeStyles } from "@mui/styles";

export const principalContainerStyles={
    //border: "1px solid red",
    width: "100%",
    maxWidth: "1148px",
    margin: "auto",
    height: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column"
}

export const headerInfo = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  gap: "12px",
  marginBottom: "20px",
  gridColumn: "1/-1",
  justifySelf: "start",
};

export const businessImageStyles = {
  borderRadius: "50%",
  width: 66,
  height: 66,
  marginRight: "5px",
}

export const typeTabs = {
  fontWeight: 700,
  fontFamily: "Montserrat",
  color: "#000",
  textTransform: "none",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1.20fr 2fr",
    width: "100%",
    maxWidth: "1164px",
    top: "16%",
    left: "13.5%",
    marginTop: "auto",
    gap: "40px 30px",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginTop: 15,
  },
  productsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconBag: { marginRight: "20px" },
  subtitle: {
    color: "#333333",
    fontWeight: 400,
    fontSize: "1.5rem",
    marginBottom: "10px",
  },
  listProducts: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "20px 60px",
    },
  },
  products: { height: "265px" },
  address: {
    height: "46px",
    width: "311px",
    marginTop: "10px",
  },
  offersContainer: {
    borderRadius: 10,
    padding: "18px 40px 18px",
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    width: "100%",
    maxWidth: "710px",
    height: "auto",
    minHeight: "250px",
    background: "#FFFFFF",
    marginTop: 10,
    /* overflowX: "scroll", */
    [theme.breakpoints.down("md")]: {
      maxWidth: "360px",
      display: "none"
    },
  },
  titleInfo: {
    lineHeight: "29.05px",
    fontWeight: 600,
    color: "#333333",
    fontSize: "1.5rem",
  },
  productsContainer:{
    width: "100%",
    height: "auto",
    [theme.breakpoints.down("md")]: {
      display: "none"
    },
  },
  businessNameStyles:{
    fontSize: "3rem",
    fontWeight: 700,
    lineHeight: "58px",
    letterSpacing: "0em",
    textAlign: "left",
    marginLeft: "27px"
  },
}));

export {useStyles};