import { useState } from "react";
import Paper from "@mui/material/Paper";
import { API, graphqlOperation, Storage } from "aws-amplify";
import GetDocuments from "../hooks/getDocsS3";
import AlertDialog from "./BusinessModalAccept";
import FileViewerModal from "./BusinessFileViewer";
import DocshopList from "./ListdocShop";
import { getRolByUser, GetRStatusShop } from "../graphql/myQueries";
import { useEffect } from "react";

export default function DocumentsByShopTable({ businessId, data }) {
  const { getDocs, getDocUri } = GetDocuments();
  const [complete, setComplete] = useState(false);
  const [open, setOpen] = useState(false);
  const [uri, setUri] = useState("");
  const [typeFile, setTypeFile] = useState("");
  const [nameDocS3, setNameDocS3] = useState("");
  const [docs, setDocs] = useState([]);
  const [affiliator, setAffiliatorInfo] = useState("");

  function downloadBlob(blob, filename) {
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }

  async function getDoc(uri, nameFile) {
    const res = await getDocs(uri);
    downloadBlob(res.Body, nameFile);
  }

  async function uploadFile(e, url) {
    setComplete(false);
    const responseUri = e.target.files[0];
    const res = await Storage.put(url, responseUri, {
      contentType: e.target.files[0].type,
    });

    if (res.key !== "") {
      setComplete(true);
    }
  }

  async function OnchangeModalView(uri, name) {
    // console.log("🚀 ~ file: DocumentsByShopTable.jsx ~ line 100 ~ OnchangeModalView ~ uri", uri)
    setNameDocS3(name);
    const res = await getDocUri(uri);
    console.log("🚀 ~ file: DocumentsByShopTable.jsx:58 ~ OnchangeModalView ~ res", res)
    const resType = await getDocs(uri);
    if (resType.Body.type === "application/pdf") {
      setOpen(false);
      setTypeFile("pdf");
    } else if (
      resType.Body.type ===
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setOpen(false);
      setTypeFile("xlsx");
    } else if (resType.Body.type === "image/jpeg") {
      setOpen(false);
      setTypeFile("jpeg");
    } else if (resType.Body.type === "image/png") {
      setOpen(false);
      setTypeFile("png");
    } else if (resType.Body.type === "image/gif") {
      setOpen(false);
      setTypeFile("gif");
    } else if (resType.Body.type === "image/bmp") {
      setOpen(false);
      setTypeFile("bmp");
    } else {
      setOpen(false);
      setTypeFile("");
    }

    setOpen(true);
    setUri(res);
  }

  async function fetchDocuments(businessId) {
    try {
      const info = await API.graphql(
        graphqlOperation(GetRStatusShop, { id: businessId })
      );
      const getAffiliator = await API.graphql(
        graphqlOperation(getRolByUser, { id: info.data.getShop.affiliator_id })
      );

      const name = getAffiliator.data.getAccount.name || "";
      const last_name = getAffiliator.data.getAccount.last_name || "";
      
      setAffiliatorInfo(name + "_" + last_name);
      const docs = info.data.getShop.url_documents;
      const resDocs = JSON.parse(docs ?? {});
      if (Array.isArray(resDocs)) setDocs(resDocs);
      else setDocs([]);
    } catch (error) {
      console.log("Errores ", error);
    }
  }

  useEffect(() => {
    fetchDocuments(businessId);
  }, [businessId]);

  return (
    <>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
        }}
      >
        <div
          style={{
            margin: 5,
            width: "50%",
            height: "40px",
            display: "flex",
            flexDirection: "column",
            backgroundColor: "#F5F8FF",
            borderRadius: "5px",
            justifyContent: "center",
          }}
        >
          <p style={{ marginLeft: "2%" }}>
            Este negocio ha sido afiliado por: {affiliator}
          </p>
        </div>
        <DocshopList
          data={data}
          viewDoc={OnchangeModalView}
          downloadDoc={getDoc}
          refreshFiles={uploadFile}
          docsComplete={docs}
          idShop={businessId}
        />
      </Paper>
      {complete ? <AlertDialog visibility={true} /> : null}

      <FileViewerModal
        visibility={open}
        url={uri}
        type={typeFile}
        nameFile={nameDocS3}
      />
    </>
  );
}
