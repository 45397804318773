import { IconButton, TextField } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import DateRangeIcon from "@mui/icons-material/DateRange";
import React from "react";

export default function DatePiker({
  driveDate = null,
  setValueDrive,
  ineDate = null,
  setValueINE,
  type,
}) {
  const [openDate, setOpendate] = React.useState(false);
/*   console.log("entraaaaa"); */
  return (
    <div>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          open={openDate}
          onClose={() => setOpendate(false)}
          value={type === "ine" ? ineDate : driveDate}
          /*         minDate={new Date()} */
          onChange={
            type === "ine"
              ? (newValue) => {
                  setValueINE(newValue.$d);
                }
              : type === "drive"
              ? (newValue) => {
                  setValueDrive(newValue.$d);
                }
              : (newValue) => {
                  setValueINE(newValue.$d);
                  setValueDrive(newValue.$d);
                }
          }
          renderInput={(params) => (
            <div>
              <TextField
                style={{
                  opacity: 0,
                  width: 0,
                  height: 0,
                }}
                {...params}
              />
              <IconButton
                onClick={() => setOpendate(true)}
                edge="end"
                aria-label="download"
              >
                <DateRangeIcon color={"#4F4F4F"} />
              </IconButton>
            </div>
          )}
        />
      </LocalizationProvider>
    </div>
  );
}
