import { makeStyles } from "@mui/styles";

export const boxContainerStyles = {
  background: "white",
  width: "100%",
  //width: { xs: "100%", sm: "100%", md: "90%" },
  height: "auto",
  //maxWidth: "1110px",
  margin: { sm: "0px", md: "auto" },
  minHeight: "913px",
  padding: { xs: "60px 5px", sm: "40px 20px", md: "36px 40px 59px" },
  borderRadius: "10px"
};

export const searchFormControl = {
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  alignItems: { xs: "center", md: "baseline" },
  flexDirection: { xs: "column", md: "row" },
};

export const textContainer = {
  display: "flex",
  justifyContent: "space-between", 
  alignItems: "end"
}

const useStyles = makeStyles((theme) => ({
  zoneBusinessText:{
    fontSize: "3rem",
    fontWeight: 700,
    lineHeight: "58px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  userInfo:{
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "29px",
    letterSpacing: "0em",
    textAlign: "right",
    color: "#828282"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    flexWrap: "wrap",
    gap: 20,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  title: {
    lineHeight: "58.09px",
    fontSize: "3rem",
    color: "#333333",
  },
  label: {
    fontWeight: 500,
    marginBottom: 9,
    textAlign: "left",
    fontSize: "1.125rem",
    lineHeight: "21.09px",
  },
  inputContainer: {
    width: 250,
    paddingBottom: 10,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
      paddingBottom: "0px",
    },
  },
  selectStyles: {
    width: 206,
    height: 35,
    border: "none",
  },
  searchContainer: {
    padding: "10px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
  },
  inputStyles: {
    border: "1px solid #E0E0E0",
    borderRadius: 5,
    width: 220,
    height: 35,
    paddingLeft: 10,
    fontWeight: 500,
    [theme.breakpoints.down("lg")]: {
      width: "100%",
    },
  },
  businessContainer: {
    marginTop: 20,
    [theme.breakpoints.up("lg")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "20px 60px",
    },
  },
  businessType: {
    fontSize: "1.5rem",
    fontWeight: 500,
    textAlign: "left",
    marginTop: 40,
    [theme.breakpoints.down("md")]: {
      textAlign: "center",
    },
  },
  searchButton: {
    color: "white",
    border: "none",
    padding: 5,
    background: "#004890",
    width: 93,
    height: 35,
    borderRadius: 5,
    "&:hover": {
      cursor: "pointer",
      background: "#004890c7",
    },
  },
}));

export {useStyles};