import { makeStyles } from "@mui/styles";

export const locationContainer = {
  display: {xs: "none", md: "block"},
  background: "white",
  borderRadius: "10px",
  width: "100%",
  maxWidth: "709px",
  minHeight: "128px",
  padding: { xs: "60px 5px", sm: "40px 20px", md: "18px 40px 18px" },
  marginTop: { md: "20px", lg: "20px" },
  marginBottom: "20px",
};

export const simulatorMaps = {
  height: "278px",
  width: "619px",
  borderRadius: "10px",
  //backgroundColor: "#BDBDBD",
};

export const hiddenButton = {
  color: "#EA5B13",
  fontWeight: 700,
  fontSize: "0.875rem",
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: "grid",
    gridTemplateColumns: "1.20fr 2fr",
    width: "100%",
    maxWidth: "1164px",
    top: "16%",
    left: "13.5%",
    marginTop: "auto",
    gap: "20px 40px",
    [theme.breakpoints.down("lg")]: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
    },
  },
  header: {
    display: "flex",
    alignItems: "center",
  },
  productsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  businessImage: {
    borderRadius: "50%",
    objectFit: "cover",
    width: 66,
    height: 66,
    maxWidth: 66,
    maxHeight: 66,
    marginRight: "20px",
  },
  iconBag: { marginRight: "20px" },
  subtitle: {
    color: "#333333",
    fontWeight: 400,
    fontSize: "1.5rem",
    marginBottom: "10px",
  },
  listProducts: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "20px 60px",
    },
  },
  products: { height: "265px" },
  address: {
    width: "85%",
    marginTop: "10px",
  },
  offersContainer: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    width: "100%",
    maxWidth: "710px",
    height: "auto",
    minHeight: "312px",
    background: "#FFFFFF",
    padding: "18px 45px 23px",
    marginTop: 35,
    marginBottom: 35,
    borderRadius: "10px",
  },
  titleInfo: {
    lineHeight: "29.05px",
    fontWeight: 600,
    color: "#333333",
    fontSize: "1.5rem",
  },
}));

export {useStyles};