import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import CommentCard from "./CommentCard";
import { getListReviews } from "../Business/Infrastructure/Business.presenter";
import { commentsContainer, useStyles } from "./styles/BusinessComments.styles"; 

export default function BusinessComments({ businessId }) {
  const classes = useStyles();
  const [commentsRender, setCommentsRender] = useState(false);
  const [comments, setComments] = useState({});

  useEffect(() => {
    if (businessId) {
      fetchDataComments(businessId);
    }
  }, [businessId]);

  async function fetchDataComments(id) {
    try {
      const info = await getListReviews(id);
      setComments(info);
      if(info.length !== 0){
        setCommentsRender(true);
      }
    } catch (error) {
      console.log("Ha ocurrido un error, no hay comentarios.");
    }
  }

  return (
    <Box sx={commentsContainer}>
      <div className={classes.commentsHeader}>
        <div className={classes.header}>
          <h2>Comentarios</h2>
        </div>
      </div>
      <Divider sx={{ margin: "10px 0px" }} />
      <Box>
        {commentsRender ? (
          comments.map((value, index) =>
            index < 6 ? (
              <CommentCard key={index} id={index} businessId={businessId} name={value.evaluator_id.name} lastName={value.evaluator_id.last_name} comment={value.comment} stars={value.note}/>
            ) : (
              <></>
            )
          )
        ) : (
          <>
            {" "}
            <p style={{minHeight: "200px"}} >Sin comentarios aún</p>{" "}
          </>
        )}
      </Box>
    </Box>
  );
}
