import { makeStyles } from "@mui/styles";

export const boxContainerStyles = {
  background: "white",
  width: "100%",
  height: "auto",
  //maxWidth: "1110px",
  margin: { sm: "0px", md: "auto" },
  minHeight: "913px",
  padding: { xs: "60px 5px", sm: "0px 20px", md: "36px 0px 59px" },
  borderRadius: "10px"
};

export const textContainer = {
  display: "flex",
  justifyContent: "space-between", 
  alignItems: "end"
}

const useStyles = makeStyles((theme) => ({
  zoneBusinessText:{
    fontSize: "3rem",
    fontWeight: 700,
    lineHeight: "58px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  userInfo:{
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "29px",
    letterSpacing: "0em",
    textAlign: "right",
    color: "#828282"
  },
  headerContainer: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    gap: "26px",
    paddingLeft: "29px",
    marginBottom: "20px",
  },
}));

export {useStyles};