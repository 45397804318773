import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getZoneModel } from "../../Zones/Infrastructure/Zone.reducer";
import { getBusinessPartnerModel } from "../../BusinessPartner/Infrastructure/BusinessPartner.reducer";
import GetBusinessPartnerInfo from "../../BusinessPartner/Infrastructure/BusinessPartner.presenter";
import RangePicker from "../../DesignSystem/RangePicker";
import BasicHeader from "../../DesignSystem/BasicHeader";
import TabPanel from "../../DesignSystem/TabPanel";
import AffiliationsTable from "./AffiliationsTable";
import StatementAccountUI from "./StatementAccount.ui";
import { sub } from "date-fns";
import { useStyles,containerStyles, textContainer, reportsTabsContainer, customBtn, tabStylesTest, dividerStyles, reportsTabsFooter } from "./styles/MainReportsPage.styles";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MainReportsPageUI(){
  const classes = useStyles();
  const currentZone = useSelector(getZoneModel);
  const currentPartner = useSelector(getBusinessPartnerModel);
  const [value, setValue] = useState(1);
  const pastDayMonth = sub(new Date(), { months: 1 });
  const [betweenDates, setBetweenDates] = useState([pastDayMonth, new Date()]);

  useEffect(() => {
    const getData = async () => {
      await GetBusinessPartnerInfo(localStorage.getItem("partner"));
    } 
    if(!currentPartner){
      getData();
    }
  }, [currentPartner]);

  const getBetweenDates = (value) => {
    const firstDate = value[0].toISOString();
    const secondDate= value[1].toISOString();
    setBetweenDates([firstDate, secondDate]);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return(
    <Box sx={containerStyles}>
      <BasicHeader>
        <Box sx={textContainer}>
        {currentZone && currentPartner && (
            <>
              <h3 className={classes.zoneBusinessText}>{currentZone.zone_name}</h3>
              <Box>
                <p className={classes.userInfo}>{currentPartner.name} {currentPartner.last_name}</p>
                <p className={classes.userInfo}>{currentPartner.birthday ? moment(currentPartner.birthday).format("DD/MM/YYYY") : ""}</p>
                <p className={classes.userInfo}>{currentPartner.email}</p>
              </Box>
            </>
          )}
        </Box>
      </BasicHeader>
      <Box sx={reportsTabsContainer}>
        <Tabs sx={tabStylesTest} textColor="secondary" indicatorColor="secondary" value={value} onChange={handleChange}>
        <Tab disabled classes={{selected: classes.selectedTab, disabled: classes.disableTab}} sx={customBtn} label="Ingresos" {...a11yProps(0)} />
        <Tab classes={{selected: classes.selectedTab, disabled: classes.disableTab}} sx={customBtn} label="Estados de cuenta" {...a11yProps(1)} />
        <Tab disabled classes={{selected: classes.selectedTab, disabled: classes.disableTab}} sx={customBtn} label="Ganancias" {...a11yProps(2)} />
        <Tab disabled classes={{selected: classes.selectedTab, disabled: classes.disableTab}} sx={customBtn} label="Envíos" {...a11yProps(3)} />
        <Tab classes={{selected: classes.selectedTab, disabled: classes.disableTab}} sx={customBtn} label="Afiliaciones" {...a11yProps(4)} />
      </Tabs>
        <Divider sx={dividerStyles}/>
        <Box sx={reportsTabsFooter}>
          <Box>
            <p className={classes.labelDatePicker}>Periodo</p>
            <RangePicker widthDate="259px" initDay={pastDayMonth} typeDateRange={"Mensual"} handleDate={getBetweenDates}/>
          </Box>
        </Box>
      </Box>
        <TabPanel value={value} index={0}>
          <h1>Ingresos</h1>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <StatementAccountUI zoneName={currentZone.zone_name || ""}/>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <h1>Ganacias</h1>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <h1>Envíos</h1>
        </TabPanel>
        <TabPanel value={value} index={4}>
          {betweenDates && <AffiliationsTable  zoneName={currentZone.zone_name || ""} zone={currentZone.zone_id} betweenDates={betweenDates}/>}
        </TabPanel>
    </Box>
  );
}