import {zonesByState, getCountries, calculateProfitByZone} from "../../Provider";

export async function GetListZonesService(country){
  return zonesByState(country);
}

export async function GetCountriesListService(){
  return getCountries();
}

export function GetProfitByZoneService(info){
  return calculateProfitByZone(info);
}