import { makeStyles } from "@mui/styles";

export const generalContainer = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  alignItems: "center",
  alignContent: "center",
};

export const centarlContainer = {
  background: "white",
  borderRadius: "10px",
  width: "100%",
  height: "auto",
  maxWidth: "1148px",
  margin: { sm: "0px", md: "auto" },
  minHeight: "913px",
  padding: { xs: "60px 5px", sm: "55px 20px", md: "55px 40px 59px" },
};

const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: "3rem",
    color: "#333333",
    fontWeight: 700,
    lineHeight: "58px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  blueHeader: {
    height: "51px",
    width: "100%",
    borderRadius: "10px",
    background: "#004990",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
  },
  textMonth: {
    height: "32px",
    width: "273px",
    borderRadius: "10px",
    background: "#F2F2F2",
    color: "#EA5B13",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    justifyContent: "center",
  },
  headText: {
    fontSize: "1.125rem",
    fontWeight: 700,
    textAlign: "left",
    color: "#FFFFFF",
  },
  graphicContainer: {
    width: "418px",
    height: "721px",
    position: "relative",
    borderRadius: "15px",
    border: "1px solid #F2F2F2",
    display: "flex",
    alignItems: "center",
    rowGap: "20px",
    flexDirection: "column",
  },
  columnsContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "22px",
  },
  buttonsContainer: {
    display: "flex",
    flexDirection: "column",
    rowGap: "27px",
  },
  gainTitle: {
    fontWeight: 500,
    fontSize: "1.5rem",
    marginTop: "16px",
  },
  gainText: {
    fontWeight: 500,
    fontSize: "2.25rem",
  },
  dateLine: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "30px",
    alignItems: "center",
    marginBottom: "21px",
  },
  chartContainer: {
    maxWidth: "250px",
    minWidth: "250px",
    maxHeight: "250px",
    minHeight: "250px",
    position: "relative",
  },
  newTooltipStyle: {
    width: "418px",
    height: "300px",
    position: "relative",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  tooltipStyles:{
    background: "#FFFFFF",
    width: "322px",
    height: "234px",
    padding: "45px 12px 28px 18px",
    margin: "auto auto",
    boxShadow: "0px 3px 15px rgba(168, 168, 168, 0.25)",
    borderRadius: "20px",
    position: "absolute",
    bottom: -240,
    left: "calc(50% - 161px)",
  },
  zoneColorStyles:{
    display: "inline-block",
    height: "22px",
    width: "22px",
    borderRadius: "50%",
    position: "relative",
    top: -30,
    left: "calc(50% - 11px)",
  },
  zoneNameStyles: {
    fontWeight: 500,
    fontSize: "1.5rem",
    lineHeight: "29px",
    color: "#828282",
    textAlign: "center",
  },
  dividerStyles:{
    marginTop: "11px",
    marginBottom: "9px",
    background: "#F2F2F2",
    border: "1px solid #F2F2F2"
  },
  containerInfo:{
    display: "flex",
    justifyContent: "space-between",
  },
  totalAmount:{
    marginBottom: "21px"
  },
  info:{
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "29px",
    color: "#333333",
  }
}));

export { useStyles };
