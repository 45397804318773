import Switch from "@mui/material/Switch";
import { useStyles, switchStyles } from "./styles/CustomSwitch.styles";
import { updateShopStatus } from "../Business/Infrastructure/Business.presenter";

export default function CustomSwitch({
  shopID,
  isComplete,
  handleSwitchChange,
}) {
  const classes = useStyles();

  const handleChange = async (e) => {
    const visibility = e.target.checked;
    try {
      await updateShopStatus({ visibility, id: shopID });
      handleSwitchChange(visibility);
    } catch (error) {
      console.log("error al cambiar estatus de tienda", error);
    }
  };

  return (
    <Switch
      defaultChecked={isComplete === "Completo" ? true : false}
      onChange={handleChange}
      sx={switchStyles}
      className={classes.root}
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
    />
  );
}
