export const changeStatusDeliveryMan = /* GraphQL */ `
  mutation ChangeStatusDeliveryMan($id: ID!, $availability: Boolean) {
    updateAccount(input: { id: $id, availability: $availability }) {
      availability
      id
    }
  }
`;

export const updateAccount = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelaccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      notification_token
    }
  }
`;

export const createMessageShort = /* GraphQL */ `
  mutation createMessageShort(
    $input: CreateMessageInput!
    $condition: ModelmessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      content
      createdAt
      expiration_time
      chat_id
      image_url
      user_id {
        id
        name
        last_name
        role
      }
    }
  }
`;

export const createNewChat = /* GraphQL */ `
  mutation createNewChat(
    $input: CreateChatInput!
    $condition: ModelchatConditionInput
  ) {
    createChat(input: $input, condition: $condition) {
      id
    }
  }
`;

/*  historyLevelId_deliverymanId: "403ace8c-908f-45d7-b0ec-3a5d08b4c53c"
        historyLevelId_levelId: "8bca8340-f925-4aa2-83e7-aec4b7841403"
        start_date: "2022-08-30T00:00:00.000Z"
        status: false
        duration: 10
        expiration_date: "2022-09-05T00:00:00.000Z" */

export const CreateHistoryLevel = /* GraphGraphQL */ `
  mutation createHistoryLevel( $input: CreateHistoryLevelInput!
    $condition: ModelhistoryLevelConditionInput) {
    createHistoryLevel(input: $input, condition: $condition) {
      id_deliveryman {
        id
      }
    }
  }
`;

export const joinUserChat = /* GraphQL */ `
  mutation joinUserChat(
    $input: CreateChatUserInput!
    $condition: ModelchatUserConditionInput
  ) {
    createChatUser(input: $input, condition: $condition) {
      id
      user_id {
        name
        last_name
      }
    }
  }
`;

export const updateDeliveryStatus = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelaccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      delivery_inprocess
    }
  }
`;

export const updateDeliveryStatusApproved = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelaccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      register_approved
    }
  }
`;

export const updateOrder = /* GraphQL */ `
  mutation UpdateOrder(
    $input: UpdateOrderInput!
    $condition: ModelorderConditionInput
  ) {
    updateOrder(input: $input, condition: $condition) {
      id
      status
      level
      discount
      final_total
      method_pay
      payment_id
      address
      order_type
      delivery_man_id {
        id
      }
      collects {
        items {
          id
          picking_date
          come_date
          type
          origin_sign
          sign
          finished
          createdAt
          updatedAt
        }
        nextToken
      }
      require_bill
      require_service
      shop_comments
      createdAt
      updatedAt
    }
  }
`;

export const updateCollect = /* GraphQL */ `
  mutation UpdateCollect(
    $input: UpdateCollectInput!
    $condition: ModelcollectConditionInput
  ) {
    updateCollect(input: $input, condition: $condition) {
      id
      picking_date
      come_date
      type
      origin_sign
      sign
      finished
      deliveryman_id {
        id
      }
    }
  }
`;

export const updatePendingChats = /* GraphQL */ `
  mutation updatePendingChats(
    $input: UpdateAccountInput!
    $condition: ModelaccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      pending_chats
    }
  }
`;

//MUTATIONS OCUPADAS PARA UN SOCIO COMERCIAL
export const updateAccountOne = /* GraphQL */ `
  mutation UpdateAccount($input: UpdateAccountInput!) {
    updateAccount(input: $input) {
      id
      name
      last_name
      email
      role
    }
  }
`;

//Mutations para el CHAT NIKU
//Crea/Inicializa un nuevo chat
export const InitializeChat = `
  mutation MyMutation($input: CreateChatInput!) {
    createChat(input: $input) {
      id
      createdAt
    }
  }
`;

//Vincular el chat creado con los usuarios relacionados
export const AssignChat = `
  mutation AssingChat($input: CreateChatUserInput!) {
    createChatUser(input: $input) {
      createdAt
    }
  }
`;

//Crear un mensaje para el chat
export const createNewMessageByChat = `
  mutation createNewMessageByChat($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
      createdAt
    }
  }
`;

//update profile photo
export const updateAccountPhotoProfile = /* GraphQL */ `
  mutation updateAccountPhotoProfile($id: ID!, $url_photoProfile: String) {
    updateAccount(input: { id: $id, url_photoProfile: $url_photoProfile }) {
      id
      url_photoProfile
    }
  }
`;

export const CreateNewAccount = `
  mutation CreateNewAccount($input: CreateAccountInput!) {
    createAccount(input: $input) {
      id
      email
      last_name
      name
      sexo
      role
      zone
      birthday
      phone_number
      url_photoProfile
    }
  }
`;

export const UpdateShopVisibility = `
  mutation UpdateShopVisibility ($id: ID!, $visibility: Boolean){
    updateShop(input: {id: ,$id status: $visibility, availability: $visibility}) {
      id
      availability
      status
    }
  }
  `;

export const updateAccountDocs = /* GraphQL */ `
  mutation UpdateAccount(
    $input: UpdateAccountInput!
    $condition: ModelaccountConditionInput
  ) {
    updateAccount(input: $input, condition: $condition) {
      id
      notification_token
      birthday
      phone_number
      current_location
    }
  }
`;
export const updateShopDocs = /* GraphQL */ `
  mutation UpdateShop(
    $condition: ModelshopConditionInput
    $input: UpdateShopInput!
  ) {
    updateShop(condition: $condition, input: $input) {
      url_documents
    }
  }
`;
