import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import FlatButton from "./FlatButton";
import TabPanel from "./TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RangePicker from "./RangePicker";
import CommissionByShopTable from "./CommissionByShopTable";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { sub } from "date-fns";
import { getProfitShop } from "../Business/Infrastructure/Business.presenter";
import * as ExcelJS from "exceljs/dist/exceljs.min.js";
import { saveAs } from "file-saver";
import StatusAccount from "../hooks/statusAccount";
import GetpdfDoc from "../hooks/getPdf";
import { ListorderDelivery } from "../graphql/myQueries";

const commissionContent = {
  background: "white",
  width: "100%",
  height: "auto",
  borderRadius: "10px",
  marginTop: { md: "20px", lg: "20px" },
  minHeight: "913px",
  padding: { xs: "60px 5px", sm: "40px 20px", md: "36px 40px 59px" },
};

const typeTabs = {
  fontWeight: 700,
  fontFamily: "Montserrat",
  color: "#000",
  textTransform: "none",
};

const btnExport = {
  height: "32px",
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const columnStyles = {
  alignment: {
    vertical: "middle",
    horizontal: "center",
  },
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  emptyComissions: {
    marginTop: "60px",
    textAlign: "center",
    fontSize: "1.4rem",
  },
}));

export default function BusinessCommissionsTable({ businessName, businessId }) {
  //INSTANCIA DE LA CLASE STATUS ACCOUNT
  let calculateStatus = new StatusAccount();
  const { getPDF } = GetpdfDoc();
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [firstDate, setFirstDate] = useState(sub(new Date(), { months: 1 }));
  const [secondDate, setSecondDate] = useState(new Date());
  const [commissions, setCommissions] = useState([]);
  const [exportDisable, setExportDisable] = useState(false);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [rFc, setRfc] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [shopName, setNameShop] = useState("");
  const [address, setAddress] = useState("");
  const [recieved, setRecieved] = useState("0.00");
  const [payable, setPayable] = useState("0.00");
  const [tarife, setTarife] = useState("0.00")

  function convertUTCDateToLocalDate(type, myDate) {
    var newDate = new Date(myDate);
    //console.log("Fecha actual: ", newDate);
    newDate.setMinutes(newDate.getMinutes() - newDate.getTimezoneOffset());
    var currentDateToString = newDate.toISOString();

    var myHour = {
      hour: parseInt(currentDateToString.substring(11, 13)),
      minutes: parseInt(currentDateToString.substring(14, 16)),
      type: parseInt(currentDateToString.substring(11, 13)) < 12 ? "am" : "pm",
    };
    //return myHour;
    if (type == "date") return currentDateToString;
    else return myHour;
  }

  const getBetweenDates = (value) => {
    // console.log("🚀 ~ file: BusinessCommissionsTable.jsx ~ line 105 ~ getBetweenDates ~ value",)

    setFirstDate(convertUTCDateToLocalDate("date", value[0].toISOString()));
    setSecondDate(convertUTCDateToLocalDate("date", value[1].toISOString()));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };


  

  useEffect(() => {

    if (businessId) {
      fetchOrdersData(businessId);
    }
  }, [businessId, firstDate, secondDate]);



  function calculatetarifservice(amount) {
    var numerador = 25000;
    var denominador = 24159;
    var secondNum = 72500;
    var result = amount * (numerador / denominador) + secondNum / denominador;
    return result - amount;
  }

  //Obtener órdenes por negocio
  async function fetchOrdersData(id) {
    try {
      const info = await getProfitShop({ id, dates: [firstDate, secondDate] });

      var category = info.data.getShop.category;
      var documents = info.data.getShop.url_documents;
      var zone = info.data.getShop.zone;
      var porcentage = info.data.getShop.list_fees;
      var rfc = info.data.getShop.seller_id.rfc;
      var orders = info.data.getShop.orders.items;
      var phone = info.data.getShop.phone_number;
      var email = info.data.getShop.email;
      var name = info.data.getShop.shop_name;
      var zipCode = info.data.getShop.postal_code;
      var address = info.data.getShop.address_shop;
      var totalToReceive = 0;
      var totalPayable = 0;
      var ivaoftarif = 0;

      setPhone(phone != null ? phone : "-");
      setEmail(email != null ? email : "-");
      setNameShop(name != null ? name : "-");
      setRfc(rfc != null ? rfc : "-");
      setZipCode(zipCode != null ? zipCode : "-");
      setAddress(address != null ? address : "-");

      var orderCalculate = calculateStatus.calculateAccountStatus(
        orders,
        id,
        category,
        documents,
        zone,
        porcentage,
        rfc
      );
      // console.log("🚀 ~ file: BusinessCommissionsTable.jsx ~ line 137 ~ fetchOrdersData ~ orderCalculate", orderCalculate)

      orderCalculate.map((element) => {
        totalToReceive = totalToReceive + element.TotalADepositarporOrden;
        totalPayable = totalPayable + element.Domicializacion;
      });

      ivaoftarif = parseFloat(calculatetarifservice(totalPayable).toFixed(2));

      setTarife(ivaoftarif)
      setCommissions(orderCalculate);
      setRecieved(totalToReceive);
      setPayable(totalPayable);
      if (info.length === 0) {
        setExportDisable(true);
      } else {
        setExportDisable(false);
      }
    } catch (error) {
      console.log("Errores ", error);
    }
  }

  const handleExport = async () => {
    let rows = [];

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Comisiones envio");

    worksheet.columns = [
      {
        header: "Orden",
        key: "Orden",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Fecha",
        key: "Fecha",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Día",
        key: "Día",
        width: 20,
        style: columnStyles,
      },
      { header: "Hora", key: "Hora", width: 20, style: columnStyles },
      {
        header: "Monto que pago el cliente",
        key: "amountCl",
        width: 20,
        style: columnStyles,
      },
      {
        header: "2.9% Tasa Opp/Cliente",
        key: "tasOpp",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Comisión NXN (Openpay)",
        key: "comOpp",
        width: 20,
        style: columnStyles,
      },
      {
        header: "IVA Opp sobre comisión NXN",
        key: "IVAUPcom",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Comisión Final",
        key: "finalCom",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Dinero en pasarela",
        key: "moneyInOpp",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Forma de pago",
        key: "method_pay",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Dinero en pasarela - comisión de envio",
        key: "moneyInOpplow",
        width: 20,
        style: columnStyles,
      },
      {
        header:
          "Dinero en pasarela sin comisión de envio + Descuento de cliente",
        key: "moneyInOpphigh",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Importe de venta",
        key: "Importe",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Descuento del cliente",
        key: "DiscountCl",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Monedero",
        key: "mcc",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Subtotal",
        key: "subtotal",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Comisión NK",
        key: "ComisionNk",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Subtotal sin IVA",
        key: "SubTSinIva",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Retención IVA",
        key: "IVA",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Retención ISR",
        key: "ISR",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Comisión de envio",
        key: "comSend",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Comisión de envio de cliente",
        key: "comClient",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Propina",
        key: "propine",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Repartidor asignado por orden",
        key: "Delivery",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Total a depositar",
        key: "dispersion",
        width: 20,
        style: columnStyles,
      },
      {
        header: "Total a cobrar",
        key: "collect",
        width: 20,
        style: columnStyles,
      },
    ];

    commissions.forEach((element) => {
      rows.push({
        Orden: element.id,
        Fecha: element.creacion,
        Día: element.Fecha,
        Hora: element.Hora,
        amountCl: element.importpluscomcustomer,
        tasOpp: element.tasaOpp,
        comOpp: element.comNXN,
        IVAUPcom: element.Ivaopp,
        finalCom: element.com_opp,
        moneyInOpp: element.moneyInopp,
        method_pay: element.Formadepago,
        moneyInOpplow: element.less_send_opp,
        moneyInOpphigh: element.less_more_discount,
        Importe: element.Importventa,
        DiscountCl: element.Descuentocl,
        mcc: element.MCC,
        subtotal: element.Subtotal,
        ComisionNk: element.ComisionNk,
        SubTSinIva: element.Subsiniva,
        IVA: element.IVA8,
        ISR: element.ISR1,
        comSend: element.Comsend,
        comClient: element.ComsendCustomer,
        propine: element.propine,
        Delivery: element.deliveryman,
        dispersion: element.TotalADepositarporOrden,
        collect: element.Domicializacion,
      });
    });

    worksheet.addRows(rows, "n");

    workbook.xlsx.writeBuffer().then(function (buffer) {
      const regex = /\s/g;
      const formatedZoneName = businessName.replace(regex, "_");
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `comisiones_envio_${formatedZoneName || ""}.xlsx`
      );
    });
  };

  async function getBinaryRes(obj) {
    const res = await getPDF(obj);
    /*     console.log("🚀 ~ file: BusinessCommissionsTable.jsx ~ line 370 ~ getBinaryRes ~ res", res) */
    if (res.success) {
      let binaryString = window.atob(res.data);
      let binaryLen = binaryString.length;
      let bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        let ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      let blob = new Blob([bytes], { type: "application/pdf" });
      saveAs(blob, `comisiones_envio_${businessName || ""}.pdf`);
    } else {
      alert(res.message);
    }
  }

  const handleExportPdf = async () => {
    var arrayofordersDelive = []; //Este es un arreglo solo para ordenar los items en el pdf DELIVERY
    var arrayofordersFace = []; //Este es un arreglo solo para ordenar los items en el pdf PRESENCIAL
    var arrelittlePdfDelive = []; //Este es un arreglo solo para ordenar los items en el pdf de menor cantidad de newItems
    var arrelittlePdfFace = []; //Este es un arreglo solo para ordenar los items en el pdf de menor cantidad de newItems

    for (var i in commissions) {
      //Hago el recorrido de los arreglos que me devuelve el cálculo de estado de cuenta
      //Esta es la asignación
      if (commissions[i].TipoOrden === "PRESENCIAL") {
        var littleArrays = [
          commissions[i].Importventa,
          commissions[i].addition_comnkdiscount,
          commissions[i].ComisionNk,
          commissions[i].addition_retentions,
          commissions[i].TotalADepositarporOrden,
          commissions[i].Domicializacion,
        ];


        var arrayFacetoFace = [
          commissions[i].creacion,
          commissions[i].Hora,
          commissions[i].Formadepago,
          commissions[i].Importventa,
          commissions[i].Descuentocl,
          commissions[i].MCC,
          commissions[i].Subtotal,
          commissions[i].ComisionNk,
          commissions[i].Subsiniva,
          commissions[i].IVA8,
          commissions[i].ISR1,
          commissions[i].Comsend,
          commissions[i].ComsendCustomer,
          commissions[i].propine,
          commissions[i].TotalADepositarporOrden,
          commissions[i].Domicializacion,
        ];
        arrelittlePdfFace.push(littleArrays);
        arrayofordersFace.push(arrayFacetoFace ?? []);


      } else {
        var littleArrays = [
          commissions[i].Importventa,
          commissions[i].addition_comnkdiscount,
          commissions[i].ComisionNk,
          commissions[i].addition_retentions,
          commissions[i].TotalADepositarporOrden,
          commissions[i].Domicializacion,
        ];

        var arrayDelivery = [
          commissions[i].creacion,
          commissions[i].Hora,
          commissions[i].Formadepago,
          commissions[i].Importventa,
          commissions[i].Descuentocl,
          commissions[i].MCC,
          commissions[i].Subtotal,
          commissions[i].ComisionNk,
          commissions[i].Subsiniva,
          commissions[i].IVA8,
          commissions[i].ISR1,
          commissions[i].Comsend,
          commissions[i].ComsendCustomer,
          commissions[i].propine,
          commissions[i].TotalADepositarporOrden,
          commissions[i].Domicializacion,
        ];

        arrelittlePdfDelive.push(littleArrays ?? []);
        arrayofordersDelive.push(arrayDelivery ?? []);
      }
    }



    //En este objeto dolo asigno los valores que lleguen

    const datasendshop = {
      address: address || "-",
      clientNumber: phone || "-",
      businessName: shopName || "-",
      rfc: rFc || "-",
      zipCode: zipCode || "-",
    };

    /*   console.log("firstDate", firstDate.toISOString(), secondDate.toISOString()) */
    //Este objeto incluye toda la información de la tienda junto con sus ordenes y totales
    const objsend = {

      datesOfMovements:
        firstDate.toISOString().substring(0, 10) +
        "-" +
        secondDate.toISOString().substring(0, 10),
      sellerData: datasendshop,
      faceToFaceDetailsData: arrayofordersFace,
      deliveryDetailsData: arrayofordersDelive,
      faceToFaceData: arrelittlePdfFace,
      deliveryData: arrelittlePdfDelive,
      totalToReceive: recieved > 0 ? recieved : "0.00",
      totalPayable: payable > 0 ? payable + tarife : "0.00",
    };
    // console.log("🚀 ~ file: BusinessCommissionsTable.jsx ~ line 424 ~ handleExportPdf ~ arrayofordersFace", arrelittlePdfDelive)
    // console.log("🚀 ~ file: BusinessCommissionsTable.jsx ~ line 471 ~ handleExportPdf ~ objsend", objsend)
    getBinaryRes(objsend);
  };

  return (
    <Box sx={commissionContent}>
      <Box className={classes.headerContainer}>
        {/* <DateRangePicker title={"Período (30 días)"} widthDate={"200px"} /> */}
        <RangePicker
          widthDate={"200px"}
          initDay={firstDate}
          typeDateRange="Mensual"
          handleDate={getBetweenDates}
        />
        <FlatButton
          isDisable={exportDisable}
          title={"Exportar"}
          typeButton="button"
          icon={<FileUploadOutlinedIcon />}
          widthBtn="167px"
          customStyles={btnExport}
          onClick={handleExport}
        />
        <FlatButton
          isDisable={exportDisable}
          title={"Exportar PDF"}
          typeButton="button"
          icon={<FileUploadOutlinedIcon />}
          widthBtn="167px"
          customStyles={btnExport}
          onClick={handleExportPdf}
        />
      </Box>
      <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          textColor="secondary"
          indicatorColor="secondary"
          value={value}
          onChange={handleChange}
        >
          <Tab sx={typeTabs} label="Comisión por envío" {...a11yProps(0)} />
        </Tabs>
      </Box>

      <div>
        <TabPanel value={value} index={0}>
          {commissions.length !== 0 ? (
            <CommissionByShopTable
              businessName={businessName}
              data={commissions}
            />
          ) : (
            <h3 className={classes.emptyComissions}>Sin registros</h3>
          )}
        </TabPanel>
      </div>
    </Box>
  );
}
