import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import Card from "@mui/material/Card";
import { useStyles } from "./styles/BusinessCard.styles";
import { GetPhotoProfile } from "../User/Infrastructure/User.presenter";
import BusinessImage from "../Business/UI/BusinessImage";

export default function BusinessCard({ id, name, image}) {
  const classes = useStyles();
  const history = useHistory();
  const [photo, setPhoto] = useState(null);

  const getUrlPhoto = async (urlPhoto) => {
    const res = await GetPhotoProfile(urlPhoto);
    setPhoto(res);
  };

  function selectBusiness() {
    localStorage.setItem("business", id);
    history.push(`/estados/zonas/negocios/${name}`);
  }

  useEffect(() => {
    if (image) {
      getUrlPhoto(image);
    }
  }, [image]);

  return (
    <Card className={classes.root} sx={{boxShadow: "none", borderRadius: "15px"}} onClick={selectBusiness}>
      <BusinessImage businessImage={photo}/>
      <div className={classes.infoContainer}>
        <h3 className={classes.businessName}>{name}</h3>
      </div>
    </Card>
  );
}

BusinessCard.defaultProps = {
  image: "",
};

BusinessCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.string,
};
