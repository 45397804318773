import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  useStyles,
  infoContainer,
  headerInfo,
} from "./styles/BusinessDetailsCard.styles";
import { getClbShop } from "../Infrastructure/Business.presenter";

function BusinessDetailsClb({ idshop }) {
  const classes = useStyles();
  const [statusCard, setStatusCard] = useState(false);
  const [clbShop, setClbShop] = useState(false);

  useEffect(() => {
    const shopClb = async () => {
      try {
        const response = await getClbShop(idshop);
        var interclb = response.data.getShop.interclb ?? "";

        if (interclb !== "") {
          var objClb = JSON.parse(response.data.getShop.interclb);
          var idOpp = objClb.id ?? "";
          var idcard = objClb.idcard ?? "";
          var idclabe = objClb.idclabe ?? "";

          if (idOpp !== "" && idOpp !== null && idOpp !== undefined) {
            //VALIDACIÓN DE TARJETA REGISTRADA
            if (idcard !== "" && idcard !== null && idcard !== undefined) {
              setStatusCard(true);
            } else {
              setStatusCard(false);
            }
            //VALIDACIÓN DE CLABE BANCARIA
            if (idclabe !== "" && idclabe !== null && idclabe !== undefined) {
              setClbShop(true);
            } else {
              setClbShop(false);
            }
          } else {
            setStatusCard(false);
            setClbShop(false);
          }
        } else {
          setStatusCard(false);
          setClbShop(false);
        }
      } catch (error) {
        console.log("error en rating shop", error);
      }
    };
    shopClb();
  }, [idshop]);

  return (
    <Box
      sx={[
        infoContainer,
        {
          backgroundColor: "#f4f4f4",
          borderRadius: 2,
          height: 150,
          justifyContent: "center",
        },
      ]}
    >
      <Box sx={headerInfo}>
        <InfoOutlinedIcon
          sx={{ color: "#EA5B13", width: "24px", height: "24px" }}
        />
        <h4 className={classes.titleInfo}>Información bancaria</h4>
      </Box>
      <Box sx={headerInfo}>
        <p className={classes.key}>CLABE Interbancaria:</p>
        &nbsp;
        <p
          className={classes.value}
          style={{
            color: statusCard ? "green" : "red",
            fontSize: 20,
          }}
        >
          {clbShop ? "Completo" : "Incompleto"}
        </p>
      </Box>
      <Box sx={headerInfo}>
        <p className={classes.key}>Tarjeta:</p>
        &nbsp;
        <p
          className={classes.value}
          style={{
            color: statusCard ? "green" : "red",
            fontSize: 20,
          }}
        >
          {statusCard ? "Completo" : "Incompleto"}
        </p>
      </Box>
    </Box>
  );
}
export default BusinessDetailsClb;
