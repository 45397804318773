import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import img from "../assets/default.png";
import ModalProfile from "./ModalProfile";
import { GetPhotoProfile } from "../User/Infrastructure/User.presenter";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import Stack from "@mui/material/Stack";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import CreditCardOffIcon from "@mui/icons-material/CreditCardOff";
import { Badge } from "@mui/material";
import { API, graphqlOperation } from "aws-amplify";
import { ListorderDelivery } from "../graphql/myQueries";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    border: "1px solid #E5E5E5",
    borderRadius: 10,
    maxWidth: 400,
    minHeight: 116,
    height: "auto",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
      background: "#E0E0E0",
    },
    [theme.breakpoints.down("lg")]: {
      margin: "20px auto",
    },
    [theme.breakpoints.down("md")]: {
      border: "none",
      margin: "10px auto",
      padding: "5px 20px",
      minHeight: 100,
    },
  },
  deliveryImage: {
    width: 60,
    height: 60,
    maxHeight: 60,
    minHeight: 60,
    maxWidth: 60,
    minWidth: 60,
    borderRadius: 10,
    objectFit: "cover",
    [theme.breakpoints.down("md")]: {
      borderRadius: "50%",
      border: "3px solid #6CB22E",
    },
  },
  infoContainer: {
    width: "100%",
    textAlign: "left",
  },
  deliveryName: {
    fontSize: "1.5rem",
    fontWeight: 500,
    marginBottom: 10,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
  matricula: {
    fontSize: 16,
    color: "#828282",
    fontWeight: 400,
  },
  lastTime: {
    fontSize: "1rem",
    color: "#000000",
    fontWeight: 500,
    /* fontWeight: 400, */
  },
}));

export default function DeliveryManCard({
  id,
  name,
  lastName,
  image,
  matricula,
  date,
  handleDeletedUser,
  vehicle,
  active,
  registerDataBank,
}) {
  const classes = useStyles();
  const [isOpenProfileModal, setProfileModal] = useState(false);
  const [photo, setPhoto] = useState(null);
  const showProfileModal = () => setProfileModal(true);
  const [breakpoint, setBreakpoint] = useState(
    window.innerWidth <= 768 ? true : false
  );
  const [someTimeAgo, setTimeAgo] = useState("");
  const [countOrders, setCountOrders] = useState("");

  function covertDate(fechaString) {
    var fechaSp = fechaString.split("-");
    var anio = new Date().getFullYear();
    if (fechaSp.length === 3) {
      anio = fechaSp[2];
    }
    var mes = fechaSp[1] - 1;
    var dia = fechaSp[0];

    return new Date(anio, mes, dia);
  }

  async function getDatesOrders(limit = 1000, nextToken = null, id, arreDates) {
    try {
      const res = await API.graphql(
        graphqlOperation(ListorderDelivery, {
          id: id,
          nextToken: nextToken,
          limit: limit,
          betweenDates: arreDates,
        })
      );

      var resArray = res.data.getAccount.deliveryman_orders.items;
      nextToken = res.data.getAccount.deliveryman_orders.nextToken;

      var newOrderDates = [];
      var arre_ord = [];

      for (var i = 0; i < resArray.length; i++) {
        arre_ord.push(resArray[i]);
        var ano = resArray[i].updatedAt.substring(0, 4);
        var month = resArray[i].updatedAt.substring(5, 7);
        var day = resArray[i].updatedAt.substring(8, 10);
        var date = day + "-" + month + "-" + ano;
        newOrderDates.push({ fecha: date });
      }

      if (nextToken != null && nextToken != undefined) {
        await getDatesOrders(1000, nextToken, id, arreDates);
      } else {
        return {
          lastDate: newOrderDates.sort(function (a, b) {
            return covertDate(a.fecha) - covertDate(b.fecha);
          })[newOrderDates.length - 1].fecha,
          finishedOrders: arre_ord,
        };
      }
    } catch (exception) {
      return null;
    }
  }

  async function checkActivityState() {
    // await getDatesOrdersCalculate(1000, null, info[i].id);
    let initialDate = new Date();
    let temp = new Date(initialDate);
    let ant = 7 * 86399.9; //dias en segundos
    let finalDate = new Date(temp.setSeconds(-ant));
    var arreDates = [finalDate.toISOString(), initialDate.toISOString()];
    const res = await getDatesOrders(1000, null, id, arreDates);
    console.log("🚀 ~ file: DeliveryManCard.jsx:166 ~ checkActivityState ~ res", res)
    if (res !== null) {
      let newDate =
        res.lastDate.substring(6, 10) +
        "-" +
        res.lastDate.substring(3, 5) +
        "-" +
        res.lastDate.substring(0, 2);
      setTimeAgo(newDate + "T00:00:00.000Z");
      setCountOrders(res.finishedOrders.length);
    } else {
      setTimeAgo("Sin actividad");
      setCountOrders(0);
    }
  }
  const getUrlPhoto = async (urlPhoto) => {
    const res = await GetPhotoProfile(urlPhoto);
    setPhoto(res);
  };

  function getTime() {
    if (someTimeAgo === "Sin actividad") {
      // console.log("🚀 ~ file: DeliveryManCard.jsx ~ line", someTiemAgo)
    } else {
      let date = new Date(someTimeAgo);
      let initialDate = new Date();

      let temp = new Date(initialDate);
      let ant = date.getDate() * 86399.9; //dias en segundos
      let finalDate = new Date(temp.setSeconds(-ant));

      return finalDate.getDate();
    }
  }

  useEffect(() => {
    checkActivityState();
  }, [id]);
  
  useEffect(() => {
    if (image) {
      getUrlPhoto(image);
    }
  }, [image]);

  const handleProfileModal = (profileModalState) => {
    setProfileModal(profileModalState);
  };

  return (
    <>
      <Badge color="success" badgeContent={getTime() > 0 ? "Esta semana" : 0}>
        <Card
          className={classes.root}
          sx={{ boxShadow: "none" }}
          onClick={showProfileModal}
        >
          <img
            className={classes.deliveryImage}
            src={image ? photo : img}
            alt=""
          />
          <div className={classes.infoContainer}>
            <h3 className={classes.deliveryName}>
              {name} {lastName}
            </h3>
            {breakpoint ? (
              <></>
            ) : (
              <>
                {/*     <p className={classes.lastTime}>Última actividad:</p> */}
                <p className={classes.lastTime}>
                  Última vez:{" "}
                  {someTimeAgo === "Sin actividad"
                    ? someTimeAgo
                    : getTime() > 0
                    ? getTime() + " " + "Días"
                    : "Hoy"}
                </p>
                {/* <p className={classes.matricula}>{matricula}</p> */}
                <p className={classes.matricula}>
                  Ordenes finalizadas: {countOrders}
                </p>
                <p className={classes.matricula}>{date}</p>
                <p className={classes.matricula}>{vehicle}</p>
                <Stack spacing={2} direction="row">
                  {active ? (
                    <>
                      <CheckCircleIcon sx={{ color: "green" }} />
                      <p
                        style={{ color: "green" }}
                        className={classes.matricula}
                      >
                        Validado
                      </p>
                    </>
                  ) : (
                    <>
                      <CancelIcon sx={{ color: "red" }} />
                      <p style={{ color: "red" }} className={classes.matricula}>
                        Invalidado
                      </p>
                    </>
                  )}
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ alignItems: "center", display: "flex" }}
                >
                  {registerDataBank != null &&
                  JSON.parse(registerDataBank).idcard != "" ? (
                    <>
                      <CreditCardIcon sx={{ color: "green" }} />
                      <p
                        style={{ color: "green" }}
                        className={classes.matricula}
                      >
                        Datos bancarios completos
                      </p>
                    </>
                  ) : (
                    <>
                      <CreditCardOffIcon sx={{ color: "red" }} />
                      <p style={{ color: "red" }} className={classes.matricula}>
                        Datos bancarios incompletos
                      </p>
                    </>
                  )}
                </Stack>
              </>
            )}
          </div>
        </Card>
      </Badge>
      <ModalProfile
        id={id}
        isOpen={isOpenProfileModal}
        handleModal={handleProfileModal}
        name={name}
        lastName={lastName === null ? "" : lastName}
        profileImage={image ? photo : img}
        role="repartidor"
        stars="5"
        vehicle={matricula}
        typeVehicle={vehicle}
        handleDeletedUser={handleDeletedUser} // hacer peticion de la lista
        countOrders={countOrders}
        registerDataBank={registerDataBank}
      />
    </>
  );
}

DeliveryManCard.defaultProps = {
  matricula: "00-00-00",
  image: "",
};

DeliveryManCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  image: PropTypes.string,
  matricula: PropTypes.string,
  date: PropTypes.string,
  active: PropTypes.bool,
};
