export const listOrdersToSearchBar =
  /* GraphQL */
  `
    query getOrdersByUserId($id: ID!) {
      getAccount(id: $id) {
        deliveryman_orders {
          items {
            id
            method_pay
            order_type
            payment_id
            status
            address
            createdAt
            discount
            final_total
          }
        }
      }
    }
  `;

export const GetRoleShop =
  /* GraphQL */
  `
    query GetRoleShop($id: ID!) {
      getAccount(id: $id) {
        role
      }
    }
  `;
export const GetRStatusShop =
  /* GraphQL */
  `
    query getRStatusShop($id: ID!) {
      getShop(id: $id) {
        status
        url_documents
        affiliator_id
      }
    }
  `;

export const ListOrdersBySearchBar =
  /* GraphQL */
  `
    query ListOrdersBySearchBar($order_type: String) {
      listOrders(filter: { order_type: { contains: $order_type } }) {
        items {
          address
          createdAt
          final_total
          discount
          id
          method_pay
          order_type
          payment_id
          status
          products {
            items {
              id
              product_id {
                product_name
                url_image
              }
            }
          }
        }
      }
    }
  `;

export const getCollectOrders =
  /* GraphQL */
  `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        deliveryman_orders(filter: { status: { ne: "FINALIZADO" } }) {
          items {
            level
            final_total
            phase
            method_pay
            id
            status
            order_type
            address
            createdAt
            shop_id {
              id
              shop_name
              address_shop
            }
            delivery_man_id {
              id
            }
            customer_id {
              id
              name
              last_name
            }
          }
        }
      }
    }
  `;

export const getInfoCollect =
  /* GraphQL */
  `
    query GetOrder($id: ID!) {
      getOrder(id: $id) {
        products {
          items {
            product_id {
              product_name
            }
            comments
            quantity
            createdAt
          }
        }
        collects {
          items {
            id
            type
            finished
            createdAt
          }
        }
        level
        phase
      }
    }
  `;

export const getCurrentOrder =
  /* GraphQL */
  `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        deliveryman_orders(filter: { status: { ne: "FINALIZADO" } }) {
          items {
            id
            status
            order_type
            customer_id {
              id
            }
          }
        }
      }
    }
  `;

export const getCollectbyIdOrder =
  /* GraphQL */
  `
    query GetOrder($id: ID!) {
      getOrder(id: $id) {
        collects {
          items {
            id
            type
          }
        }
      }
    }
  `;

export const getRolByUser =
  /* GraphQL */
  `
    query getRolByUser($id: ID!) {
      getAccount(id: $id) {
        id
        name
        last_name
        role
      }
    }
  `;
export const getStatus =
  /* GraphQL */
  `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        availability
        delivery_inprocess
      }
    }
  `;

export const getInfoDelivery =
  /* GraphQL */
  `
    query GetAccount($id: ID!) {
      getAccount(id: $id) {
        name
        last_name
        delivery_inprocess
        url_photoProfile
        availability
        vehicles {
          items {
            id
            price
            type_vehicle
            mark
            model
            temporal
            niv
          }
        }
        deliveryman_orders {
          items {
            id
            createdAt
          }
        }
        deliveryman_collects {
          items {
            id
            createdAt
          }
        }
        payments_vehicle {
          items {
            date
            pay
            method_pay
          }
        }
      }
    }
  `;

export const getChatsByUserId =
  /* GraphQL */
  `
    query getChatsByUserId($id: ID!) {
      getAccount(id: $id) {
        id
        pending_chats
        chatsUser {
          items {
            chat_id {
              id
              chatsUser {
                items {
                  user_id {
                    id
                    name
                    last_name
                    role
                    notification_token
                    url_photoProfile
                    shops {
                      items {
                        shop_name
                        url_image
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  `;

export const getMessagesByChat =
  /* GraphQL */
  `
    query getMessagesByChat($chat_id: String!) {
      listMessages(filter: { chat_id: { eq: $chat_id } }) {
        items {
          id
          content
          chat_id
          user_id {
            name
            id
          }
          createdAt
        }
      }
    }
  `;

export const getUserOfaChat =
  /* GraphQL */
  `
    query getUserOfaChat($chat_id: ID!) {
      getChat(id: $chat_id) {
        chatsUser {
          items {
            user_id {
              id
              name
              last_name
            }
          }
        }
      }
    }
  `;

export const getOrderByIDDelivery =
  /* GraphQL */
  `
    query getOrderByIDDelivery($id: ID!) {
      getAccount(id: $id) {
        deliveryman_orders(filter: { status: { eq: "EN ESPERA" } }) {
          items {
            order_type
            address
            shop_id {
              shop_name
              address_shop
            }
            customer_id {
              id
            }
            delivery_man_id {
              id
            }
            order_type
            id
            level
            status
            products {
              items {
                comments
                quantity
                url_audio
                url_image
                product_id {
                  product_name
                  description
                }
              }
            }
          }
        }
      }
    }
  `;

export const getOrderinProcess =
  /* GraphQL */
  `
    query getOrderinProcess($id: ID!) {
      getAccount(id: $id) {
        deliveryman_orders(filter: { status: { ne: "FINALIZADO" } }) {
          items {
            id
            level
            shop_id {
              shop_name
              address_shop
            }
            order_case
            phase
            customer_id {
              name
              last_name
              id
              url_photoProfile
              notification_token
            }
            delivery_man_id {
              id
            }
            shop_id {
              seller_id {
                id
                notification_token
              }
              url_image
            }
            orderDetails
            address
            order_type
            method_pay
            status
            require_service
            final_total
            createdAt
            com_shop
            com_customer
          }
        }
      }
    }
  `;

export const getOrder =
  /* GraphQL */
  `
    query GetOrder($id: ID!) {
      getOrder(id: $id) {
        id
        customer_id {
          id
          name
          last_name
          email
          url_photoProfile
          notification_token
        }
        delivery_man_id {
          id
          name
          last_name
        }
        phase
        status
        level
        final_total
        total
        propina
        discount
        products {
          items {
            price
            id
            product_id {
              product_name
              description
              price
              ##url_image
              price
              offerprice
            }
            quantity
            comments
            url_audio
            url_image
          }
        }
        method_pay
        address
        order_type
        order_case
        orderDetails
        shop_id {
          id
          shop_name
          availability
          status
          phone_number
          url_image
          price
          seller_id {
            id
            role
            name
            last_name
            availability
            notification_token
          }
          address_shop
          shop_type
        }
        collects {
          items {
            id
            finished
            deliveryman_id {
              id
            }
          }
        }
        require_service
        createdAt
        com_shop
        com_customer
      }
    }
  `;

export const messageByCreationDate =
  /* GraphQL */
  `
    query messageByCreationDate($chat_id: String!) {
      messageByCreationDate(chat_id: $chat_id, sortDirection: DESC) {
        items {
          id
          content
          image_url
          chat_id
          user_id {
            name
            id
          }
          createdAt
        }
      }
    }
  `;

// get comments using id account
export const getReviewsbyEvaluated =
  /* GraphQL */
  `
    query getReviewsbyEvaluated($reviewEvaluated_idId: String!) {
      getReviewsbyEvaluated(
        reviewEvaluated_idId: $reviewEvaluated_idId
        sortDirection: DESC
      ) {
        items {
          note
        }
      }
    }
  `;

// get comments using id account
export const ordersByUpdatedDate =
  /* GraphQL */
  `
    query ordersByUpdatedDate($order_type: String!) {
      ordersByUpdatedDate(
        filter: { order_type: { notContains: $order_type } }
        sortDirection: ASC
        status: "LISTO"
      ) {
        items {
          address
          createdAt
          id
          order_case
        }
      }
    }
  `;

export const listProductsOfAnOrder =
  /* GraphQL */
  `
    query ListProductsOfAnOrder($id: ID!) {
      listOrders(filter: { id: { eq: $id } }) {
        items {
          products {
            items {
              product_id {
                product_name
                price
                product_type
                url_image
                description
                id
              }
            }
          }
        }
      }
    }
  `;

export const listAcceptedOrders =
  /* GraphQL */
  `
    query listAcceptedOrders {
      listOrders(
        filter: {
          status: { contains: "ACEPTADA" }
          order_type: { ne: "PRESENCIAL" }
        }
      ) {
        items {
          address
          createdAt
          id
        }
      }
    }
  `;

export const getOrderByOrderId =
  /* GraphQL */
  `
    query GetOrder($id: ID!) {
      getOrder(id: $id) {
        id
        status
        discount
        final_total
        method_pay
        payment_id
        address
        order_type
        shop_id {
          id
          shop_name
          business_name
          category
          availability
          status
          phone_number
          deleted_at
          seller_id {
            id
            role
            interests
            nikucoins
            deleted_at
            createdAt
            updatedAt
          }
          address_shop
        }
        require_bill
        createdAt
        updatedAt
      }
    }
  `;

export const QueryFetchTips =
  /* GraphQL */
  `
    query GetTips($id: ID!) {
      getAccount(id: $id) {
        deliveryman_orders {
          items {
            id
            propina
            createdAt
            method_pay
          }
        }
      }
    }
  `;

export const getPendingChats =
  /* GraphQL */
  `
    query getPendingChats($id: ID!) {
      getAccount(id: $id) {
        pending_chats
      }
    }
  `;

export const getNotificationTokens =
  /* GraphQL */
  `
    query getNotificationTokens($id: ID!) {
      getAccount(id: $id) {
        notification_token
      }
    }
  `;

// QUERIES OCUPADAS EN LA APLICACIÓN SOCIO COMERCIAL

// Listar cuentas por rol y por zona
export const listAccountsByRol =
  /* GraphQL */
  `
    query listAccountsByRol($role: String, $nextToken: String) {
      listAccounts(
        filter: {
          role: { eq: $role }
          email: { notContains: "registerDelivery@tochange.som" }
        }
        limit: 1000
        nextToken: $nextToken
      ) {
        items {
          register_approved
          id
          name
          last_name
          role
          url_photoProfile
          createdAt
          delivery_transport
          openPay_id
        }
        nextToken
      }
    }
  `;

export const listDocsDelivery =
  /* GraphQL */
  `
    query listDocsDelivery($id: ID!) {
      getAccount(id: $id) {
        delivery_docs
        availability
      }
    }
  `;

export const listApproved =
  /* GraphQL */
  `
    query listApproved($id: ID!) {
      getAccount(id: $id) {
        register_approved
      }
    }
  `;
export const listReferral =
  /* GraphQL */
  `
    query listReferral($id: ID!) {
      getAccount(id: $id) {
        referral_id {
          name
          last_name
        }
      }
    }
  `;

export const listAccountsBy =
  /* GraphQL */
  `
    query listAccountsByRol($role: String, $nextToken: String) {
      listAccounts(
        filter: { role: { contains: $role } }
        limit: 1000
        nextToken: $nextToken
      ) {
        items {
          id
          name
          last_name
          role
          url_photoProfile
          createdAt
        }
        nextToken
      }
    }
  `;

// Mostrar la lista de productos en oferta de un negocio
export const listOffertProducts =
  /* GraphQL */
  `
    query listOffertProducts($id: ID!) {
      getShop(id: $id) {
        id
        business_name
        products(filter: { offerprice: { gt: 0 } }) {
          items {
            id
            product_name
            product_type
            description
            offerprice
            price
            netcontent
            url_image
          }
        }
      }
    }
  `;

// Mostrar información personalizada de un negocio
export const GetShopProfile =
  /* GraphQL */
  `
    query GetShopProfile($id: ID!) {
      getShop(id: $id) {
        id
        url_image
        schedule
        shop_name
        description
        address_shop
        address_coords
        price
        url_documents
        url_pdf
        createdAt
        affiliator_id
        seller_id {
          id
          name
          last_name
        }
        products {
          items {
            id
            product_name
            product_type
            description
            netcontent
            price
            offerprice
            url_image
          }
        }
      }
    }
  `;

// Mostrar información de los empleados de una tienda
export const GetAccountProfile =
  /* GraphQL */
  `
    query GetAccountProfile($id: ID!) {
      getAccount(id: $id) {
        id
        name
        last_name
      }
    }
  `;

// Mostrar información de los empleados de una tienda
export const ListorderDelivery =
  /* GraphQL */
  `
    query ListorderDelivery(
      $id: ID!
      $nextToken: String
      $limit: Int
      $betweenDates: [String]
    ) {
      getAccount(id: $id) {
        deliveryman_orders(
          filter: {
            updatedAt: { between: $betweenDates }
            status: { eq: "FINALIZADO" }
            level: { eq: "FINALIZADO" }
          }
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            order_case
            order_type
            method_pay
            nc
            level
            total
            final_total
            com_customer
            com_shop
            createdAt
            propina
            shipping_fees
            updatedAt
          }
          nextToken
        }
      }
    }
  `;

export const ListorderDeliverySum =
  /* GraphQL */
  `
    query ListorderDelivery($id: ID!, $nextToken: String, $limit: Int) {
      getAccount(id: $id) {
        deliveryman_orders(
          filter: { status: { eq: "FINALIZADO" }, level: { eq: "FINALIZADO" } }
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
            order_case
            order_type
            method_pay
            nc
            level
            total
            final_total
            com_customer
            com_shop
            createdAt
            propina
            shipping_fees
            updatedAt
          }
          nextToken
        }
      }
    }
  `;

// Mostrar el número de tiendas afiliadas por fechas
export const GetShopsBetweenDates = `
    query getShopsBetweenDates ($affiliator_id: String, $betweenDates: [String], $nextToken: String){
        listShops(filter: {createdAt: {between: $betweenDates}, affiliator_id: {eq: $affiliator_id}}, limit: 1000, nextToken: $nextToken){
            items{
                id
                createdAt
                affiliator_id
            }
            nextToken
        }
    }
`;

// Mostrar la lista de negocios correspondientes a una zona
export const ListShopsByZone = `
    query ListShopsByZone ($zone_id: String, $nextToken: String){
        listShops(filter: {zone: {contains: $zone_id}}, limit: 1000, nextToken: $nextToken) {
            items {
                id
                zone
                shop_name
                description
                url_image
                category
                schedule
            }
            nextToken
        }
    }
`;

// Mostrar la lista de negocios por zona y por categoría
export const ListShopsByZoneAndCategory = `
    query ListShopsByZoneAndCategory ($zone_id: String, $category: String, $nextToken: String){
        listShops(filter: {zone: {contains: $zone_id}, category: {contains: $category}}, limit: 1000, nextToken: $nextToken) {
            items {
                id
                zone
                shop_name
                description
                url_image
                category
                schedule
            }
            nextToken
        }
    }
`;

// Filtrar lista de negocios por nombre y zona
export const ListShopsByZoneAndName = `
    query ListShopsByZoneAndCategory ($zone_id: String, $shop_name: String, $nextToken: String){
        listShops(filter: {zone: {contains: $zone_id}, shop_name: {contains: $shop_name}}, limit: 1000, nextToken: $nextToken) {
            items {
                id
                zone
                shop_name
                description
                url_image
                category
                schedule
            }
            nextToken
        }
    }
`;

// Listar categorias de negocios
export const ListCategoriesShop = `
  query ListCategoriesShop ($nextToken: String){
    listCategorys(limit: 1000, nextToken: $nextToken) {
      items {
        category_name
      }
      nextToken
    }
  }
`;

// Viajes de un deliveryman entre dos fechas
export const getCollectsByDelivery =
  /* GraphQL */
  `
    query getCollectsByDelivery($id: ID!, $betweenDates: [String]) {
      getAccount(id: $id) {
        id
        name
        deliveryman_collects(
          filter: {
            picking_date: { between: $betweenDates }
            finished: { eq: true }
          }
        ) {
          items {
            id
            picking_date
            finished
          }
        }
      }
    }
  `;

// Filtrar trabajadores por zona, rol y nombre para la barra de busqueda
export const searchBarAccounts =
  /* GraphQL */
  `
    query searchBarAccounts($role: String, $name: String, $nextToken: String) {
      listAccounts(
        filter: { name: { contains: $name }, role: { contains: $role } }
        limit: 1000
        nextToken: $nextToken
      ) {
        items {
          register_approved
          id
          name
          last_name
          role
          url_photoProfile
          createdAt
          delivery_transport
          openPay_id
        }
        nextToken
      }
    }
  `;

// Mostrar las notas de un delivery o un negocio para poder calcular su promedio
export const getReviewsNotesByID = `
  query getReviewsNotesByID ($id: String, $nextToken: String){
    listReviews(filter: {reviewEvaluated_idId: {contains: $id}}, nextToken: $nextToken) {
      items {
        note
      }
      nextToken
    }
  }
`;

// Mostrar los comentarios relacionados a un negocio
export const getReviewsByEvaluatedID = `
  query getReviewsNotesByID ($id: String, $nextToken: String){
    listReviews(filter: {reviewEvaluated_idId: {contains: $id}}, nextToken: $nextToken) {
      items {
        note
        comment
        createdAt
        evaluator_id {
          last_name
          name
          email
          role
          url_photoProfile
        }
      }
      nextToken
    }
  }
`;

// Mostrar los comentarios relacionados a un negocio por fecha
export const getReviewsByEvaluatedIDAndDate = `
  query getReviewsByEvaluatedIDAndDate ($id: String, $nextToken: String, $date: String){
    listReviews(filter: {reviewEvaluated_idId: {contains: $id}, createdAt: {beginsWith: $date}}, nextToken: $nextToken) {
      items {
        note
        comment
        createdAt
        evaluator_id {
          last_name
          name
          email
          role
          url_photoProfile
        }
      }
      nextToken
    }
  }
`;

// Queries relacionadas al CHAT NIKU

// Verificar si un chat existe buscando su id
export const getChatById = `
  query getChatById ($id: ID!) {
    getChat(id: $id) {
      active
    }
  }
`;

// Mostrar chats de un socio
export const ListChatsByUser = `
  query ListChatsByUser($id: ID!, $nextToken: String){
    listChatUsers(filter: {id: {contains: $id}}, limit: 1000, nextToken: $nextToken) {
      items {
        id
        createdAt
        user_id {
          name
          last_name
          email
          url_photoProfile
          phone_number
        }
      }
      nextToken
    }
  }
`;

// Mostrar chats de un socio
export const ListMessagesByChat = `
  query ListMessagesByChat($chat_id: String, $nextToken: String){
    messageByCreationDate(chat_id:  $chat_id, limit: 1000, nextToken: $nextToken, sortDirection:ASC) {
      nextToken
      items {
        chat_id
        content
        user_id {
          id
          name
          last_name
          url_photoProfile
          phone_number
        }
      }
    }
  }
`;

// Comisión por tienda
export const OrdersbyShop =
  /* GraphQL */
  `
    query OrdersbyShop($id: ID!) {
      getShop(id: $id) {
        shop_name
        orders(
          filter: {
            level: { contains: "FINALIZADO" }
            status: { eq: "FINALIZADO" }
          }
        ) {
          items {
            id
            createdAt
            discount
            total
            final_total
            status
            method_pay
            com_shop
            com_customer
            order_type
          }
        }
      }
    }
  `;

// Lista de tiendas con su comisión
export const OrdersByShops =
  /* GraphQL */
  `
    query OrdersByShops($zone: String) {
      listShops(filter: { zone: { contains: $zone } }, limit: 2000) {
        items {
          id
          shop_name
          orders(
            filter: {
              level: { contains: "FINALIZADO" }
              status: { eq: "FINALIZADO" }
            }
          ) {
            items {
              id
              createdAt
              discount
              total
              final_total
              status
              method_pay
              com_shop
              com_customer
              order_type
            }
          }
        }
      }
    }
  `;

// Ordenes de una tienda
export const OrdersCommissions =
  /* GraphQL */
  `
    query OrdersCommissions($id: ID!, $nextToken: String) {
      getShop(id: $id) {
        shop_name
        orders(
          filter: {
            level: { contains: "FINALIZADO" }
            status: { eq: "FINALIZADO" }
          }
          limit: 1000
          nextToken: $nextToken
        ) {
          items {
            id
            final_total
          }
        }
      }
    }
  `;

// Estados
export const countries =
  /* GraphQL */
  `
    query countries($nextToken: String) {
      listBases(limit: 1000, nextToken: $nextToken) {
        items {
          id
          country
        }
        nextToken
      }
    }
  `;

// Zonas filtradas por estado
export const ZoneByAddress =
  /* GraphQL */
  `
    query ZoneByAddress($nextToken: String, $country: String) {
      listBases(
        filter: { country: { contains: $country } }
        limit: 1000
        nextToken: $nextToken
      ) {
        items {
          zone_id {
            id
            zone_name
            franchisse_id {
              id
              name
              last_name
              birthday
              email
            }
          }
        }
        nextToken
      }
    }
  `;

// Nueva query para listar estadoss
export const states =
  /* GraphQL */
  `
    query states($nextToken: String) {
      listZones(limit: 1000, nextToken: $nextToken) {
        items {
          zone_state
        }
        nextToken
      }
    }
  `;

// Nueva query para zonas filtradas por estado
export const ZoneByStates =
  /* GraphQL */
  `
    query ZoneByStates($nextToken: String, $zone_state: String) {
      listZones(
        filter: { zone_state: { contains: $zone_state } }
        limit: 1000
        nextToken: $nextToken
      ) {
        items {
          id
          zone_name
          franchisse_id {
            id
            last_name
            name
            birthday
            email
          }
        }
        nextToken
      }
    }
  `;

export const GetShopsPublicIDs = `
  query GetShopsPublicIDs ($shop_idToSort: String, $nextToken: String){
    ordersByShopId(shop_idToSort: $shop_idToSort, nextToken: $nextToken) {
      items {
        customer_id {
          id
        }
      }
      nextToken
    }
  }
  `;

export const GetShopInterClb = `
query GetshopInterClb($id: ID!) {
  getShop(id: $id) {
    interclb
  }
}`;

export const GetShopsPublicSexAndAge = `
  query GetShopsPublicSexAndAge ($id: ID!) {
    getAccount(id: $id) {
      id
      sexo
      birthday
    }
  }
`;

export const OrderswithFilters = `
  query OrderswithFilters($zone: String, $status: String, $updatedAt: String, $nextToken: String) {
    listShops(filter: {zone: {contains: $zone}}, limit: 1000, nextToken: $nextToken) {
      items {
        id
        shop_name
        orders(limit: 10000, filter: {status: {contains: $status}, updatedAt: {contains: $updatedAt}}) {
          items {
            id
            updatedAt
            level
            status
            final_total
            delivery_man_id {
              id
              name
              last_name
            }
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;

export const affiliations = `
  query affiliations($zone: String, $betweenDates: [String], $nextToken: String){
    listShops(filter: {createdAt: {between: $betweenDates}, zone: {contains: $zone}}, limit: 1000, nextToken: $nextToken){
      items {
        id
        createdAt
        shop_name
        status
        seller_id {
          id
          name
          last_name
        }
      }
    }
  }
`;

// Mostrar la lista de ordenes de todos los negocios correspondientes a una zona
export const GetAllFinalizedOrdersByZone = `
  query GetAllFinalizedOrdersByZone ($zone_id: String, $betweenDates: [String]) {
    listShops(filter: {zone: {eq: $zone_id}}, limit: 5000) {
      items {
        id
        category
        orders(filter: {status: {eq: "FINALIZADO"}, updatedAt: {between: $betweenDates}}, limit: 10000) {
          items {
            id
            com_shop
            com_customer
            total
            discount
          }
        }
      }
    }
  }
`;

export const GetAllFinalizedOrdersByShop = `
  query GetAllFinalizedOrdersByShop($id: ID!, $betweenDates: [String]){
    getShop(id: $id){
      id
      shop_name
      category
      url_documents
      zone
      list_fees
      phone_number
      
      postal_code
      address_shop
      orders(filter: {status: {eq: "FINALIZADO"} level:{eq:"FINALIZADO" }, updatedAt: {between: $betweenDates}}, limit: 10000){
        items {
          id
          createdAt
          discount
          total
          final_total
          status
          method_pay
          com_shop
          com_customer
          order_type
          propina
          shop_id {
            percentage_traded {
              value
            }
          }
          delivery_man_id {
            name
            last_name
          }
          products {
            items {
              product_id {
                product_name
                price
              }
            }
          }
        }
      }
      seller_id {
        id
        rfc
        email
      }
    }
  }
`;

export const getOrdersDelivery = /* GraphQL */ `
  query getordersDelivery($id: ID!, $nextToken: String, $date: [String]) {
    getAccount(id: $id) {
      deliveryman_orders(
        filter: {
          level: { eq: "FINALIZADO" }
          status: { eq: "FINALIZADO" }
          updatedAt: { between: $date }
        }
        limit: 10
        nextToken: $nextToken
      ) {
        items {
          id
          order_case
          order_type
          method_pay
          nc
          level
          total
          final_total
          com_customer
          com_shop
          createdAt
          propina
          shipping_fees
        }
        nextToken
      }
    }
  }
`;
