import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import MicNoneIcon from "@mui/icons-material/MicNone";
import FormControl from "@mui/material/FormControl";
import { Button, CircularProgress, TextField } from "@mui/material";
import FlatButton from "../../DesignSystem/FlatButton";
import ButtonText from "../../DesignSystem/ButtonText";
import BasicHeader from "../../DesignSystem/BasicHeader";
import ModalAlert from "../../DesignSystem/ModalAlert";
import Divider from "@mui/material/Divider";
import DeliveryManCard from "../../DesignSystem/DeliveryManCard";
import AdviserCard from "../../DesignSystem/AdviserCard";
import RadioOrderGroup from "../../DesignSystem/RadioOrderGroup";
import { useStyles } from "./styles/UserList.styles";
import {
  boxContainerStyles,
  searchFormControl,
} from "./styles/UserList.styles";
import {
  getListEmployees,
  searchByUserName,
} from "../Infrastructure/User.presenter";
import { getZoneModel } from "../../Zones/Infrastructure/Zone.reducer";
/* import { getBusinessPartnerModel } from "../../BusinessPartner/Infrastructure/BusinessPartner.reducer"; */
import styled from "@emotion/styled";
import { ListorderDelivery } from "../../graphql/myQueries";
import { API, graphqlOperation } from "aws-amplify";

export default function UsersChatListStart({ role }) {
  const classes = useStyles();
  const currentZone = useSelector(getZoneModel);
  /*   const currentPartner = useSelector(getBusinessPartnerModel); */
  const [advisers, setAdvisers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [adviserR, setAdviserR] = useState(false);
  const [deliveryR, setDeliveryR] = useState(false);
  const [order, setOrder] = useState("Orden alfabético a-z");
  const [show, setShow] = useState(false);
  const [breakpoint, setBreakpoint] = React.useState(
    window.innerWidth <= 768 ? true : false
  );
  const [isOpenAlert, setOpenAlert] = useState(false);
  const [titleAlert, setTitleAlert] = useState("Ha ocurrido un error");
  const elementRef = useRef();
  const [activeCount, setActiveCount] = useState(0);
  const [inactiveeCount, setInactiveCount] = useState(0);
  const [focusActive, setFocusActive] = useState(false);
  const [showAll, setShowAll] = useState(false);

  const showAlertModal = () => setOpenAlert(true);

  const handleClickOpen = (alertState) => {
    setOpenAlert(alertState);
  };

  const getSortType = (value) => {
    setOrder(value !== undefined ? value : "Orden alfabético a-z");
    if (value === "Orden alfabético z-a") {
      setAdvisers(advisers.reverse());
      setDealers(dealers.reverse());
    } else if (value === "Registros antiguos - a +") {
      setAdvisers(advisers.sort(dateSort));
      setDealers(dealers.sort(dateSort));
    } else if (value === "Registros recientes + a -") {
      setAdvisers(advisers.sort(dateSortMaxtoMin));
      setDealers(dealers.sort(dateSortMaxtoMin));
    } else {
      setAdvisers(advisers.sort(SortArrayAZ));
      setDealers(dealers.sort(SortArrayAZ));
    }
  };

  function dateSort(a, b) {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  }
  function dateSortMaxtoMin(a, b) {
    return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
  }

  function SortArrayAZ(x, y) {
    if (x.name < y.name) {
      return -1;
    }
    if (x.name > y.name) {
      return 1;
    }
    return 0;
  }

  function covertDate(fechaString) {
    var fechaSp = fechaString.split("-");
    var anio = new Date().getFullYear();
    if (fechaSp.length == 3) {
      anio = fechaSp[2];
    }
    var mes = fechaSp[1] - 1;
    var dia = fechaSp[0];

    return new Date(anio, mes, dia);
  }

  async function getDatesOrders(limit = 1000, nextToken = null, id, arreDates) {
    try {
      const res = await API.graphql(
        graphqlOperation(ListorderDelivery, {
          id: id,
          nextToken: nextToken,
          limit: limit,
          betweenDates: arreDates,
        })
      );

      var resArray = res.data.getAccount.deliveryman_orders.items;
      nextToken = res.data.getAccount.deliveryman_orders.nextToken;

      var newOrderDates = [];
      var arre_ord = [];

      for (var i = 0; i < resArray.length; i++) {
        arre_ord.push(resArray[i]);
        var ano = resArray[i].updatedAt.substring(0, 4);
        var month = resArray[i].updatedAt.substring(5, 7);
        var day = resArray[i].updatedAt.substring(8, 10);
        var date = day + "-" + month + "-" + ano;
        newOrderDates.push({ fecha: date });
      }

      if (nextToken != null && nextToken != undefined) {
        await getDatesOrders(1000, nextToken, id, arreDates);
      } else {
        return {
          lastDate: newOrderDates.sort(function (a, b) {
            return covertDate(a.fecha) - covertDate(b.fecha);
          })[newOrderDates.length - 1].fecha,
          finishedOrders: arre_ord,
        };
      }
    } catch (exception) {
      return null;
    }
  }

  useEffect(() => {
    var sumActives = 0;
    var sumInactive = 0;
    for (var i in dealers) {
      var statusActive = dealers[i].register_approved;
      if (statusActive != null && statusActive) {
        sumActives = sumActives + 1;
      } else {
        sumInactive = sumInactive + 1;
      }
    }
    setActiveCount(sumActives);
    setInactiveCount(sumInactive);
  }, [dealers]);

  const title = () => (role === "delivery" ? "REPARTIDORES" : "ASESORES");

  const placeholderText = () =>
    role === "delivery" ? "Buscar repartidor" : "Buscar asesor";

  const handleResizeTitle = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      setBreakpoint(true);
    } else if (screenWidth >= 769) {
      setBreakpoint(false);
    }
  };

  useEffect(function () {
    const onChange = (entries, observer) => {
      const el = entries[0];
      if (el.isIntersecting) {
        setShow(true);
        observer.disconnect();
      }
    };
    const observer = new IntersectionObserver(onChange, {
      rootMargin: "100px",
    });
    observer.observe(elementRef.current);
    return () => observer.disconnect();
  });

  useEffect(() => {
    if (currentZone) {
      fetchDataAdviser(currentZone.zone_id);
      fetchDataDelivery(currentZone.zone_id);
    }
    window.addEventListener("resize", handleResizeTitle);
    window.addEventListener("load", handleResizeTitle);
    return () => {
      window.removeEventListener("resize", handleResizeTitle);
      window.addEventListener("load", handleResizeTitle);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZone]);

  async function fetchDataAdviser(zone) {
    try {
      const info = await getListEmployees({ role: "afiliador", zone });
      setAdvisers(info.sort(SortArrayAZ));
      setAdviserR(true);
    } catch (error) {
      console.log("error", error);
    }
  }

  async function fetchDataDelivery() {
    setShowAll(true);
    setFocusActive(false);
    try {
      const info = await getListEmployees({ role: "deliveryman" });
      setDealers(info.sort(SortArrayAZ));
      setDeliveryR(true);
      setShowAll(false);
    } catch (error) {
      setShowAll(false);
      console.log("error");
    }
  }

  async function getActives(array) {
    setFocusActive(true);
    var arreActives = [];

    for (var i in array) {
      if (array[i].register_approved != null && array[i].register_approved) {
        arreActives.push(array[i]);
      }
    }
    setDealers(arreActives.sort(SortArrayAZ));
  }

  async function getInactives(array) {
    setFocusActive(true);
    var arreInactives = [];

    for (var i in array) {
      if (array[i].register_approved === null || !array[i].register_approved) {
        arreInactives.push(array[i]);
      }
    }
    setDealers(arreInactives.sort(SortArrayAZ));
  }

  const handleChangeInputSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSubmitAdviserSearch = async () => {
    try {
      const info = await searchByUserName({
        role: "afiliador",
        zone: currentZone.zone_id,
        name: searchValue,
      });
      if (info.length !== 0) {
        setAdvisers(info);
      } else {
        setTitleAlert("Sin resultados");
        showAlertModal();
      }
      setSearchValue("");
    } catch (error) {
      console.log("Ocurrió un error: ", error);
      setSearchValue("");
    }
  };

  const handleSubmitDeliverySearch = async () => {
    try {
      const info = await searchByUserName({
        role: "deliveryman",
        name: searchValue,
      });

      if (info.length !== 0) {
        for (var i = 0; i < info.length; i++) {
          let initialDate = new Date();
          let temp = new Date(initialDate);
          let ant = 7 * 86399.9; //dias en segundos
          let finalDate = new Date(temp.setSeconds(-ant));

          var arreDates = [finalDate.toISOString(), initialDate.toISOString()];
          const res = await getDatesOrders(1000, null, info[i].id, arreDates);

          if (res != null) {
            var newDate =
              res.lastDate.substring(6, 10) +
              "-" +
              res.lastDate.substring(3, 5) +
              "-" +
              res.lastDate.substring(0, 2);
            info[i]["someTimeago"] = newDate + "T00:00:00.000Z";
            info[i]["countOrders"] = res.finishedOrders.length;
          } else {
            info[i]["someTimeago"] = "Sin actividad";
            info[i]["countOrders"] = 0;
          }
        }
        setDealers(info);
      } else {
        setTitleAlert("Sin resultados");
        showAlertModal();
      }
      setSearchValue("");
    } catch (error) {
      console.log("Ocurrió un error: ", error);
      setSearchValue("");
    }
  };

  const BtnModified = styled(Button)(({ theme }) => ({
    color: "#000000",
    fontSize: "15px",
    fontWeight: "bold",
  }));

  return (
    <Box sx={{ width: "100%" }}>
      <BasicHeader />
      <Box sx={boxContainerStyles}>
        {breakpoint === false ? (
          <>
            <div className={classes.headerContainer}>
              <h3 className={classes.title}>{title()}</h3>

              {showAll ? null : focusActive ? (
                <BtnModified
                  size="small"
                  disabled={dealers.length !== 0 ? false : true}
                  onClick={async () => await fetchDataDelivery()}
                  className={classes.totals}
                >
                  Ver todos
                </BtnModified>
              ) : (
                <>
                  <BtnModified
                    size="small"
                    disabled={dealers.length !== 0 ? false : true}
                    variant="secondary"
                    sx={{ color: "black" }}
                    /* color="secondary" */
                    onClick={async () => await getActives(dealers)}
                    className={classes.totals}
                  >
                    Ver validados:&nbsp;{activeCount}
                  </BtnModified>
                  <BtnModified
                    size="small"
                    disabled={dealers.length !== 0 ? false : true}
                    onClick={async () => await getInactives(dealers)}
                    className={classes.totals}
                  >
                    Ver pendientes:&nbsp;{inactiveeCount}
                  </BtnModified>
                  <BtnModified
                    disabled={dealers.length !== 0 ? false : true}
                    onClick={async () => await fetchDataDelivery()}
                    className={classes.totals}
                  >
                    Ver todos
                  </BtnModified>
                </>
              )}
            </div>
            <Divider sx={{ marginTop: "20px" }} />
            {showAll ? null : (
              <div className={classes.searchContainer}>
                <RadioOrderGroup typeOrder={order} handleValue={getSortType} />
                <FormControl sx={searchFormControl}>
                  <TextField
                    fullWidth
                    type="text"
                    variant="standard"
                    placeholder={placeholderText()}
                    onChange={handleChangeInputSearch}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        role === "delivery"
                          ? handleSubmitDeliverySearch()
                          : handleSubmitAdviserSearch();
                      }
                    }}
                    id="busqueda"
                    value={searchValue}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ color: "#333333" }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      classes: { root: classes.inputStyles },
                      sx: { fontSize: ".9rem" },
                    }}
                  />
                  <ButtonText
                    title="Buscar"
                    typeButton="button"
                    widthBtn="100px"
                    onClick={
                      role === "delivery"
                        ? handleSubmitDeliverySearch
                        : handleSubmitAdviserSearch
                    }
                  />
                </FormControl>
              </div>
            )}
          </>
        ) : (
          <>
            <div className={classes.headerContainer}>
              <div>
                <FormControl sx={searchFormControl}>
                  <TextField
                    fullWidth
                    variant="standard"
                    type="text"
                    placeholder={placeholderText()}
                    onChange={handleChangeInputSearch}
                    value={searchValue}
                    id="busqueda"
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        role === "delivery"
                          ? handleSubmitDeliverySearch()
                          : handleSubmitAdviserSearch();
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ color: "#333333" }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <MicNoneIcon sx={{ color: "#004990" }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      classes: { root: classes.inputStyles },
                      sx: { fontSize: ".9rem" },
                    }}
                  />
                </FormControl>
              </div>
              <div className={classes.buttons}>
                <FlatButton
                  title="Repartidores"
                  typeButton="button"
                  widthBtn="160px"
                  isDisabled={role === "adviser"}
                  //onClick={() => history.push("/personal/repartidores")}
                />
                <FlatButton
                  title="Asesores"
                  typeButton="button"
                  widthBtn="160px"
                  isDisabled={role === "delivery"}
                  //onClick={() => history.push("/personal/asesores")}
                />
              </div>
              DeliveryManCard
              <RadioOrderGroup
                typeOrder={"Orden alfabético a-z"}
                handleValue={getSortType}
              />
            </div>
          </>
        )}
        <div ref={elementRef} className={classes.userContainer}>
          {show ? (
            role === "delivery" && deliveryR && showAll === false ? (
              dealers.length !== 0 ? (
                <>
                  {dealers.map((delivery) => (
                    <DeliveryManCard
                      key={delivery.id}
                      id={delivery.id}
                      name={delivery.name}
                      lastName={delivery.last_name}
                      vehicle={delivery.delivery_transport}
                      image={delivery.url_photoProfile}
                      matricula={"Matricula: 00-00-000"}
                      date={"Desde: " + delivery.createdAt.substring(0, 10)}
                      active={delivery.register_approved}
                      registerDataBank={delivery.openPay_id}
                      someTiemAgo={delivery.someTimeago}
                      countOrders={delivery.countOrders}
                    />
                  ))}
                </>
              ) : (
                <p style={{ width: "-webkit-fill-available" }}>
                  Sin resultados
                </p>
              )
            ) : (
              <div
                style={{
                  width: "100%",
                }}
              >
                <CircularProgress sx={{ color: "#2566e8" }} />
              </div>
            )
          ) : (
            <CircularProgress sx={{ color: "#2566e8" }} />
          )}

          {show ? (
            role === "adviser" && adviserR ? (
              advisers.length !== 0 ? (
                <>
                  {advisers.map((adviser) => (
                    <AdviserCard
                      key={adviser.id}
                      id={adviser.id}
                      name={adviser.name}
                      lastName={adviser.last_name}
                      image={adviser.url_photoProfile}
                    />
                  ))}
                </>
              ) : (
                <p style={{ width: "-webkit-fill-available" }}>
                  Sin resultados
                </p>
              )
            ) : (
              <></>
            )
          ) : null}
        </div>
        <ModalAlert
          title={titleAlert}
          typeAlert={"error"}
          isOpen={isOpenAlert}
          handleAlert={handleClickOpen}
        />
      </Box>
    </Box>
  );
}
