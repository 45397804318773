import React, { useState, useEffect } from "react";
import moment from "moment";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import MessageOutlinedIcon from "@mui/icons-material/MessageOutlined";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import FlatIconButton from "./FlatButton";
import RatingStars from "./RatingStars";
import img from "../assets/default.png";
import {
  GetUserInfo,
  getDeliveryRating,
} from "../User/Infrastructure/User.presenter";
import CustomStaticDatePicker from "./CustomStaticDatePicker";
import editIcon from "../assets/edit-black.svg";
import {
  useStyles,
  calendarContainer,
  arrowStyles,
  btnExitStyles,
  addVehicle,
  actionBtn,
  acceptBtnStyles,
  iconBtnStyles,
  editIconStyles,
} from "./styles/ModalProfile.styles";
import { API, graphqlOperation } from "aws-amplify";
import { listDocsDelivery, ListorderDelivery } from "../graphql/myQueries";
import ControlledAccordions from "./Accordion";
import { listReferral } from "../graphql/myQueries";
import Calculatecoms from "../utils/CheckTypeDay/CalculateComs";
import { Grid } from "@mui/material";
let ComsHook = new Calculatecoms();

const formatDate = (date) => {
  return moment(date).format("DD/MM/YYYY");
};

const getBeforeWeek = (date) => {
  const formatedDate = new Date(date);
  const WeekInMs = 1000 * 60 * 60 * 24 * 7;
  const res = formatedDate.getTime() - WeekInMs;
  return new Date(res);
};

export default function ModalProfile({
  isOpen,
  name,
  lastName,
  role,
  profileImage,
  handleModal,
  id,
  vehicle,
  typeVehicle,
  countOrders,
  registerDataBank,
}) {
  const todayDate = new Date();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [stars, setStars] = useState(0);
  const [startDate, setstartDate] = useState(getBeforeWeek(todayDate));
  const [endDate, setDateEnd] = useState(todayDate);
  const [isDatePickerOpen, setDatePicker] = useState(false);
  const [collects, setCollects] = useState(countOrders);
  const [shops, setShops] = useState(0);
  const [arre_docs, setarreDocs] = useState([]);
  const [referralName, setreferralName] = useState("");
  const [total_gain, setTotalGain] = useState(0);
  const [total_debts, setTotalDebts] = useState(0);
  const [userData, setUserData] = useState({
    email: "",
    birthDate: "",
    address: "",
    phone_number: "",
    vehicle: "",
  });
  var arrayDocs = [];
  const getDeliveryStars = async () => {
    const response = await getDeliveryRating(id);
    if (isNaN(response)) {
      setStars(0);
    } else {
      setStars(response.toFixed(1));
    }
  };

  const recollectInfo = async () => {
    try {
      const documents = await API.graphql(
        graphqlOperation(listDocsDelivery, { id: id })
      );
      const docss = documents.data.getAccount.delivery_docs ?? null;
      if (docss != null) {
        for (var i in docss) {
          var insert_Info = JSON.parse(docss[i]);

          arrayDocs.push(insert_Info);
        }

        setarreDocs(arrayDocs);
      } else {
        console.log("No tiene datos esste json");
      }
    } catch (e) {
      console.log("error", e);
    }
  };

  async function getDatesOrders(limit = 1000, nextToken = null, arreDates) {
    try {
      const res = await API.graphql(
        graphqlOperation(ListorderDelivery, {
          id: id,
          nextToken: nextToken,
          limit: limit,
          betweenDates: arreDates,
        })
      );
      var resArray = res.data.getAccount.deliveryman_orders.items;
      nextToken = res.data.getAccount.deliveryman_orders.nextToken;
      var arre_ord = [];
      var totalGains = 0;
      var totalDebts = 0;
      for (var i = 0; i < resArray.length; i++) {
        arre_ord.push(resArray[i]);
        if (resArray[i].shipping_fees != null) {
          var fees = JSON.parse(resArray[i].shipping_fees);
          var costForDistance = fees.delivery + fees.distance + fees.collection;
          var gain = ComsHook.calculateCommisions(costForDistance);
          var debts = ComsHook.calculateDebts(costForDistance);
          // var objDebtsSHow = ComsHook.calculateDebtsObj(costForDistance);
          // arreDebtsData.push(objDebtsSHow);
          totalDebts += debts;
          totalGains += gain;
        }
      }

      if (nextToken != null && nextToken != undefined) {
        await getDatesOrders(limit, nextToken, arreDates);
      } else {
        setCollects(arre_ord.length);
        /*  console.log("Gnanacias -----> ", totalGains, totalDebts); */
        setTotalGain(totalGains);
        setTotalDebts(totalDebts);
      }
    } catch (exception) {
      return null;
    }
  }

  const getUserInfo = async () => {
    let formatedAddress = "";
    try {
      const response = await GetUserInfo(id);
      if (response.user_addresses.items.length !== 0) {
        const address = response.user_addresses.items[0].address_id;
        formatedAddress = `${address.street} ${address.outdoor_number}, ${address.postal_code}, ${address.city} ${address.country}`;
      }
      setUserData({
        ...userData,
        birthDate: response.birthday,
        email: response.email,
        address: formatedAddress,
        phone_number: response.phone_number,
        vehicle: response.delivery_transport,
      });
    } catch (error) {
      console.log("----->", error);
    }
  };

  useEffect(() => {
    setOpen(isOpen);
    isOpen && getUserInfo();
    if (isOpen && role === "repartidor") {
      recollectInfo();
      getDeliveryStars();
      getDatesOrders(1000, null, [
        startDate.toISOString().slice(0, -1),
        endDate.getTime() > new Date().getTime
          ? new Date().toISOString().slice(0, -1)
          : endDate.toISOString().slice(0, -1),
      ]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, role]);

  const handleClose = () => {
    setOpen(false);
    setUserData({ email: "", birthDate: "", address: "" });
    setShops(0);
    setCollects(0);
    handleModal(false);
    setDatePicker(false);
  };

  const getDateValue = (start, end) => {
    setstartDate(start);
    setDateEnd(end);
  };

  const handleAcceptDatePicker = async () => {
    setDatePicker(false);
    getDatesOrders(1000, null, [
      startDate.toISOString().slice(0, -1),
      endDate.getTime() > new Date().getTime
        ? new Date().toISOString().slice(0, -1)
        : endDate.toISOString().slice(0, -1),
    ]);
  };

  async function getReferral() {
    try {
      const refe = await API.graphql(
        graphqlOperation(listReferral, { id: id })
      );
      var referido = refe.data.getAccount.referral_id;
      if (referido != null && referido != "") {
        setreferralName(
          "Referido por: " + referido.name + " " + referido.last_name
        );
      }
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(async () => {
    await getReferral();
  }, [isOpen]);

  return (
    <Dialog open={open} maxWidth="md" scroll="body" onClose={handleClose}>
      <div className={classes.containerInfo}>
        <IconButton sx={btnExitStyles} onClick={handleClose}>
          <CloseIcon />
        </IconButton>
        <div className={classes.userMainInfo}>
          <h4 className={classes.userName}>{`${name} ${lastName}`}</h4>
          <div className={classes.principalUserInfoContainer}>
            <img
              className={classes.profileImage}
              src={profileImage === null ? img : profileImage}
              alt="foto de perfil usuario"
            />
            <div className={classes.infoDescriptionContainer}>
              {role === "repartidor" && (
                <div className={classes.rating}>
                  {stars}
                  <RatingStars rating={stars} />
                </div>
              )}

              <p className={classes.info}>
                {userData.email || "Correo no disponible"}
              </p>
              <p className={classes.info}>
                {userData.address || "Dirección no disponible"}
              </p>
              <p className={classes.info}>
                {userData.phone_number || "Número celular no disponible"}
              </p>
              <p className={classes.info}>
                {userData.vehicle || "Vehiculo no disponible"}
              </p>
              <p className={classes.info}>
                {referralName || "No ha sido referido por nadie"}
              </p>
            </div>
          </div>
          <div className={classes.contactInfoContainer}>
            <IconButton sx={iconBtnStyles}>
              <MessageOutlinedIcon />
            </IconButton>
            <IconButton sx={iconBtnStyles}>
              <LocalPhoneOutlinedIcon />
            </IconButton>
            {role === "repartidor" && (
              <div className={classes.btnContainer}>
                {vehicle ? (
                  <p className={classes.idVehicle}>{vehicle}</p>
                ) : (
                  <FlatIconButton
                    widthBtn="170px"
                    typeButton="button"
                    title="Agregar vehículo"
                    customStyles={addVehicle}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className={classes.roleNameContainer}>
          <p className={classes.roleName}># Repartos</p>
          <div className={classes.rangeDatesContainer}>
            <p className={classes.rangeDates}>
              {formatDate(startDate)} - {formatDate(endDate)}
            </p>
            <KeyboardArrowDownRoundedIcon
              sx={arrowStyles}
              onClick={() => setDatePicker(true)}
            />
          </div>
        </div>
        <Modal open={isDatePickerOpen} onClose={() => setDatePicker(false)}>
          <Box sx={calendarContainer}>
            <CustomStaticDatePicker
              dateValue={startDate}
              handleDateChange={getDateValue}
            />
            <FlatIconButton
              title="Aceptar"
              widthBtn="80px"
              customStyles={acceptBtnStyles}
              onClick={handleAcceptDatePicker}
            />
          </Box>
        </Modal>
        <Grid container rowSpacing={2} direction="row">
          <div className={classes.amountContainer}>
            <p className={classes.amountNum}>{collects}</p>
          </div>
          <div className={classes.amountGains}>
            <p>
              Ganancias del repartidor: {"\n"}${total_gain.toFixed(2)}
            </p>
            <p>
              Deudas del repartidor: {"\n"}${total_debts.toFixed(2)}
            </p>
          </div>

          {/* <p
            className={classes.amountNum}
            style={{ color: "green", fontSize: 20 }}
          >
            ${total_gain.toFixed(2)}
          </p>

          <p
            className={classes.amountNum}
            style={{ color: "red", fontSize: 20 }}
          >
            ${total_debts.toFixed(2)}
          </p> */}
        </Grid>

        <div className={classes.actionButtonsContainer}>
          <FlatIconButton
            isDisable
            width="100px"
            title="Eliminar"
            icon={<DeleteOutlineOutlinedIcon />}
            customStyles={actionBtn}
            //onClick={handleDelete}
          />
          <FlatIconButton
            isDisable
            width="100px"
            title="Editar"
            icon={
              <img
                className={editIconStyles}
                src={editIcon}
                alt="icono editar"
              />
            }
            customStyles={actionBtn}
            //onClick={handleDelete}
          />
        </div>

        <ControlledAccordions
          data={arre_docs}
          user={id}
          vehicle={typeVehicle}
          styles={classes}
          profileIMG={profileImage}
          img={img}
          stars={stars}
          email={userData.email}
          address={userData.address}
          phone_number={userData.phone_number}
          vehiculo={userData.vehicle}
          referralName={referralName}
          registerDataBank={registerDataBank}
        />
      </div>
    </Dialog>
  );
}
