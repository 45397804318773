import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import FileDownloadRoundedIcon from '@mui/icons-material/FileDownloadRounded';

const exportBtnContainer = {
  //border: "1px solid red",
  alignSelf: "end",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  position: "absolute",
  top:"-92px",
  right: 35,
}

const exportTextStyles = {
  //fontFamily: Work Sans,
  fontWeight: 700,
  fontSize: "1.25rem",
  lineHeight: "23px",
  textAlign: "center",
  letterSpacing: "-0.02em",
  color: "#EA5B13",
}

const exportBtnStyles = {
  width: "40px",
  height: "40px",
  background: "#EA5B13",
  color: "#FFFFFF",
  "&:hover":{
    background: "#F76014"
  }
}

export default function ExportButton({onClick}){
  return(
    <Box sx={exportBtnContainer}>
      <p style={exportTextStyles}>Exportar</p>
      <IconButton sx={exportBtnStyles} onClick={onClick}>
        <FileDownloadRoundedIcon/>
      </IconButton>
    </Box>
  );
}