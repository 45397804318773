import { getOne, consumeS3Image, getListAccounts, searchBar, getRating, getCollectsBetweenDates, getShopsBetweenDates } from "../../Provider";

export async function GetUserInfoService(id) {

  return getOne(id);
}

export async function getPhotoProfileService(url){
  return consumeS3Image(url);
}

export async function getListEmployeesService(info){
  return getListAccounts(info);  
}

export async function searchByUserNameService(info){
  return searchBar(info);
}

export async function getDeliveryRatingService(id){
  return getRating(id);
}

export function getCollectsByDeliveryService(data){
  return getCollectsBetweenDates(data)
} 

export async function getShopsByAdviserService(data){
  return getShopsBetweenDates(data);
}