import Avatar from "@mui/material/Avatar";
import StoreIcon from '@mui/icons-material/Store';
import {businessImageStyles, iconStyles} from "./styles/BusinessImage.styles";

export default function BusinessImage({businessImage="", customStyles}){
  return (
    <Avatar src={businessImage} sx={customStyles || businessImageStyles}>
      <StoreIcon sx={iconStyles}/>
    </Avatar>
  );
}