import { Auth } from "aws-amplify";
import {signIn} from "../../Provider";

export async function LoginService(loginModel) {
  return signIn(loginModel.email, loginModel.password);
  // return Auth.signIn(loginModel.email, loginModel.password);
}

export async function LogoutService() {
  return Auth.signOut();
}