import { useState, useEffect } from "react";
import moment from "moment";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

const columnStyle = {
  color: "#828282",
  align: "left",
  fontWeight: 500,
  minWidth: 170,
  maxWidth: 170,
  minHeight: 73,
  fontSize: "1.125rem",
  fontFamily: "Montserrat",
};

const textStyle = {
  fontWeight: 500,
  fontSize: "1.125rem",
  fontFamily: "Montserrat",
};

const statusText = {
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
  fontWeight: 500,
  fontSize: "1.125rem",
  fontFamily: "Montserrat",
};

const captionStyle = {
  background: "#004990",
  color: "#FFF",
  borderRadius: "10px",
  fontFamily: "Montserrat",
};

const columns = [
  { id: "status", label: "Estatus" },
  { id: "business", label: "Negocio" },
  { id: "order", label: "Orden"},
  { id: "deliveryman", label: "Repartidor" },
  { id: "hour", label: "Hora", format: (value) => value.toLocaleString("en-US") },
  { id: "total", label: "Total", format: (value) => value.toFixed(2) },
];

export default function BusinessOrdersRealTime({ data }) {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const completeData = [];
    let complement = {};
    data.map((element) => {
      element.orders.items.map((item) => {
        complement = {
          shop_id: element.id, 
          shop_name: element.shop_name, 
          order_id: item.id,
          status: item.status,
          delivery: item.delivery_man_id !== null ? item.delivery_man_id.name : "Sin registro",
          deliveryLast: item.delivery_man_id !== null ? item.delivery_man_id.last_name : "",
          hour: item.updatedAt,
          total: item.final_total
        };
        completeData.push(complement);
      });
    });
    setOrders(completeData);
  }, [data]);

  function selectColor(stateOrder){
    let color;
    if(stateOrder === "ACEPTADA"){
      color = "#219653";
    }else if(stateOrder === "RECHAZADA"){
      color = "#F2994A";
    }else if(stateOrder === "CANCELADA"){
      color = "#EB5757";
    }else if(stateOrder === "FINALIZADO"){
      color = "#BDBDBD"; 
    }
    return color;
  }

  return (
    <Paper sx={{ width: "100%", overflow: "hidden" }}>
      <TableContainer sx={{ maxHeight: 800 }}>
        <Table
          stickyHeader
          aria-label="sticky table"
          sx={{ fontFamily: "Montserrat" }}
        >
          <TableHead sx={{ boxShadow: "0px 4px 4px rgba(219, 219, 219, 0.25)" }} >
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.id} align={"center"} sx={columnStyle}>
                  {column.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>      
          <TableBody>
          {orders && orders.map((row) => {
              return (
                <TableRow hover role="checkbox" tabIndex={-1} key={row.order_id}>
                  <TableCell key={"status"} align={"center"} sx={statusText}>
                    <span style={{
                      borderRadius: "50%",
                      width: "8px",
                      height: "8px",
                      background: selectColor(row.status)
                    }} />{row.status}
                  </TableCell>

                  <TableCell key={"business"} align={"center"} sx={textStyle}>
                    {row.shop_name}
                  </TableCell>

                  <TableCell key={"order"} align={"center"} sx={textStyle}>  
                    #{row.order_id.slice(0, -30)}
                  </TableCell>

                  <TableCell key={"deliveryman"} align={"center"} sx={textStyle}>
                    {row.delivery} {row.deliveryLast}
                  </TableCell>

                  <TableCell key={"hour"} align={"center"} sx={textStyle}>
                    {moment(row.hour).format("hh:mm a")}
                  </TableCell>

                  <TableCell key={"total"} align={"center"} sx={textStyle}>
                    ${row.total}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
}
