import { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import {
  useStyles,
  infoContainer,
  headerInfo,
} from "./styles/BusinessDetailsCard.styles";
import { UpdateShopVisibility } from "../../graphql/myMutations";
import { GetRStatusShop } from "../../graphql/myQueries";
import Switch from "@mui/material/Switch";
import { API, graphqlOperation } from "aws-amplify";
import { makeStyles } from "@mui/styles";

function BusinessSwitch({ idshop }) {
  const classes = useStyles();
  const [check, setStatus] = useState("");
  const switchStyles = {
    width: 71,
    height: 26,
    padding: 0,
  };
  const useSwitchStyle = makeStyles((theme) => ({
    root: {
      "& .MuiSwitch-switchBase": {
        padding: 0,
        margin: 2,
        transitionDuration: "300ms",
        "&.Mui-checked": {
          transform: "translateX(45px)",
          color: "#fff",
          "& + .MuiSwitch-track": {
            backgroundColor:
              theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
            opacity: 1,
            border: 0,
          },
          "&.Mui-disabled + .MuiSwitch-track": {
            opacity: 0.5,
          },
        },
        "&.Mui-focusVisible .MuiSwitch-thumb": {
          color: "#33cf4d",
          border: "6px solid #fff",
        },
        "&.Mui-disabled .MuiSwitch-thumb": {
          color:
            theme.palette.mode === "light"
              ? theme.palette.grey[100]
              : theme.palette.grey[600],
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
        },
      },
      "& .MuiSwitch-thumb": {
        boxSizing: "border-box",
        width: 22,
        height: 22,
      },
      "& .MuiSwitch-track": {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
        opacity: 1,
        transition: theme.transitions.create(["background-color"], {
          duration: 500,
        }),
      },
    },
  }));
  const classSwicth = useSwitchStyle();
  useEffect(() => {
/*     console.log("idshop", idshop); */
    const getStatusShop = async () => {
      try {
        const res = await API.graphql(
          graphqlOperation(GetRStatusShop, {
            id: idshop,
          })
        );
        var availability = res.data.getShop.status;
        setStatus(availability ? "Completo" : "Incompleto");
      } catch (error) {
        // console.log("Este es el error", error);
      }
    };
    getStatusShop();
  }, [idshop]);

  useEffect(() => {}, [check]);

  async function updateShopStatus(stat) {
    try {
      const res = await API.graphql(
        graphqlOperation(UpdateShopVisibility, {
          id: idshop,
          visibility: stat,
        })
      );
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleChange = async (event) => {
    const visibility = event.target.checked;
    await updateShopStatus(visibility);
  };

  return (
    <Box
      sx={[
        infoContainer,
        {
          backgroundColor: "#f4f4f4",
          borderRadius: 2,
          height: 150,
          justifyContent: "center",
        },
      ]}
    >
      <Box sx={headerInfo}>
        <InfoOutlinedIcon
          sx={{ color: "#EA5B13", width: "24px", height: "24px" }}
        />
        <h4 className={classes.titleInfo}>Vizualizar tienda</h4>
      </Box>
      <Box sx={headerInfo}>
        <p className={classes.key}>Habilitar</p>
        &nbsp;
        {check === "Completo" ? (
          <>
            <h1></h1>
            <Switch
              defaultChecked={true}
              onChange={handleChange}
              sx={switchStyles}
              className={classSwicth.root}
              focusVisibleClassName=".Mui-focusVisible"
              disableRipple
            />
          </>
        ) : (
          <Switch
            defaultChecked={false}
            onChange={handleChange}
            sx={switchStyles}
            className={classSwicth.root}
            focusVisibleClassName=".Mui-focusVisible"
            disableRipple
          />
        )}
      </Box>
    </Box>
  );
}
export default BusinessSwitch;
