import { useHistory } from "react-router";
import Avatar from '@mui/material/Avatar';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from "@mui/material/List";
import ListItemDrawer from "./ListItemDrawer";
import businessIcon from "../assets/business.svg";
import chatIcon from "../assets/chat.svg";
import logoutIcon from "../assets/logout.svg";
import logo from "../assets/logoNiku.svg";
import { useStyles, avatarStyles } from "./styles/Navbar.styles";

import { Logout } from "../Auth/Infrastructure/Auth.presenter";

export default function Navbar() {
  const classes = useStyles();
  const history = useHistory();

  const logout = async () => {
    try {
      await Logout();
      history.push("/");
    } catch (error) {
      alert("error signing out: ", error);
    }
  };

  return (
    <AppBar className={classes.root} sx={{boxShadow: "none"}} position="static">
        <Toolbar sx={{width: "100%",maxWidth: "1148px", margin: "auto",display: "flex", justifyContent: "space-between", alignItems: "center"}}>
        <img className={classes.logoStyles} src={logo} alt="logo Niku" onClick={() => history.push("/estados")}/>
      <List className={classes.headerList}>
        <ListItemDrawer goTo={() => {history.push("/estados")}}>
          <img className={classes.navIcon} src={businessIcon} alt="icono negocios"/>
        </ListItemDrawer>
        <ListItemDrawer goTo={() => {history.push("/chat")}}>
          <img className={classes.navIcon} src={chatIcon} alt="icono chats"/>
        </ListItemDrawer>
        <ListItemDrawer goTo={logout}>
          <img className={classes.navIcon} src={logoutIcon} alt="icono cerrar sesión"/>
        </ListItemDrawer>
        <ListItemDrawer goTo={() => {history.push("/perfil")}}>
          <Avatar sx={avatarStyles} alt="foto de perfil usuario"/>
        </ListItemDrawer>
      </List>
        </Toolbar>
    </AppBar>);
}