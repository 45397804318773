import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import offerIcon from "../assets/offer-icon.svg"
import { GetPhotoProfile } from "../User/Infrastructure/User.presenter";

const useStyles = makeStyles((theme) => ({
  offerCard:{
    display: "flex",
    alignItems: "center",
    flexDirection:" column",
    gap: 5,
    width: "190px",
    height: "217px",
    marginRight: "20px",
    background: "#FFFFFF",
    border: "1px solid #F2F2F2",
    borderRadius: "0px 0px 5px 5px",
    [theme.breakpoints.down("md")]:{
      boxShadow: "0px 4px 4px rgba(179, 179, 179, 0.25)",
      borderRadius: "0px 0px 5px 5px",
      minHeight: 250,
    }
  },
  offerDiscountContainer:{
    padding: 5,
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 8,
    background: "#004990",
    borderRadius: "5px 5px 0px 0px",
  },
  offerIconStyles:{
    width: "22.53px",
    height: "12.38px"
  },
  discount:{
    color: "#FFFFFF",
    fontSize: "1rem",
    fontWeight: 500,
    lineHeight: "20px",
    letterSpacing: "0em",
    textAlign: "left",
  },
  productImageStyles:{
    maxWidth: 143,
    maxHeight: 79,
    margin:"3px auto",
  },
  productName:{
    fontSize: "0.875rem",
    color: "#333333",
    lineHeight: "17.07px",
    fontWeight: 500,
    alignSelf: "start",
    paddingLeft: 8,
  },
  amount:{
    alignSelf: "start",
    paddingLeft: 8,
    color: "#828282",
    fontSize: "0.875rem",
    lineHeight: "17.07px",
    fontWeight: 400,
  },
  priceContainer:{
    paddingLeft: 8,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 10,
  },
  discountPrice:{
    fontSize: "0.875rem", //cambiar por clase más general
    lineHeight: "17.07px",
    fontWeight: 400,
    color: "#333333",
  },
  realPrice:{
    color: "#BDBDBD",
    fontSize: "0.8125rem",
    lineHeight: "15.85px",
    textDecoration: "line-through"
  },
  offersContainer:{
    marginTop: "30px",
    background: "#FFFFFF",
    padding: "18px 45px 23px",
    maxWidth: 709,
    maxHeight: 312,
  },
  titleInfo:{
    lineHeight: "29.05px",
    fontWeight: 600,
    color: "#333333",
    fontSize: "1.5rem"
  },
}));

const getDiscountPercentage = (realPrice, offerPrice) => {
  const res =  parseInt(((offerPrice * 100) / realPrice));
  return 100 - res;
}

export default function BusinessOfferCard({id, offerPrice, realPrice, name, image, amount}){
  const classes = useStyles();
  const [photo, setPhoto] = useState({});

  useEffect(() => {
    if (image) {
      getUrlPhoto(image)
    }
  }, [image]);

  const getUrlPhoto = async (urlPhoto) =>{
    const res = await GetPhotoProfile(urlPhoto);
    setPhoto(res);
  }

  return(
    <div className={classes.offerCard}>
      <div className={classes.offerDiscountContainer}>
        <img className={classes.offerIconStyles} src={offerIcon} alt="icono cupon descuento"/>
        <p className={classes.discount}>-{getDiscountPercentage(realPrice, offerPrice)}%</p>
      </div>
      <img className={classes.productImageStyles} src={image === null ? null : photo} alt="producto"/>
      <p className={classes.productName}>{name}</p>
      <p className={classes.amount}>{amount}</p>
      <div className={classes.priceContainer}>
        <p className={classes.discountPrice}>Precio: ${offerPrice.toFixed(2)}</p>
        <p className={classes.realPrice}>${realPrice.toFixed(2)}</p>
      </div>
    </div>
  );
}