import { Component } from "react";
import PropTypes from "prop-types";

const options = {
  weekday: "long",
};

var ordertype = "";
class StatusAccount extends Component {
  formatDate = (d) => {
    var date = new Date(d);
    var dd = date.getDate();
    var mm = date.getMonth() + 1;
    var yyyy = date.getFullYear();
    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }
    return (d = yyyy + "-" + mm + "-" + dd);
  };

  // C+alcula la comisión de opp por transacción
  calculatetarife = (amount) => {
    var numerador = 25000;
    var denominador = 24159;
    var secondNum = 72500;
    var result = amount * (numerador / denominador) + secondNum / denominador;
    return result - amount;
  };

  // Calcula el IVA de la comision Opp
  calculatetarifserviceIva(amount) {
    var numerador = 25000;
    var denominador = 24159;
    var secondNum = 72500;
    var result = amount * (numerador / denominador) + secondNum / denominador;
    return (result * 0.029 + 2.5) * 0.16;
  }

  // Función para calcular el estado de cuenta por tienda
  calculateAccountStatus = (
    arrayX,
    idshop,
    cat,
    documents,
    zona,
    porcentage,
    rfc
  ) => {
    var comDelivery = 0;
    var faceToface = 0;

    // Conversión de datos string a number para obtener los porcentajes cedidos por las tiendas
    if (porcentage != null) {
      var res = JSON.parse(porcentage ?? "");
      comDelivery = res.delivery.substring(0, res.delivery.length - 1).trim();
      faceToface = res.faceToface
        .substring(0, res.faceToface.length - 1)
        .trim();

      if (comDelivery.length === 2) {
        comDelivery = parseFloat("0." + comDelivery);
      } else {
        comDelivery = parseFloat("0.0" + comDelivery);
      }

      if (faceToface.length === 2) {
        faceToface = parseFloat("0." + faceToface);
      } else {
        faceToface = parseFloat("0.0" + faceToface);
      }
    }

    // Evaluación de zona por orden
    var zonee = "";
    if (zona != null) {
      zonee = zona;
    } else {
      zonee = "No tiene zona asignada";
    }

    // Información de documentos fiscales
    var documenteslegals = JSON.parse(
      documents === undefined ? "[]" : documents
    );
    var totalshop = 0;
    var subtractionmcc = 0;
    // Arreglo que devuelve la función
    var arraytoReturn = [];
    // Cálculo de monedero
    var mcc25 = 0.25;
    var mcc = 0;
    var i = 0;
    // Objeto de devolución
    var newObj = {};
    // Retenciones
    var iva8 = 0;
    var iva1 = 0;
    // Comisión de niku
    var comisión = 0;

    // Recorrido de los datos por orden
    for (var i in arrayX) {
      var comnxn = 2.5;
      var tasaInt = 0.029;
      var tasaOpps = (arrayX[i].final_total * tasaInt).toFixed(2);
      var ivaOpps = ((arrayX[i].final_total * tasaInt + comnxn) * 0.16).toFixed(
        2
      );
      var moneyOp =
        arrayX[i].final_total -
        (parseFloat(tasaOpps) + comnxn + parseFloat(ivaOpps));
      var commissionOpp = parseFloat(tasaOpps) + comnxn + parseFloat(ivaOpps);
      // Asignación de tipo de orden
      ordertype = arrayX[i].order_type;
      // Evaluación de porcentaje cedido por la tienda
      var percentageceded =
        arrayX[i].shop_id.percentage_traded.value !== null
          ? arrayX[i].shop_id.percentage_traded.value
          : 0;
      // Este es el objeto que devuelve esta función y se le asigna al arreglo de devolución
      newObj = {
        creacion: this.formatDate(arrayX[i].createdAt),
        id: arrayX[i].id,
        Fecha: "",
        Hora: "",
        Formadepago: "",
        importsindiscount: 0,
        Importventa: 0,
        importpluscomcustomer: 0,
        tarifeservice: 0,
        Descuentocl: 0,
        MCC: 0,
        Subtotal: 0,
        ComisionNk: 0,
        Subsiniva: 0,
        IVA8: 0,
        ISR1: 0,
        Comsend: 0,
        TotalADepositarporOrden: 0,
        Domicializacion: 0,
        TipoOrden: arrayX[i].order_type,
        ComsendCustomer: arrayX[i].com_customer ?? 0,
        propine: arrayX[i].propina ?? 0,
        deliveryman: "",
        idzona: zonee,
        finalTotalbd: arrayX[i].final_total,
        arreproducts: "",
        quantity: 0,
        subtotalpro: 0,
        tasaOpp: tasaOpps,
        comNXN: comnxn,
        Ivaopp: ivaOpps,
        com_opp: commissionOpp,
        moneyInopp: moneyOp.toFixed(2),
        less_send_opp: (moneyOp - arrayX[i].com_customer).toFixed(2),
        less_more_discount: (
          moneyOp -
          arrayX[i].com_customer +
          arrayX[i].discount
        ).toFixed(2),
        addition_retentions: 0,
        addition_comnkdiscount: 0,
      };
      // Condición para mandar nombre de repartidor
      if (arrayX[i].delivery_man_id !== null) {
        newObj.deliveryman =
          arrayX[i].delivery_man_id.name +
          " " +
          arrayX[i].delivery_man_id.last_name;
      } else {
        newObj.deliveryman = "No hay repartidor en esta orden";
      }
      // Resultado de comisión de envio
      var sendshop = arrayX[i].com_shop !== null ? arrayX[i].com_shop : 0;
      // Resultado de método de pago
      var method_pay = arrayX[i].method_pay.toUpperCase();
      // Resultado del total
      var total = parseFloat(arrayX[i].total);
      // Resultado de descuento
      var discount =
        method_pay === "EFECTIVO"
          ? Math.ceil(parseFloat(arrayX[i].discount))
          : parseFloat(arrayX[i].discount);
      // Modificacion de descuento ------> valor del cedido;
      mcc = parseFloat(
        (((arrayX[i].total * percentageceded) / 100) * mcc25).toFixed(2)
      );
      // Resultado de la fecha por orden final
      newObj.addition_comnkdiscount = discount + mcc;
      const event = new Date(arrayX[i].createdAt);
      // subtotal total-descuento
      var submonto = parseFloat((total - discount).toFixed(2));
      // Submonto - monedero
      var subtotalmcc = parseFloat((submonto - mcc).toFixed(2));
      // Importe de venta mas comisión de servicio
      var importpluscomservicecustom =
        submonto +
        (arrayX[i].com_customer !== null ? arrayX[i].com_customer : 0);
      // Evaluación de comisión cedida por el comercio por comercio y categoria desde base de datos
      if (cat === "Gas") {
        comisión = parseFloat((subtotalmcc * 0.01).toFixed(2));
      } else if (idshop?.substring(0, 3) === "2am") {
        comisión = subtotalmcc * 0.04 * 1.16;
      } else {
        comisión = parseFloat(
          (
            subtotalmcc *
            (ordertype === "PRESENCIAL" ? faceToface : comDelivery)
          ).toFixed(2)
        );
      }
      // Asignacion de sub total sin IVA
      subtractionmcc = subtotalmcc.toFixed(2);
      // Función que cálcula las retenciones
      function calculateReten(IVA, comIva, isr) {
        subtractionmcc = (subtotalmcc / IVA).toFixed(2);
        iva8 = parseFloat((subtractionmcc * comIva).toFixed(2));
        iva1 = parseFloat((subtractionmcc * isr).toFixed(2));
        newObj.addition_retentions = parseFloat((iva8 + iva1).toFixed(2));
      }
      // Inicio de reconomiento de datos fiscales
      if (documenteslegals !== null) {
        // Entra a esta condición si el campo ya existe
        if (
          documenteslegals.contrato_fisica !== "" &&
          documenteslegals.contrato_fisica !== undefined
        ) {
          // Condición de persona FISICA
          // Cálculo de retenciones sin importar método de pago IVA 8% E ISR 1%
          // Persona fisica sin RFC aumentan sus retenciones 20% de ISR Y 16% DE IVA+
          // console.log('Este es el resultado de res', rfc, idshop);
          if (rfc != null && rfc != undefined && rfc != "") {
            calculateReten(1.16, 0.08, 0.021);
          } else {
            calculateReten(1.16, 0.16, 0.2);
          }
        } else if (
          documenteslegals.contrato_moral !== "" &&
          documenteslegals.contrato_moral !== undefined
        ) {
          // Condición de persona MORAL
          // Cálculo de retenciones sin importar método de pago IVA 8% E ISR 1%
          calculateReten(1.16, 0.08, 0.021);
        } else {
          // LLega el JSON pero con datos vacios
          // El calculo se realiza tomandolas en cuenta como personas FISICAS por defecto
          calculateReten(1.16, 0.08, 0.021);
        }
      } else {
        // No existe el json en la bd
        // El calculo se realiza tomandolas en cuenta como personas FISICAS por defecto
        calculateReten(1.16, 0.08, 0.021);
      }

      // LA TIENDA LLEVA COMISIÓN DE ENVIO
      if (sendshop > 0) {
        // Si la tienda lleva comisión de envío
        // Subtotal + mcc- comision de niku - iva8 - isr - envio;
        totalshop = subtotalmcc - (sendshop + comisión + iva8 + iva1);
      } else {
        totalshop = subtotalmcc - (comisión + iva8 + iva1 + mcc);
      }

      // ESTA CONDICION APLICA PARA SERVICIO, PRESENCIAL Y ONLINE PERO EN EFECTIVO
      if (method_pay.toUpperCase() === "EFECTIVO") {
        totalshop = mcc + comisión + sendshop;
        newObj.Domicializacion = parseFloat(totalshop.toFixed(2));
      } else {
        newObj.Domicializacion = 0;
      }
      // Asignacion de valores aL objeto
      newObj.id = arrayX[i].id;
      newObj.Fecha = event.toLocaleDateString(undefined, options);
      newObj.Hora = event.getHours() + ":" + event.getMinutes();
      newObj.Formadepago = method_pay !== undefined ? method_pay : "";
      newObj.Importventa = total;
      newObj.importsindiscount = submonto;
      newObj.Descuentocl = discount;
      newObj.MCC = mcc;
      newObj.Subtotal = subtotalmcc;
      newObj.ComisionNk = comisión;
      newObj.Subsiniva = parseFloat(subtractionmcc);
      newObj.IVA8 = iva8;
      newObj.ISR1 = iva1;
      newObj.Comsend = sendshop > 0 ? sendshop : 0;
      newObj.TotalADepositarporOrden =
        newObj.Domicializacion > 0 ? 0 : parseFloat(totalshop.toFixed(2));
      newObj.importpluscomcustomer = parseFloat(
        importpluscomservicecustom.toFixed(2)
      );
      newObj.tarifeservice = parseFloat(
        this.calculatetarife(newObj.importpluscomcustomer).toFixed(2)
      );
      arraytoReturn.push(newObj);
    }
    // Devolución del arreglo con los objetos creados
    return arraytoReturn;
  };
}
StatusAccount.propTypes = {
  children: PropTypes.func.isRequired,
};

export default StatusAccount;
