import { makeStyles } from "@mui/styles";

export const scheduleContainer = {
  width: "360px",
  height: "auto",
  background: "#F2F2F2",
  borderRadius: "10px",
  padding: "15px 30px",
};

export const scheduleStyles = {
  color: "#4f4f4f",
  height: "15px",
  width: "15px",
};

export const stats = {
  width: "100%",
  minHeight: "34px",
  display: "flex",
  justifyContent: { xs: "start", md: "space-around" },
  background: { xs: "#FFF", md: "#F2F2F2" },
  borderRadius: "10px",
  alignSelf: "start",
  alignItems: "center",
  gap: { xs: "15px", md: "0px" },
};

export const infoContainer = {
  paddingLeft: "10px",
  display: { xs: "none", md: "flex" },
  alignItems: "start",
  flexDirection: "column",
  gap: "15px",
  width: "100%",
};

export const infoContainerMobile = {
  //alignSelf:"start",
  padding: "10px 20px 15px",
  display: "flex",
  alignItems: "start",
  flexDirection: "column",
  gap: "15px",
  width: "100%",
};

export const headerInfo = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
};
export const headerSwitch = {
  display: "flex",
  justifyContent: "flex-start",
  alignItems: "center",
  flexDirection: "row",
};

export const priceStyles = {
  color: "#4f4f4f",
  height: { xs: "12px", lg: "14px" },
  width: { xs: "12px", lg: "14px" },
};

export const businessImageStyles = {
  border: "1px solid #F2F2F2",
  boxSizing: "border-box",
  borderRadius: "10px",
  objectFit: "cover",
  width: { xs: "100%", md: "90%" },
  minHeight: { xs: "100px", md: "110px" },
  height: "auto",
  maxHeight: { xs: "99px", md: "261px" },
  position: { xs: "relative", md: "static" },
};

const useStyles = makeStyles((theme) => ({
  principalContainer: {
    marginTop: 20,
    background: "#FFFFFF",
    borderRadius: 10,
    maxWidth: 435,
    minHeight: "100vh",
    padding: "20px 25px 15px",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column",
    gap: 35,
    [theme.breakpoints.down("md")]: {
      gap: 18,
      maxWidth: "100%",
      width: "100%",
      padding: "50px 55px 55px",
      marginTop: 0,
    },
  },
  businessPrice: {
    display: "flex",
    justifyContent: "center",
  },
  centerAlign: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 3,
  },
  businessHours: {
    fontWeight: 400,
    fontSize: "0.8125rem",
    color: "#4F4F4F",
    lineHeight: "15.85px",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.6875rem",
    },
  },
  businessName: {
    paddingLeft: 10,
    maxWidth: 290,
    color: "#333333",
    alignSelf: "start",
    fontWeight: 700,
    fontSize: "3rem",
    lineHeight: "58.09px",
    [theme.breakpoints.down("md")]: {
      order: -5,
      fontSize: "1.875rem",
      fontWeight: 500,
    },
  },
  businessDescription: {
    marginTop: 12,
    paddingLeft: 10,
    maxWidth: 325,
    color: "#4f4f4f",
    fontSize: "1.125rem",
    fontWeight: 400,
    lineHeight: "21.94px",
    textAlign: "left",
  },
  titleInfo: {
    lineHeight: "29.05px",
    fontWeight: 600,
    color: "#333333",
    fontSize: "1.5rem",
  },
  text: {
    color: "#4F4F4F",
    fontSize: "1.125rem",
    lineHeight: "22px",
    [theme.breakpoints.down("md")]: {
      fontSize: "0.875rem",
    },
  },
  key: {
    fontWeight: 500,
  },
  value: {
    fontWeight: 400,
  },
  menuButtons: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 15,
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  headerHelp: {
    marginBottom: 15,
    width: "100%",
    order: -6,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
}));

export { useStyles };
