import { makeStyles } from "@mui/styles";

export const commentsContainer = {
  background: "white",
  borderRadius: "10px",
  maxWidth: "709px",
  minHeight: "265px",
  width: "100%",
  padding: { xs: "60px 5px", sm: "40px 20px", md: "18px 40px 18px" },
  marginTop: { md: "20px", lg: "20px" },
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
};

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
  },
  commentsHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  iconBag: { marginRight: "20px" },
  subtitle: {
    color: "#333333",
    fontWeight: 400,
    fontSize: "1.5rem",
    marginBottom: "10px",
  },
}));

export {useStyles};