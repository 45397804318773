import React, { useState, useEffect } from "react";
import moment from "moment";
import { useParams } from "react-router-dom";
import { Box } from "@mui/system";
import { useStyles } from "./styles/ZonesList.styles";
import { textContainer } from "../../User/UI/styles/UserList.styles";
import { generalContainer, centarlContainer } from "./styles/ZonesList.styles";

import {
  GetListZones,
  GetProfitByZone,
} from "../Infrastructure/Zone.presenter";

import BasicHeader from "../../DesignSystem/BasicHeader";
import ButtonZone from "../../DesignSystem/ButtonZone";
import RangePicker from "../../DesignSystem/RangePicker";
import RadioTypeDateGroup from "../../DesignSystem/RadioTypeDateGroup";

import { sub } from "date-fns";
import { Chart, ArcElement } from "chart.js";
import "chart.js/auto";
import { Doughnut } from "react-chartjs-2";
Chart.register(ArcElement);

let ganancias = [];

export default function ZonesListEditor() {
  const classes = useStyles();
  const { estado } = useParams();
  const [firstDate, setFirstDate] = useState(sub(new Date(), { years: 1 }));
  const [secondDate, setSecondDate] = useState(new Date());
  const [typeDate, setTypeDate] = useState("Anual");
  const [labels, setLabels] = useState([]);
  const [colors, setColors] = useState([]);
  const [completeData, setCompleteData] = useState([]);

  const [isTooltipActive, setTooltip] = useState(false);
  const [color, setColor] = useState("#FFFFFF");
  const [theLabel, setTheLabel] = useState();
  const [total, setTotal] = useState();
  const [thePercentage, setThePercentage] = useState();
  const [gainTotal, setGainTotal] = useState(0);

  const pastDayMonth = sub(new Date(), { weeks: 4 });
  const pastDay = sub(new Date(), { days: 1 });
  const pastWekend = sub(new Date(), { weeks: 1 });
  const pastYear = sub(new Date(), { years: 1 });

  const getBetweenDates = (value) => {
    setFirstDate(moment(value[0]).utc().format("YYYY-MM-DD"));
    setSecondDate(moment(value[1]).utc().format("YYYY-MM-DD"));
  };

  const getSortType = (value) => {
    setTypeDate(value);
  };

  function generarCaracter() {
    var caracter = [
      "a",
      "b",
      "c",
      "d",
      "e",
      "f",
      "0",
      "1",
      "2",
      "3",
      "4",
      "5",
      "6",
      "7",
      "8",
      "9",
    ];
    var numero = (Math.random() * 15).toFixed(0);
    return caracter[numero];
  }

  function colorHEX() {
    var color = "";
    for (let i = 0; i < 6; i++) {
      color = color + generarCaracter();
    }
    return "#" + color;
  }

  function generarColores(total) {
    var colores = [];
    for (let i = 0; i < total; i++) {
      colores.push(colorHEX());
    }
    setColors(colores);
    return colores;
  }

  const dataB = {
    labels: labels,
    datasets: [
      {
        data: ganancias,
        backgroundColor: colors,
      },
    ],
  };

  const opciones = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
    cutout: "90%",
    elements: {
      arc: {
        borderWidth: 1,
        borderColor: "#FFF",
        weight: 5,
        onHover: function (dataB) {
          console.log("datos ", dataB);
          console.log(1, dataB);
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        external: function (context) {
          // Create element on first render
          if (!isTooltipActive) {
            setTooltip(true);
          }

          // Hide if no tooltip
          const tooltipModel = context.tooltip;
          let currentValue = tooltipModel.dataPoints[0].parsed;
          var percentage = Math.floor((currentValue / gainTotal) * 100 + 0.5);

          setTheLabel(context.tooltip.dataPoints[0].label);
          setTotal(tooltipModel.dataPoints[0].parsed);
          setThePercentage(percentage);

          if (tooltipModel.opacity === 0) {
            setTooltip(false);
          }

          function getBody(bodyItem) {
            return bodyItem.lines;
          }

          if (tooltipModel.body) {
            const bodyLines = tooltipModel.body.map(getBody);
            bodyLines.forEach(function (body, i) {
              var colors = tooltipModel.labelColors[i];
              setColor(colors.backgroundColor);
            });
          }
        },
      },
    },
  };

  useEffect(() => {
    async function fetchDataZones() {
      try {
        const infoZone = await GetListZones(estado);
        var zonas = [];
        var ids = [];
        var infoSocio = [];
        ganancias = [];
        infoZone.forEach((item) => {
          zonas.push(item.zone_name);
          ids.push(item.id);
          infoSocio.push(item.franchisse_id);
        });

        ganancias = await Promise.all(
          infoZone.map(async (item) => {
            const response = await GetProfitByZone({
              id: item.id,
              dates: [firstDate, secondDate],
            });
            return response.toFixed(2);
          })
        );

        let sum = 0;
        for (let i = 0; i < ganancias.length; i++) {
          sum += parseFloat(ganancias[i]);
        }

        setLabels(zonas);
        setGainTotal(sum);

        const colores = generarColores(zonas.length);
        const complete = [];
        for (let i = 0; i < zonas.length; i++) {
          complete.push({
            id: ids[i],
            partner: infoSocio[i],
            zone: zonas[i],
            percentage: ganancias[i],
            color: colores[i],
          });
        }
        setCompleteData(complete);
      } catch (error) {
        console.log("error", error);
      }
    }

    fetchDataZones();
  }, [firstDate, secondDate, typeDate]);

  return (
    <Box sx={generalContainer}>
      <BasicHeader>
        <Box sx={textContainer}>
          <h3 className={classes.title}>{estado}</h3>
        </Box>
      </BasicHeader>

      <Box sx={centarlContainer}>
        <div className={classes.dateLine}>
          <RadioTypeDateGroup typeOrder={"Anual"} handleValue={getSortType} />
          {typeDate === "Anual" && (
            <RangePicker
              widthDate={"160px"}
              initDay={pastYear}
              typeDateRange={typeDate}
              handleDate={getBetweenDates}
            />
          )}
          {typeDate === "Mensual" && (
            <RangePicker
              widthDate={"160px"}
              initDay={pastDayMonth}
              typeDateRange={typeDate}
              handleDate={getBetweenDates}
            />
          )}
          {typeDate === "Semanal" && (
            <RangePicker
              widthDate={"160px"}
              initDay={pastWekend}
              typeDateRange={typeDate}
              handleDate={getBetweenDates}
            />
          )}
          {typeDate === "Día" && (
            <RangePicker
              widthDate={"160px"}
              initDay={pastDay}
              typeDateRange={typeDate}
              handleDate={getBetweenDates}
            />
          )}
        </div>

        <div className={classes.blueHeader}>
          <p className={classes.headText}>Zonas</p>
        </div>

        <div className={classes.columnsContainer}>
          <div className={classes.buttonsContainer}>
            {completeData !== null ? (
              <>
                {completeData.map((element, index) => (
                  <ButtonZone
                    type={"Editor"}
                    id={element.id}
                    key={index}
                    zone={element.zone}
                    partner={""}
                    gain={""}
                    color={element.color}
                  />
                ))}
              </>
            ) : (
              <p>Sin resultados</p>
            )}
          </div>
        </div>
      </Box>
    </Box>
  );
}
