import { makeStyles } from "@mui/styles";

export const containerStyles = {
  width: "100%",
  maxWidth: "1148px",
  maxHeight: "159px",
  background: "#FFFFFF",
  borderRadius: "10px",
  padding: "15px 52px 12px 35px",
  marginBottom: "35px"
}

export const headerContainerStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems:"center"
}

const useStyles = makeStyles({
  zoneBusinessText:{
    //font-family: Inter;
    fontSize: "3rem",
    fontWeight: 700,
    lineHeight: "58px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  userInfo:{
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "29px",
    letterSpacing: "0em",
    textAlign: "right",
    color: "#828282"
  }
})

export {useStyles};