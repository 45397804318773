import React, { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Alert from "@mui/material/Alert";
import Slide from "@mui/material/Slide";

const useStyles = makeStyles((theme) => ({
  root: ({ typeAlert}) => ({
    background: typeAlert === "success" ? "#2e7d32" : "#d32f2f",
  }),
}));

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

export default function ModalAlert({ title, typeAlert, isOpen, handleAlert }) {
  const classes = useStyles({typeAlert});
  const [open, setOpen] = useState(false);

  const handleClose = () => {
    setOpen(false);
    handleAlert(false);
  };

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent className={classes.root} >
        <Alert variant="filled" severity={typeAlert}>
          {title}
        </Alert>
      </DialogContent>
    </Dialog>
  );
}
