import Box from '@mui/material/Box';
import { useStyles, textContainer } from "./styles/StatementTableText.styles";

export default function StatementTableTex({icon, text, amount}){
  const { stateAccountInfo } = useStyles();
  return (
    <Box sx={textContainer}>
      <p className={stateAccountInfo}>{icon}{text}</p><p className={stateAccountInfo}>${amount}</p>
    </Box>
  );
}