function GetpdfDoc() {
  const url_pdf = "https://niku-cms.com/file/receipt";
  async function getPDF(objPdf) {
    // console.log("🚀 ~ file: getPdf.js ~ line 4 ~ getPDF ~ objPdf", objPdf)

    const newFormdata = new FormData();
    newFormdata.append("sellerData", JSON.stringify(objPdf.sellerData));
    newFormdata.append("datesOfMovements", objPdf.datesOfMovements);
    newFormdata.append("totalToReceive", objPdf.totalToReceive);
    newFormdata.append("totalPayable", objPdf.totalPayable);
    newFormdata.append("deliveryData", JSON.stringify(objPdf.deliveryData));
    newFormdata.append("faceToFaceData", JSON.stringify(objPdf.faceToFaceData));
    newFormdata.append(
      "deliveryDetailsData",
      JSON.stringify(objPdf.deliveryDetailsData)
    );
    newFormdata.append(
      "faceToFaceDetailsData",
      JSON.stringify(objPdf.faceToFaceDetailsData)
    );

    try {
      const getBin = await fetch(url_pdf, {
        method: "POST",
        body: newFormdata,
        /*      mode: "cors",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Access-Control-Allow-Methods": "*"
                } */
      });
      // console.log("🚀 ~ file: getPdf.js ~ line 24 ~ getPDF ~ getBin", getBin)
      /*             console.log("🚀 ~ file: getPdf.js ~ line 21 ~ getPDF ~ getBin", getBin) */

      return getBin.json();
    } catch (error) {
      /*             console.log("🚀 ~ file: getPdf.js ~ line 24 ~ getPDF ~ error", error) */
      return error;
    }
  }

  return { getPDF };
}

export default GetpdfDoc;
