import { makeStyles } from "@mui/styles";

export const cardStyles = {
  width: "988px",
  //height: "784px",
  margin: "auto",
  background: "#FFFFFF",
  backdropFilter: "blur(4px)",
  borderRadius: "50px",
  padding: "65px 113px 90px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexDirection: "column"
}

export const avatarStyles = {
  width: "210px",
  height: "210px",
  border: "3px solid #EA5B13",
  boxSizing: "border-box",
}

export const editBtn = {
  textTransform: "capitalize",
  boxShadow: "none",
  fontSize: "1.125rem",
  lineHeight: "21.94px",
  fontWeight: 700,
  background: "#FFFFFF",
  color: "#EA5B13",
  width: "152px",
  height: "40px",
  alignItems: "center",
  ":hover": {
    boxShadow: "none",
    background: "#F2F2F2"
  },
};

export const userPhotoContainer = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "15px",
  marginBottom: "40px"
}

export const infoContainerStyles = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  gap: "22px"
}

export const dividerStyles = {
  //border: "1px solid #F2F2F2",
  borderColor: "#F2F2F2",
  width: "763px"
}

const useStyles = makeStyles({
  editIconStyles:{
    width: 20,
    height: 20,
  },
  userInfoStyles:{
    fontWeight: 500,
    fontSize: "1.25rem",
    lineHeight: "24px",
    textAlign: "center",
    color: "#4F4F4F",
  }
})

export {useStyles};
