import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Box from "@mui/material/Box";
import { useStyles } from "./styles/BusinessOrders.styles";
import {
  boxContainerStyles,
  textContainer,
} from "./styles/BusinessOrders.styles";
import BasicHeader from "../../DesignSystem/BasicHeader";
import FlatButton from "../../DesignSystem/FlatButton";
import OneDatePicker from "../../DesignSystem/OneDatePicker";
import BusinessOrdersRealTime from "./BusinessOrdersRealTime.ui";
import { getZoneModel } from "../../Zones/Infrastructure/Zone.reducer";
import { getBusinessPartnerModel } from "../../BusinessPartner/Infrastructure/BusinessPartner.reducer";
import { GetOrdersWithFilter } from "../Infrastructure/Business.presenter";

const buttonText = {
  height: "32px",
  borderRadius: "5px",
  marginBottom: "5px",
};

const buttonDisable = {
  height: "32px",
  color: "#000",
  boxShadow: "none",
  background: "#F6F6F6",
  border: "none",
  borderRadius: "5px",
  marginBottom: "5px",
};
//(Ejemplo de aceptadas)
/* const arrayRealTime = [
  {
    id: "343d8919-652d-44ce-b554-2208b4f9221d",
    shop_name: "Central Cold",
    orders: {
      items: [
        {
          delivery_man_id: {
            id: "4898a0db-65db-4f64-9108-e650b7c38cc5",
            last_name: "Quiroz",
            name: "Damaris",
          },
          final_total: 150,
          id: "69bb3a05-56ae-40c7-83bf-fad162a28d53",
          level: "NUEVA",
          status: "ACEPTADA",
          updatedAt: "2022-01-28T04:40:34.554Z",
        },
        {
          delivery_man_id: {
            id: "4898a0db-65db-4f64-9108-e650b7c38cc4",
            last_name: null,
            name: "Jonathan",
          },
          final_total: 150,
          id: "69bb3a05-56ae-40c7-83bf-fad162a28d54",
          level: "NUEVA",
          status: "ACEPTADA",
          updatedAt: "2022-01-28T04:40:34.554Z",
        },
        {
          delivery_man_id: {
            id: "4898a0db-65db-4f64-9108-e650b7c38cc2",
            last_name: null,
            name: "Erick",
          },
          final_total: 150,
          id: "69bb3a05-56ae-40c7-83bf-fad162a28d51",
          level: "NUEVA",
          status: "ACEPTADA",
          updatedAt: "2022-01-28T04:40:34.554Z",
        },
      ],
    },
  },
  {
    id: "343d8919-652d-44ce-b554-2208b4f9221d",
    shop_name: "Helados Valverde",
    orders: {
      items: [
        {
          delivery_man_id: {
            id: "4898a0db-65db-4f64-9108-e650b7c38cd1",
            last_name: null,
            name: "Noé",
          },
          final_total: 150,
          id: "69bb3a05-56ae-40c7-83bf-fad162a28dd2",
          level: "NUEVA",
          status: "ACEPTADA",
          updatedAt: "2022-01-28T04:40:34.554Z",
        },
        {
          delivery_man_id: {
            id: "4898a0db-65db-4f64-9108-e650b7c38cd3",
            last_name: null,
            name: "Rebeca",
          },
          final_total: 150,
          id: "69bb3a05-56ae-40c7-83bf-fad162a28dd4",
          level: "NUEVA",
          status: "ACEPTADA",
          updatedAt: "2022-01-26T04:40:34.554Z",
        },
        {
          delivery_man_id: {
            id: "4898a0db-65db-4f64-9108-e650b7c38cd5",
            last_name: null,
            name: "María Fernanda",
          },
          final_total: 150,
          id: "69bb3a05-56ae-40c7-83bf-fad162a28dd6",
          level: "NUEVA",
          status: "ACEPTADA",
          updatedAt: "2022-01-27T04:40:34.554Z",
        },
      ],
    },
  },
]; */

export default function BusinessOrders() {
  const classes = useStyles();
  const currentZone = useSelector(getZoneModel);
  const currentPartner = useSelector(getBusinessPartnerModel);
  const [page1, setPage1] = useState(true);
  const [page2, setPage2] = useState(false);
  const today = moment(new Date()).utc().format("YYYY-MM-DD");
  const [realTime, setRealTime] = useState([]);
  const [finished, setOrderFinish] = useState([]);

  const setPage = async (e) => {
    setPage1(!page1);
    setPage2(!page2);
  };

  const getSortDate = (value) => {
    let newDate = moment(value).utc().format("YYYY-MM-DD");
    dataOrders(currentZone.zone_id, newDate);
  };

  useEffect(() => {
    if (currentZone) {
      fetchDataOrders(currentZone.zone_id);
      dataOrders(currentZone.zone_id, today);
    }
  }, [currentZone]);

  // Para las órdenes en tiempo real
  async function fetchDataOrders(zone) {
    let arrayRealTime = [];
    const info = await GetOrdersWithFilter(zone, today, "ACEPTADA");
    info.map((element) => {
      arrayRealTime.push(element);
    });

    const pen = await GetOrdersWithFilter(zone, today, "RECHAZADA");
    pen.map((element) => {
      arrayRealTime.push(element);
    });

    const cancel = await GetOrdersWithFilter(zone, today, "CANCELADA");
    cancel.map((element) => {
      arrayRealTime.push(element);
    });

    const fin = await GetOrdersWithFilter(zone, today, "FINALIZADO");
    fin.map((element) => {
      arrayRealTime.push(element);
    });

    setRealTime(arrayRealTime);
  }

  //Para el historial de órdenes
  async function dataOrders(zone, date) {
    console.log("La fecha en el query 2: ", date);
    const fin = await GetOrdersWithFilter(zone, date, "FINALIZADO");
    console.log("INFO CON STATUS FINALIZADO:  ", fin);
    setOrderFinish(fin);
  }

  return (
    <Box sx={{ width: "100%" }}>
      <BasicHeader>
        <Box sx={textContainer}>
          {currentZone && currentPartner && (
            <>
              <h3 className={classes.zoneBusinessText}>
                {currentZone.zone_name}
              </h3>
              <Box>
                <p className={classes.userInfo}>
                  {currentPartner.name} {currentPartner.last_name}
                </p>
                <p className={classes.userInfo}>{currentPartner.birthday}</p>
                <p className={classes.userInfo}>{currentPartner.email}</p>
              </Box>
            </>
          )}
        </Box>
      </BasicHeader>
      <Box sx={boxContainerStyles}>
        <div className={classes.headerContainer}>
          <FlatButton
            typeButton="button"
            title={"Tiempo real"}
            widthBtn={"161px"}
            onClick={setPage}
            customStyles={page1 ? buttonText : buttonDisable}
          />
          <FlatButton
            typeButton="button"
            title={"Historial"}
            widthBtn={"161px"}
            onClick={setPage}
            customStyles={page2 ? buttonText : buttonDisable}
          />
          {page2 && <OneDatePicker title={"Fecha"} handleDate={getSortDate} />}
        </div>
        {page1 && realTime && <BusinessOrdersRealTime data={realTime} />}
        {page2 && finished && <BusinessOrdersRealTime data={finished} />}
      </Box>
    </Box>
  );
}
