import { Amplify, API, Auth, graphqlOperation } from "aws-amplify";
import React, { useEffect, useState } from "react";
import awsconfig from "./aws-exports";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { useSelector } from "react-redux";
import store from "./Redux/store";
import { getUserModel } from "./User/Infrastructure/User.reducer";
import { getZoneModel } from "./Zones/Infrastructure/Zone.reducer";
import { getBusinessPartnerModel } from "./BusinessPartner/Infrastructure/BusinessPartner.reducer";
import { GetUserInfo } from "./User/Infrastructure/User.presenter";
import GetBusinessPartnerInfo from "./BusinessPartner/Infrastructure/BusinessPartner.presenter";
import { setZoneModel } from "./Zones/Infrastructure/Zone.reducer";
import { GetRoleShop } from "../src/graphql/myQueries";

import Navbar from "./DesignSystem/Navbar";
import LoginUI from "./Auth/UI/Login.ui";
import StatesList from "./DesignSystem/StatesList.ui";
import ZonesList from "./Zones/UI/ZonesList.ui";
import DeliveryList from "./Zones/UI/DeliveryList.ui";
import ZonesListEditor from "./Zones/UI/ZoneListEditor.ui";
import UserProfileUI from "./User/UI/UserProfile.ui";
import MainZonesPageUI from "./Zones/UI/MainZonesPage.ui";
import BusinessInfo from "./Business/UI/BusinessInfo.ui";
import MainZonesEditorUI from "./Zones/UI/MainZonesEditor.ui";
import BussinesEditorInfo from "./Business/UI/BusinessinfoEditor";

Amplify.configure(awsconfig);

function App() {
  const currentUser = useSelector(getUserModel);
  const currentZone = useSelector(getZoneModel);
  const currentPartner = useSelector(getBusinessPartnerModel);
  const [role, setRol] = useState(null);

  async function getRole() {
    try {
      const user = await Auth.currentAuthenticatedUser();

      const role = await API.graphql(
        graphqlOperation(GetRoleShop, { id: user.attributes.sub })
      );
      setRol(role.data.getAccount.role);
    } catch (e) {
      setRol(null);
    }
  }
  
  useEffect(() => {
    getRole();
  });

  useEffect(() => {
    if (!currentUser) {
      GetUserInfo(localStorage.getItem("userid"));
    }

    if (!currentZone) {
      store.dispatch(
        setZoneModel({
          zone_id: localStorage.getItem("zone"),
          zone_name: localStorage.getItem("zone_name"),
        })
      );
    }

    if (!currentPartner) {
      GetBusinessPartnerInfo(localStorage.getItem("partner"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser, currentZone]);

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <LoginUI />
        </Route>

        <PrivateRoute exact path="/estados">
          <StatesList />
        </PrivateRoute>

        <PrivateRoute exact path="/estados/:estado">
          <Redirect to="/estados" />
        </PrivateRoute>

        <PrivateRoute exact path="/estados/:estado/zonas">
          {role === "editor" ? <ZonesListEditor /> : <ZonesList />}
        </PrivateRoute>

        <PrivateRoute exact path="/estados/:estado/DeliveryList">
          {<DeliveryList />}
        </PrivateRoute>

        <PrivateRoute exact path="/estados/zonas/negocios">
          {role === "editor" ? <MainZonesEditorUI /> : <MainZonesPageUI />}
        </PrivateRoute>

        <PrivateRoute exact path="/estados/zonas">
          <Redirect to="/estados" />
        </PrivateRoute>

        <PrivateRoute exact path="/perfil">
          <UserProfileUI />
        </PrivateRoute>

        <PrivateRoute exact path="/estados/zonas/negocios/:nombre">
          {role === "editor" ? <BussinesEditorInfo /> : <BusinessInfo />}
        </PrivateRoute>
      </Switch>
    </Router>
  );
}

export default App;

export function PrivateRoute({ children, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !localStorage.getItem(
          "CognitoIdentityServiceProvider.10th6r5fgst4ihbf0gvpelpbjf.LastAuthUser"
        ) ? (
          <Redirect
            to={{
              pathname: `/`,
              state: { from: location },
            }}
          />
        ) : (
          <>
            <Navbar />
            {children}
          </>
        )
      }
    />
  );
}
