import { Link, useLocation } from 'react-router-dom';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useStyles } from './styles/BreadCrumb.styles';

export default function BreadCrumb(){
  const {breadCrumbText, linkStyles, activePosition} = useStyles();
  const location = useLocation();
  const pathnames = location.pathname.split('/').filter((x) => x);

  return (
    <Breadcrumbs separator={<NavigateNextIcon fontSize="medium" />} aria-label="breadcrumb">
      {/* <Link className={`${breadCrumbText} ${linkStyles}`} to="/">Home</Link> */}
      {pathnames.map((value, index) => {
        const last = index === pathnames.length - 1;
        const to = `/${pathnames.slice(0, index + 1).join('/')}`;

        return last ? (
          <p key={to} className={`${breadCrumbText} ${activePosition}`}>{value}</p>
        ) : (
          <Link className={`${breadCrumbText} ${linkStyles}`} to={to} key={to}>{value}</Link>)
        })}
    </Breadcrumbs>
  );
}