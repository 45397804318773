import { useState, useEffect } from "react";
import { useHistory } from "react-router";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/system";
import MenuButton from "./MenuButton";
import { GetListCountries } from "../Zones/Infrastructure/Zone.presenter";

const centarlContainer = {
  background: "white",
  borderRadius: "10px",
  width: { xs: "100%", sm: "100%", md: "90%" },
  height: "auto",
  maxWidth: "1110px",
  margin: { sm: "0px", md: "auto" },
  minHeight: "913px",
  padding: { xs: "60px 5px", sm: "55px 20px", md: "55px 40px 59px" },
};

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
    flexWrap: "wrap",
    gap: 20,
    [theme.breakpoints.down("lg")]: {
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  title: {
    lineHeight: "58.09px",
    fontSize: "3rem",
    color: "#333333",
    marginBottom: "29px",
  },
}));

export default function StatesList() {
  const classes = useStyles();
  const history = useHistory();
  const [states, setStates] = useState([]);

  useEffect(() => {
    fetchDataZones();
  }, []);

  async function fetchDataZones() {
    try {
      const info = await GetListCountries();
      var countries = [];

      for (var i = 0; i < info.length; i++) {
        const elemento = info[i].zone_state;

        if (!countries.includes(info[i].zone_state)) {
          countries.push(elemento);
        }
      }

      setStates(countries);
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <Box sx={centarlContainer}>
      &nbsp;
      <h3 className={classes.title}>NEGOCIOS</h3>
      {states !== null &&
        states.map((item) => {
          return (
            <MenuButton
              key={item}
              title={item}
              onClick={() => {
                history.push(`/estados/${item}/zonas`);
              }}
            />
          );
        })}
      &nbsp;
      <h3 className={classes.title}>REPARTIDORES</h3>
      <MenuButton
        title="Repartidores activos en la ciudad Puebla"
        onClick={() => {
          history.push(`/estados/${"Puebla"}/DeliveryList`);
        }}
      />
    </Box>
  );
}
