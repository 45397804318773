const ThemeComponents = {
  MuiButton: {
    styleOverrides: {
      root: {
        position: "relative",
        fontSize: "0.875rem",
        color: "white",
        fontWeight: 500,
        textTransform: "none",
        fontFamily: 'Montserrat',
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      root: {
        fontFamily: 'Montserrat',
      },
    },
  },
  MuiInput: {
    styleOverrides: {
      root: {
        fontFamily: 'Montserrat',
        fontSize: "1rem",
      },
    },
  },
  MuiInputLabel:{
    styleOverrides: {
      root: {
        fontSize: "1rem",
        color: "white",
        fontFamily: 'Montserrat',
      },
    },
  }
};
  
export default ThemeComponents;