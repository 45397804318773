import React, { useState } from "react";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Checkbox from "@mui/material/Checkbox";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import SquareRoundedIcon from '@mui/icons-material/SquareRounded';
import CheckBoxRoundedIcon from '@mui/icons-material/CheckBoxRounded';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import MicNoneIcon from "@mui/icons-material/MicNone";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import {searchFormControl } from "./styles/HorizontalSelect.styles";
import {useStyles} from "./styles/HorizontalSelect.styles";

export default function HorizontalSelect({options, selected, handleCategory, searchValue, handleInputSearch, handleFunction}) {
  const classes = useStyles();
  const [checked, setChecked] = useState([0]);
  const [state, setState] = useState({ bottom: false });
  const [categoria, setCategoria] = useState(selected);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleToggle = (value, text) => () => {
    const currentIndex = checked.indexOf(value);
    if (currentIndex === -1) {
      setChecked([value]);
      setCategoria(text);
    } 
    handleCategory(text);
  };

  const handleChangeInputSearch = (e) => {
   // setSearchValue(e.target.value);
    handleInputSearch(e.target.value)
  };

  return (
    <div sx={{ width: 50 }}>
      <Button
        variant="outlined"
        color="gray"
        onClick={toggleDrawer("bottom", true)}
        endIcon={<ArrowForwardIosIcon color="secondary" />}
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        fullWidth
      >
        <p className={classes.text}>{categoria}</p>
      </Button>

      <Drawer
        anchor={"bottom"}
        open={state["bottom"]}
        onClose={toggleDrawer("bottom", false)}
        onOpen={toggleDrawer("bottom", true)}
        sx={{
          "& .MuiDrawer-paper": {
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
            backgroundColor: "#F2F2F2",
            maxHeight: "60%"
          },
        }}
      >
        <Box
          sx={{ width: "auto", margin: "30px 20px 10px" }}
          role="presentation"
        >
        <div>
              <FormControl sx={searchFormControl}>
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  placeholder={"Buscar categoría"}
                  onChange={handleChangeInputSearch}
                  value={searchValue}
                  id="busqueda"
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') handleFunction()
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#333333" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <MicNoneIcon sx={{ color: "#004990" }} />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    classes: { root: classes.inputStyles },
                    sx: { fontSize: ".9rem" },
                  }}
                />
              </FormControl>
            </div>
          <List>
            {options.map((text, index) => (
              <ListItem
              button
              key={index}
              onClick={handleToggle(index, text)}
              sx={{ borderBottom: "1px solid #FFF" }}
              dense
            >
                <ListItemIcon>
                  <Checkbox
                    edge="start"
                    checked={checked.indexOf(index) !== -1}
                    tabIndex={-1}
                    disableRipple
                    icon={<SquareRoundedIcon />} 
                    checkedIcon={<CheckBoxRoundedIcon />}
                    sx={{ 
                      color: "#FFF",
                      "&.Mui-checked": {
                        color: "#333",
                      },
                    }}
                  />
                </ListItemIcon>
                <ListItemText id={index} primary={text} />
              </ListItem>
            ))}
          </List>
        </Box>
      </Drawer>
    </div>
  );
}

HorizontalSelect.defaultProps = {
  selected: "",
  searchValue: "",
};

HorizontalSelect.propTypes = {
  options: PropTypes.array.isRequired,
  selected: PropTypes.string,
  searchValue: PropTypes.string,
  handleFunction: PropTypes.func.isRequired
};