import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";

const useStyles = makeStyles({
  root: ({ widthBtn }) => ({
    width: widthBtn,
    height: "36px",
  }),
  text: {
    color: "#000",
    fontWeight: 700
  }
});

export default function ButtonText({ title, typeButton, widthBtn, onClick }) {
  const classes = useStyles({ widthBtn });

  return (
    <Button type={typeButton} className={classes.root} onClick={onClick}>
      <p className={classes.text} >{title}</p>
    </Button>
  );
}

ButtonText.defaultProps = {
  onClick: () => null,
  typeButton: "button",
};

ButtonText.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string.isRequired,
  typeButton: PropTypes.string,
  widthBtn: PropTypes.string.isRequired,
};
