import React from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import StarRoundedIcon from '@mui/icons-material/StarRounded';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    border: "none",
    borderRadius: 10,
    maxHeight: 70,
    height: "60px",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
      background: "#E0E0E0",
    },
    [theme.breakpoints.down("lg")]: {
      margin: "20px auto",
    },
    [theme.breakpoints.down("md")]: {
      margin: "10px auto",
      padding: "5px 20px",
      minHeight: 100,
    },
  },
  infoContainer: {
    width: "100%",
    textAlign: "left",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  icon: { 
    width: 8, 
    height: 8, 
    background: "#219653", 
    borderRadius: "50%",
    marginRight: 10,
  },
  userName: {
    height: "22px",
    width: "190px",
    fontSize: "1.125rem",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: "1.125rem",
    },
  },
  comment:{
    WebkitBoxOrient: "horizontal",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    height: "17px",
    width: "205px",
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  stars: {
    display: "flex",
    alignItems: "flex-end",
  }
}));

export default function CommentCard({
  id,
  businessId,
  name,
  lastName,
  comment,
  stars
}) {
  const classes = useStyles();
  
  return (
    <>
    <Card className={classes.root} sx={{ boxShadow: "none" }} onClick={()=>null} >
      <div className={classes.infoContainer}>
        <span className={classes.icon} />
        <h3 className={classes.userName}>
          {name} {lastName}
        </h3>
        <p className={classes.comment} >{comment}</p>
        <p className={classes.stars} >{stars} <StarRoundedIcon fontSize="small" /> </p>
      </div>
    </Card>
    </>
  );
}

CommentCard.defaultProps = {
  lastName: "",
  stars: 0,
};

CommentCard.propTypes = {
  id: PropTypes.number.isRequired,
  businessId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string,
  comment: PropTypes.string.isRequired,
  stars: PropTypes.number,
};
