import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import MicNoneIcon from "@mui/icons-material/MicNone";
import FormControl from "@mui/material/FormControl";
import { TextField } from "@mui/material";
import FlatButton from "../../DesignSystem/FlatButton";
import ButtonText from "../../DesignSystem/ButtonText";
import BasicHeader from "../../DesignSystem/BasicHeader";
import ModalAlert from "../../DesignSystem/ModalAlert";
import Divider from "@mui/material/Divider";
import DeliveryManCard from "../../DesignSystem/DeliveryManCard";
import AdviserCard from "../../DesignSystem/AdviserCard";
import RadioOrderGroup from "../../DesignSystem/RadioOrderGroup";
import { useStyles } from "./styles/UserList.styles";
import { boxContainerStyles, searchFormControl, textContainer} from "./styles/UserList.styles";
import { getListEmployees, searchByUserName } from "../Infrastructure/User.presenter";
import { getZoneModel } from "../../Zones/Infrastructure/Zone.reducer";
import { getBusinessPartnerModel } from "../../BusinessPartner/Infrastructure/BusinessPartner.reducer";

export default function UsersChatList({ role }) {
  const classes = useStyles();
  const currentZone = useSelector(getZoneModel);
  const currentPartner = useSelector(getBusinessPartnerModel);
  const [advisers, setAdvisers] = useState([]);
  const [dealers, setDealers] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [adviserR, setAdviserR] = useState(false);
  const [deliveryR, setDeliveryR] = useState(false);
  const [encapsuleDealers, setEncapsule] = useState([]);
  const [order, setOrder] = useState("Orden alfabético a-z");
  const [show, setShow] = useState(false);
  const [breakpoint, setBreakpoint] = React.useState(window.innerWidth <= 768 ? true : false);
  const [isOpenAlert, setOpenAlert] = useState(false);
  const [titleAlert, setTitleAlert] = useState("Ha ocurrido un error");
  const [initialData, setInitialData] = useState([]);
  const [dataFromDB, setDataDB] = useState([]);
  const elementRef = useRef();
  const ITEMS_PAGE = 10;
  const showAlertModal = () => setOpenAlert(true);


  const handleClickOpen = (alertState) => {
    setOpenAlert(alertState);
  };

  const getSortType = (value) => {
    setOrder(value !== undefined ? value : "Orden alfabético a-z");
    if (value === "Orden alfabético z-a") {
      setAdvisers(advisers.reverse());
      setDealers(dealers.reverse());
    } else if (value === "Fecha de contratación") {
      setAdvisers(advisers.sort(dateSort));
      setDealers(dealers.sort(dateSort));
    } else {
      setAdvisers(advisers.sort(SortArrayAZ));
      setDealers(dealers.sort(SortArrayAZ));
    }
  };

  function dateSort(a, b) {
    return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
  }

  function SortArrayAZ(x, y) {
    if (x.name < y.name) {
      return -1;
    }
    if (x.name > y.name) {
      return 1;
    }
    return 0;
  }


  const title = () => (role === "delivery" ? "REPARTIDORES" : "ASESORES");
  const placeholderText = () => role === "delivery" ? "Buscar repartidor" : "Buscar asesor";

  const handleResizeTitle = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      setBreakpoint(true);
    } else if (screenWidth >= 769) {
      setBreakpoint(false);
    }
  };

  useEffect(function () {
    const onChange = (entries, observer) => {
      const el = entries[0];
      if (el.isIntersecting) {
        setShow(true);
        observer.disconnect();
      }
    };
    const observer = new IntersectionObserver(onChange, {
      rootMargin: "100px",
    });
    observer.observe(elementRef.current);
    return () => observer.disconnect();
  });

 /*  useEffect(() => {
    if (currentZone) {
      fetchDataAdviser(currentZone.zone_id);
      fetchDataDelivery(currentZone.zone_id);
    }
    window.addEventListener("resize", handleResizeTitle);
    window.addEventListener("load", handleResizeTitle);
    return () => {
      window.removeEventListener("resize", handleResizeTitle);
      window.addEventListener("load", handleResizeTitle);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZone, currentPartner]); */

  async function fetchDataAdviser(zone) {
    try {
      const info = await getListEmployees({role: "afiliador", zone});
      setAdvisers(info.sort(SortArrayAZ));
      setAdviserR(true);
    } catch (error) {
      console.log("error", error);
    }
  }

  async function fetchDataDelivery(zone) {
    try {
      const info = await getListEmployees({role: "deliveryman", zone});
      // console.log("🚀 ~ file: UsersList.ui.jsx ~ line 129 ~ fetchDataDelivery ~ info", info)
      setInitialData(info.sort(SortArrayAZ));
      setDeliveryR(true);
    } catch (error) {
      console.log("error");
    }
  }

  useEffect(() => {
    fetchDataDelivery();
  }, []);

  useEffect(() => {
    setDataDB([...initialData]);
  }, [initialData]);

  useEffect(() => {
    let independent = dataFromDB.slice(0);
    setEncapsule([...independent.splice(0, ITEMS_PAGE)].sort(SortArrayAZ));
  }, [dataFromDB]);
  

  const handleChangeInputSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const handleSubmitAdviserSearch = async () => {
    try{
      const info = await searchByUserName({role: "afiliador", zone: currentZone.zone_id, name: searchValue});
      if(info.length !== 0){
        setAdvisers(info);
      }else{
        setTitleAlert("Sin resultados");
        showAlertModal();
      }
      setSearchValue("");
    }catch(error){
      console.log("Ocurrió un error: ", error);
      setSearchValue("");
    }
  };

  const handleSubmitDeliverySearch = async () => {
    try{
      const info = await searchByUserName({role: "deliveryman", zone: currentZone.zone_id, name: searchValue});
      if(info.length !== 0){
        setDealers(info);
      }else{
        setTitleAlert("Sin resultados");
        showAlertModal();
      }
      setSearchValue("");
    }catch(error){
      console.log("Ocurrió un error: ", error);
      setSearchValue("");
    }
  };

  return (
    <Box sx={{width: "100%"}}>
      <BasicHeader>
        <Box sx={textContainer}>
          {currentZone && currentPartner && (
            <>
              <h3 className={classes.zoneBusinessText}>{currentZone.zone_name}</h3>
              <Box>
                <p className={classes.userInfo}>{currentPartner.name} {currentPartner.last_name}</p>
                <p className={classes.userInfo}>{currentPartner.birthday ? moment(currentPartner.birthday).format("DD/MM/YYYY") : ""}</p>
                <p className={classes.userInfo}>{currentPartner.email}</p>
              </Box>
            </>
          )}
        </Box>
      </BasicHeader>
      <Box sx={boxContainerStyles}>
      {breakpoint === false ? (
        <>
          <div className={classes.headerContainer}>
            <h3 className={classes.title}>{title()}</h3>
          </div>
          <Divider sx={{ marginTop: "20px" }} />
          <div className={classes.searchContainer}>
            <RadioOrderGroup typeOrder={order} handleValue={getSortType} />
            <FormControl sx={searchFormControl}>
              <TextField
                fullWidth
                type="text"
                variant="standard"
                placeholder={placeholderText()}
                onChange={handleChangeInputSearch}
                onKeyPress={(e) => {
                  if (e.key === "Enter") {
                    role === "delivery"
                      ? handleSubmitDeliverySearch()
                      : handleSubmitAdviserSearch();
                  }
                }}
                id="busqueda"
                value={searchValue}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: "#333333" }} />
                    </InputAdornment>
                  ),
                  disableUnderline: true,
                  classes: { root: classes.inputStyles },
                  sx: { fontSize: ".9rem" },
                }}
              />
              <ButtonText title="Buscar" typeButton="button" widthBtn="100px"
                onClick={
                  role === "delivery"
                    ? handleSubmitDeliverySearch
                    : handleSubmitAdviserSearch
                }
              />
            </FormControl>
          </div>
        </>
      ) : (
        <>
          <div className={classes.headerContainer}>
            <div>
              <FormControl sx={searchFormControl}>
                <TextField
                  fullWidth
                  variant="standard"
                  type="text"
                  placeholder={placeholderText()}
                  onChange={handleChangeInputSearch}
                  value={searchValue}
                  id="busqueda"
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      role === "delivery"
                        ? handleSubmitDeliverySearch()
                        : handleSubmitAdviserSearch();
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#333333" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <MicNoneIcon sx={{ color: "#004990" }} />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    classes: { root: classes.inputStyles },
                    sx: { fontSize: ".9rem" },
                  }}
                />
              </FormControl>
            </div>
            <div className={classes.buttons}>
              <FlatButton
                title="Repartidores"
                typeButton="button"
                widthBtn="160px"
                isDisabled={role === "adviser"}
                //onClick={() => history.push("/personal/repartidores")}
              />
              <FlatButton
                title="Asesores"
                typeButton="button"
                widthBtn="160px"
                isDisabled={role === "delivery"}
                //onClick={() => history.push("/personal/asesores")}
              />
            </div>
            <RadioOrderGroup typeOrder={"Orden alfabético a-z"} handleValue={getSortType}/>
          </div>
        </>
      )}
      <div ref={elementRef} className={classes.userContainer}>
        {show ? (
          role === "delivery" && deliveryR ? (
            encapsuleDealers.length !== 0 ? (
              <>
                {encapsuleDealers.map((delivery) => (
                  <DeliveryManCard
                    key={delivery.id}
                    id={delivery.id}
                    name={delivery.name}
                    lastName={delivery.last_name}
                    image={delivery.url_photoProfile}
                    matricula="00-00-00"
                    registerDataBank={delivery.openPay_id}
                  />
                ))}
              </>
            ) : (
              <p style={{ width: "-webkit-fill-available" }}>Sin resultados</p>
            )
          ) : (
            <></>
          )
        ) : null}

        {show ? (
          role === "adviser" && adviserR ? (
            advisers.length !== 0 ? (
              <>
                {advisers.map((adviser) => (
                  <AdviserCard
                    key={adviser.id}
                    id={adviser.id}
                    name={adviser.name}
                    lastName={adviser.last_name}
                    image={adviser.url_photoProfile}
                  />
                ))}
              </>
            ) : (
              <p style={{ width: "-webkit-fill-available" }}>Sin resultados</p>
            )
          ) : (
            <></>
          )
        ) : null}
      </div> 
      <ModalAlert
        title={titleAlert}
        typeAlert={"error"}
        isOpen={isOpenAlert}
        handleAlert={handleClickOpen}
      />
    </Box>
    </Box>
  );
}