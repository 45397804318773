import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import FlatButton from "./FlatButton";

const orderOptions = ['Anual','Mensual','Semanal', 'Día'];

const textMonth = {
  height: "32px",
  width: "273px",
  borderRadius: "10px",
  background: "#F2F2F2",
  color: "#EA5B13",
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  boxShadow: "none",
  "&:hover": {
    cursor: "pointer",
    background: "#EA5B13",
    color: "#FFF",
  },
};

const useStyles = makeStyles((theme) => ({
  order: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function RadioTypeDateGroup({typeOrder, handleValue}){  
  const typeOrderOp = () => (order === undefined ? 'Anual' : order);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [order, setOrder] = React.useState(typeOrder);
  const [value, setValue] = React.useState(typeOrderOp);
  const open = Boolean(anchorEl);

  const handleOrderClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  
  const handleClose = (e) => {
    setOrder(e.target.name);
    setAnchorEl(null);
    handleValue(e.target.name);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  

  return(
    <div className={classes.order}>
      <FlatButton 
        title={typeOrderOp()}
        typeButton="button"
        widthBtn="273px"
        onClick={handleOrderClick}
        customStyles={textMonth}
      />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 60 * 5,
            width: '25ch',
            borderRadius: 10,
            background: "#F2F2F2",
          },
        }}
      >
         <RadioGroup
            aria-labelledby="radio-buttons-date-group"
            name="controlled-radio-buttons-date-group"
            value={value}
            onChange={handleChange}
          >
            {orderOptions.map((option) => (
            <MenuItem key={option} >
              <FormControlLabel 
              name={option} 
              value={option} 
              control={<Radio color="secondary" />} 
              label={option}
              onClick={handleClose}
              />
            </MenuItem>
          ))}
        </RadioGroup>
      </Menu>
    </div>
  );
}

RadioTypeDateGroup.defaultProps = {

};

RadioTypeDateGroup.propTypes = {
  typeOrder: PropTypes.string.isRequired, 
};