import { useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import {
  containerStyles,
  tabStyles,
  typeTabs,
} from "./styles/MainZonesPage.styles";
import TabPanel from "../../DesignSystem/TabPanel";
import UsersListUI from "../../User/UI/UsersList.ui";
import BusinessList from "../../Business/UI/BusinessList.ui";
import BusinessOrders from "../../Business/UI/BusinessOrders.ui";
import MainReportsPageUI from "../../Reports/UI/MainReportsPage.ui";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MainZonesPageUI() {
  const [value, setValue] = useState(5);
  const roleUser = localStorage.getItem("role");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box sx={containerStyles}>
      <Tabs
        sx={tabStyles}
        textColor="secondary"
        indicatorColor="secondary"
        value={value}
        onChange={handleChange}
      >
        <Tab sx={typeTabs} label="Reportes" {...a11yProps(0)} />
        <Tab sx={typeTabs} label="Vehículos" {...a11yProps(1)} />
        <Tab sx={typeTabs} label="Asesores" {...a11yProps(2)} />
        <Tab sx={typeTabs} label="Repartidores" {...a11yProps(3)} />
        <Tab sx={typeTabs} label="Ordenes" {...a11yProps(4)} />
        <Tab sx={typeTabs} label="Negocios" {...a11yProps(5)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <MainReportsPageUI />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <h1>Vehículos</h1>
      </TabPanel>
      <TabPanel value={value} index={2}>
        <UsersListUI role="adviser" />
      </TabPanel>
      <TabPanel value={value} index={3}>
        <UsersListUI role="delivery" />
      </TabPanel>
      <TabPanel value={value} index={4}>
        <BusinessOrders />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <BusinessList />
      </TabPanel>
    </Box>
  );
}
