import {GetUserInfoLogic, getPhotoProfileLogic, getListEmployeesLogic, searchByUserNameLogic, getDeliveryRatingLogic, getShopsByAdviserLogic, getCollectsByDeliveryLogic} from "../Application/GetUserInfo.logic";
import { setUserModel } from "./User.reducer";
import store from "../../Redux/store";

export async function GetUserInfo(id) {


  const infoUser = await GetUserInfoLogic(id);

  if(infoUser.role === "admin" || infoUser.role === "developer"){
    store.dispatch(setUserModel(infoUser));
    localStorage.setItem("role", infoUser.role);
  }
  return infoUser;
}

export async function GetPhotoProfile(url){
  return getPhotoProfileLogic(url);
} 

export async function getListEmployees(info){
  return getListEmployeesLogic(info);
}

export async function searchByUserName(info){
  return searchByUserNameLogic(info);
}

export async function getDeliveryRating(id){
  return getDeliveryRatingLogic(id);
}

export async function getCollectsByDelivery(data){
  return getCollectsByDeliveryLogic(data);
}

export async function getShopsByAdviser(data){
  return getShopsByAdviserLogic(data);
}