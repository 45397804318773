import {
  getBusinessInfoService,
  getGeoLocationService,
  getRatingShopService,
  getOrdersByShopLogicService,
  getProfitShopService,
  GetPublicDataService,
  getCLBService,
} from "../Infrastructure/Business.service";

export async function getBusinessInfoLogic(id) {
  const response = await getBusinessInfoService(id);
  if (response.schedule) {
    try {
      let schedule_json = JSON.parse(response["schedule"]);
      let schedule = [];
      schedule_json.forEach((day) => {
        schedule.push(
          day["nombre"] + ": " + day["inicio"] + " - " + day["fin"]
        );
      });
      return { ...response, schedule };
    } catch (error) {
      return { ...response, schedule: "---" };
    }
  } else {
    return response;
  }
}
export async function getclbLogic(id) {
  return getCLBService(id);
}
export async function getGeoLocationLogic(id) {
  return getGeoLocationService(id);
}

export async function getRatingShopLogic(id) {
  return getRatingShopService(id);
}

export async function getOrdersByShopLogic(id) {
  return getOrdersByShopLogicService(id);
}

export async function GetPublicDataLogic(id_shop) {
  return GetPublicDataService(id_shop);
}

export function getProfitShopLogic(data) {
  return getProfitShopService(data);
}
