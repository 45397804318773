import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import MicNoneIcon from "@mui/icons-material/MicNone";
import SearchIcon from "@mui/icons-material/Search";
import Divider from "@mui/material/Divider";
import { TextField } from "@mui/material";
import { useStyles } from "./styles/BusinessList.styles";
import {
  boxContainerStyles,
  searchFormControl,
  textContainer,
} from "./styles/BusinessList.styles";
import BasicHeader from "../../DesignSystem/BasicHeader";
import BusinessCard from "../../DesignSystem/BusinessCard";
import CheckSelect from "../../DesignSystem/CheckSelect";
import HorizontalSelect from "../../DesignSystem/HorizontalSelect";
import {
  GetListCategories,
  GetBusinessList,
  GetBusinessListByCategory,
  GetBusinessListByName,
} from "../Infrastructure/Business.presenter";
import { getZoneModel } from "../../Zones/Infrastructure/Zone.reducer";
import { getBusinessPartnerModel } from "../../BusinessPartner/Infrastructure/BusinessPartner.reducer";

export default function BusinessList() {
  const classes = useStyles();
  const currentZone = useSelector(getZoneModel);
  const currentPartner = useSelector(getBusinessPartnerModel);
  const [searchValue, setSearchValue] = useState("");
  const [searchCategoryValue, setSearchCategoryValue] = useState("");
  const [categories, setCategories] = useState([]);
  const [businessList, setBusinessList] = useState({});
  const [categoriesR, setCategoriesR] = useState(false);
  const [selectCategory, setSelectCategory] = useState(["Miscelánea"]);
  const [categoryTitle, setCategoryTitle] = useState("");
  const [businessR, setBusinessR] = useState(false);
  const [breakpoint, setBreakpoint] = useState(
    window.innerWidth <= 768 ? true : false
  );
  const [show, setShow] = useState(false);
  const elementRef = useRef();

  const getCategory = (value) => {
    setSelectCategory(value);
    handleBusinessCategory(value[0]);
  };

  const getCategoryHorizontalArray = (value) => {
    setSelectCategory(value);
    handleBusinessCategory(value[0]);
  };

  const handleBusinessCategory = async (searchCategory) => {
    const info = await GetBusinessListByCategory(
      currentZone.zone_id,
      searchCategory
    );

    if (info === undefined || info === null || info.length === 0) {
      setCategoryTitle(searchCategory);
      setBusinessList(null);
    } else {
      setCategoryTitle(searchCategory);
      setBusinessList(info);
    }
  };

  const handleResizeTitle = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth <= 768) {
      setBreakpoint(true);
    } else if (screenWidth >= 769) {
      setBreakpoint(false);
    }
  };

  useEffect(() => {
    if (currentZone) {
      fetchDataBusiness(currentZone.zone_id);
      fetchCategories();
    }
    window.addEventListener("resize", handleResizeTitle);
    window.addEventListener("load", handleResizeTitle);
    return () => {
      window.removeEventListener("resize", handleResizeTitle);
      window.addEventListener("load", handleResizeTitle);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentZone, currentPartner]);

  useEffect(function () {
    const onChange = (entries, observer) => {
      const el = entries[0];
      if (el.isIntersecting) {
        setShow(true);
        observer.disconnect();
      }
    };
    const observer = new IntersectionObserver(onChange, {
      rootMargin: "100px",
    });
    observer.observe(elementRef.current);
    return () => observer.disconnect();
  });

  async function fetchDataBusiness(zone) {
    const info = await GetBusinessList(zone);
    setBusinessList(info);
    setBusinessR(true);
  }

  async function fetchCategories() {
    const info = await GetListCategories();
    const newCategories = [...categories];
    info.map((cat) => newCategories.push(cat.category_name));
    setCategories(newCategories);
    setCategoriesR(true);
  }

  const handleChangeInputSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getCategoryToInputSearch = (value) => {
    setSearchCategoryValue(value);
  };
  // Para buscar un negocio por nombre en la barra de búsqueda
  const handleBusinessNameSearch = async () => {
    try {
      const info = await GetBusinessListByName(
        currentZone.zone_id,
        searchValue
      );

      setBusinessList(info);
      setSearchValue("");
      setCategoryTitle("");
    } catch (error) {
      console.log(error);
    }
  };
  // Para buscar por categoria en la barra de búsqueda del select de versión responsiva
  const handleBusinessCategorySearch = async () => {
    handleBusinessCategory(searchCategoryValue);
    setSearchCategoryValue("");
  };

  return (
    <Box sx={{ width: "100%" }}>
      <BasicHeader>
        <Box sx={textContainer}>
          {currentZone && currentPartner && (
            <>
              <h3 className={classes.zoneBusinessText}>{currentZone.zone_name}</h3>
              <Box>
                <p className={classes.userInfo}>{currentPartner.name} {currentPartner.last_name}</p>
                <p className={classes.userInfo}>{currentPartner.birthday ? moment(currentPartner.birthday).format("DD/MM/YYYY") : ""}</p>
                <p className={classes.userInfo}>{currentPartner.email}</p>
              </Box>
            </>
          )}
        </Box>
      </BasicHeader>
      <Box sx={boxContainerStyles}>
        {breakpoint === false ? (
          <>
            <div className={classes.headerContainer}>
              <div className={classes.inputContainer}>
                {categoriesR ? (
                  <>
                    <CheckSelect
                      options={categories}
                      defaultText={selectCategory}
                      handleValue={getCategory}
                    />
                  </>
                ) : (
                  <></>
                )}
              </div>
              <div className={classes.searchContainer}>
                <FormControl sx={searchFormControl}>
                  <TextField
                    fullWidth
                    type="text"
                    variant="standard"
                    placeholder="Buscar negocios"
                    onChange={handleChangeInputSearch}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        handleBusinessNameSearch();
                      }
                    }}
                    id="busqueda"
                    value={searchValue}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon sx={{ color: "#C4C4C4" }} />
                        </InputAdornment>
                      ),
                      disableUnderline: true,
                      classes: { root: classes.inputStyles },
                      sx: { fontSize: ".9rem" },
                    }}
                  />
                </FormControl>
              </div>
            </div>
            <Divider sx={{ marginTop: "20px" }} />
          </>
        ) : (
          <div className={classes.headerContainer}>
            <div>
              <FormControl sx={searchFormControl}>
                <TextField
                  fullWidth
                  type="text"
                  variant="standard"
                  placeholder={"Buscar negocio"}
                  onChange={handleChangeInputSearch}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      handleBusinessNameSearch();
                    }
                  }}
                  id="busqueda"
                  value={searchValue}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon sx={{ color: "#333333" }} />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <MicNoneIcon sx={{ color: "#004990" }} />
                      </InputAdornment>
                    ),
                    disableUnderline: true,
                    classes: { root: classes.inputStyles },
                    sx: { fontSize: ".9rem" },
                  }}
                />
              </FormControl>
            </div>
            {categoriesR ? (
              <>
                <HorizontalSelect
                  options={categories}
                  selected={selectCategory}
                  handleCategory={getCategoryHorizontalArray}
                  searchValue={searchCategoryValue}
                  handleInputSearch={getCategoryToInputSearch}
                  handleFunction={handleBusinessCategorySearch}
                />
              </>
            ) : (
              <></>
            )}
          </div>
        )}

        <h3 className={classes.businessType}>{categoryTitle}</h3>
        <div ref={elementRef} id="cat" className={classes.businessContainer}>
          {show ? (
            businessList !== null && businessR ? (
              <>
                {businessList.map((shop) => (
                  <BusinessCard
                    id={shop.id}
                    key={shop.id}
                    name={shop.shop_name}
                    image={shop.url_image}
                  />
                ))}
              </>
            ) : (
              <p>Sin resultados</p>
            )
          ) : null}
        </div>
      </Box>
    </Box>
  );
}
