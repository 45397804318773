import { makeStyles } from "@mui/styles";

export const collapseStyles = {
  textAlign: "right",
  width: "100%",
};

export const editIconStyles = {
  width: "17px",
  height: "17px",
};

export const iconBtnStyles = {
  width: { xs: "60px", md: "70px" },
  height: "31px",
  background: "#F2F2F2",
  borderRadius: "5px",
  color: "4F4F4F",
  ":hover": {
    background: "#BDBDBD",
  },
};

export const acceptBtnStyles = {
  width: "45px",
  fontSize: "0.75rem",
  position: "absolute",
  bottom: "8px",
  right: "8px",
  background: "#004990",
  ":hover": {
    background: "#004E9C",
  },
};

export const actionBtn = {
  alignSelf: "start",
  boxShadow: "none",
  fontSize: "0.875rem",
  fontWeight: 700,
  background: "white",
  color: "#4F4F4F",
  width: "100px",
  ":hover": {
    boxShadow: "none",
    backgroundColor: "#F2F2F2",
  },
};

export const addVehicle = {
  textAlign: "center",
  boxShadow: "none",
  fontSize: { xs: "10px", sm: "11px", md: "0.875rem" },
  fontWeight: 400,
  height: 31,
  ":hover": {
    boxShadow: "none",
  },
};

export const btnExitStyles = {
  position: "absolute",
  top: "10px",
  right: "20px",
  color: "#004990",
  boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
};

export const arrowStyles = {
  ":hover": {
    background: "#F2F2F2",
    borderRadius: "50%",
    cursor: "pointer",
  },
};

export const calendarContainer = {
  width: "400px",
  height: "396px",
  padding: "10px",
  position: "absolute",
  bottom: "calc(50% - 213px)",
  left: "calc(50% - 200px)",
  background: "#F5F5F5",
  boxShadow: "0px 1px 2px rgba(0, 14, 51, 0.25)",
  borderRadius: "16px",
  "& .MuiPickerStaticWrapper-root": {
    background: "#F5F5F5",
    borderRadius: "16px",
    maxWidth: "348px",
    margin: "auto",
  },
};

const useStyles = makeStyles((theme) => ({
  containerInfo: {
    marginTop: 10,
    width: "100%",
    //maxHeight: 519,
    margin: "auto",
    position: "relative",
    padding: "5px 20px 30px",
    background: "white",
    borderRadius: 10,
    height: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    gap: 25,
  },
  userMainInfo: {
    marginTop: 10,
    width: "100%",
  },
  profileImage: {
    width: 115,
    height: 115,
    objectFit: "cover",
    borderRadius: 5,
    [theme.breakpoints.down("sm")]: {
      width: 70,
      height: 70,
    },
  },
  userName: {
    width: "85%",
    fontWeight: 500,
    fontSize: "1.125rem",
    lineHeight: "22px",
    color: "#4F4F4F",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  rating: {
    fontWeight: 500,
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    fontSize: "0.875rem",
    lineHeight: "17.07px",
    color: "#4F4F4F",
  },
  roleNameContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      gap: 5,
    },
  },
  roleName: {
    fontSize: "0.875rem",
    color: "#4F4F4F",
    lineHeight: "17.07px",
    fontWeight: 500,
    [theme.breakpoints.down("sm")]: {
      fontSize: 11,
    },
  },
  rangeDatesContainer: {
    display: "flex",
    justifyContent: "flex-end",
    gap: 5,
    alignItems: "center",
    width: "70%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  amountGains: {
    position: "relative",
    width: 300,
    height: 104,
    /*     borderRadius: "50%",
    border: "7px solid #004990", */
    justifyContent: "center",
    display: "flex",
    flexDirection: "column",
    marginLeft: "5%",
  },
  amountContainer: {
    position: "relative",
    width: 104,
    height: 104,
    borderRadius: "50%",
    border: "7px solid #004990",
  },
  amountNum: {
    textAlign: "center",
    width: 50,
    color: "#4F4F4F",
    fontSize: "1.875rem",
    lineHeight: "36px",
    position: "absolute",
    left: "calc(50% - 25px)",
    top: "calc(50% - 18px)",
  },
  btnContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 5,
    [theme.breakpoints.up("md")]: {
      gridColumn: "1/-1",
    },
  },
  contactInfoContainer: {
    marginTop: 26,
    width: "100%",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 8,
  },
  rangeDates: {
    fontSize: "0.875rem",
    color: "#4F4F4F",
    lineHeight: "17.07px",
    fontWeight: 500,
    [theme.breakpoints.down("md")]: {
      fontSize: 12,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 10,
    },
  },
  idVehicle: {
    flexGrow: 2,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: 170,
    height: "31px",
    background: "#F2F2F2",
    borderRadius: "5px",
    color: "4F4F4F",
    ":hover": {
      background: "#BDBDBD",
    },
  },
  info: {
    fontSize: "0.875rem",
    lineHeight: "17px",
    color: "#4F4F4F",
  },
  principalUserInfoContainer: {
    marginTop: "17px",
    display: "flex",
    justifyContent: "flex-start",
    gap: "21px",
  },
  infoDescriptionContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "start",
    flexDirection: "column",
  },
  actionButtonsContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
}));

export { useStyles };
