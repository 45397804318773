import { makeStyles } from "@mui/styles";

export const textContainer = {
  display:"flex",
  justifyContent:" space-between",
  alignItems: "center",
}

const useStyles = makeStyles({
  stateAccountInfo: {
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "17px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#333333"
  }
});

export {useStyles};