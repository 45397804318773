import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import img from "../assets/default.png";
import ModalProfile from "./ModalProfile";
import { GetPhotoProfile } from "../User/Infrastructure/User.presenter";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    border: "1px solid #E5E5E5",
    borderRadius: 10,
    maxWidth: 400,
    minHeight: 116,
    height: "auto",
    width: "100%",
    "&:hover": {
      cursor: "pointer",
      background: "#E0E0E0",
    },
    [theme.breakpoints.down("lg")]: {
      margin: "20px auto",
    },
    [theme.breakpoints.down("md")]: {
      border: "none",
      margin: "10px auto",
      padding: "5px 20px",
      minHeight: 100,
    },
  },
  adviserImage: {
    width: 60,
    height: 60,
    maxHeight: 60,
    minHeight: 60,
    maxWidth: 60,
    minWidth: 60,
    borderRadius: 10,
    objectFit: "cover",
     [theme.breakpoints.down("md")]: {
      borderRadius: "50%",
      border: "3px solid #6CB22E",
    },
  },
  infoContainer: {
    width: "100%",
    textAlign: "left",
  },
  adviserName: {
    fontSize: "1.5rem",
    fontWeight: 500,
    marginBottom: 10,
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
    },
  },
}));

export default function AdviserCard({id, name, lastName, image, handleDeletedUser}) {
  const classes = useStyles();
  const [isOpenProfileModal, setProfileModal] = useState(false);
  const [photo, setPhoto] = useState(null);
  const showProfileModal = () => setProfileModal(true);

  const handleProfileModal = (profileModalState) => {
    setProfileModal(profileModalState);
  };

  const getUrlPhoto = async (urlPhoto) =>{
    const res = await GetPhotoProfile(urlPhoto);
    setPhoto(res);
  }

  useEffect(() => {
    if (image) {
      getUrlPhoto(image)
    }
  }, [image]);

  return (
    <>
    <Card
      className={classes.root}
      sx={{ boxShadow: "none" }}
      onClick={showProfileModal}
    >
      <img
        className={classes.adviserImage}
        src={image ? photo : img}
        alt=""
      />
      <div className={classes.infoContainer}>
        <h3 className={classes.adviserName}>
          {name} {lastName}
        </h3>
      </div>
    </Card>
    <ModalProfile
        id={id}
        isOpen={isOpenProfileModal}
        handleModal={handleProfileModal}
        name={name}
        lastName={lastName === null ? "" : lastName}
        profileImage={image ? photo : img}
        role="afiliador"
        handleDeletedUser={handleDeletedUser} 
      />
    </>
  );
}

AdviserCard.defaultProps = {
  image: ""
};

AdviserCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  lastName: PropTypes.string.isRequired,
  image: PropTypes.string,
};
