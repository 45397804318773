import React from "react";

class Calculatecoms extends React.Component {
  calculateCommisions = (taxDistance) => {
    var ret_Isr = 0.021; //Cantidad de ISR
    var ret_Iva = 0.08; //Cantidad de IVA
    var nullIVA = taxDistance / 1.16; // Suma de Tarfifas(Collection + Delivery + Distance) - IVA 16%
    var null_Isr = nullIVA * ret_Isr; //Retención ISR
    var null_Iva = nullIVA * ret_Iva; //Retención IVA
    var res = nullIVA - null_Isr - null_Iva; //Suma de Tarfifas sin IVA - Suma de (retención ISR + retención IVA)
    return res;
  };

  calculateDebts = (taxDistance) => {
    var ret_Isr = 0.021; //Cantidad de ISR
    var ret_Iva = 0.08; //Cantidad de IVA
    var nullIVA = taxDistance / 1.16; // Suma de Tarfifas(Collection + Delivery + Distance) - IVA 16%
    var null_Isr = nullIVA * ret_Isr; //Retención ISR
    var null_Iva = nullIVA * ret_Iva; //Retención IVA
    var res = null_Isr + null_Iva; //Suma de (retención ISR + retención IVA)
    return res;
  };
  calculateDebtsObj = (taxDistance) => {
    var ret_Isr = 0.021; //Cantidad de ISR
    var ret_Iva = 0.08; //Cantidad de IVA
    var nullIVA = taxDistance / 1.16; // Suma de Tarfifas(Collection + Delivery + Distance) - IVA 16%
    var iva_difference = taxDistance - nullIVA; //Diferencia de IVA
    var null_Isr = nullIVA * ret_Isr; //Retención ISR
    var null_Iva = nullIVA * ret_Iva; //Retención IVA

    return {
      subtotal: nullIVA.toFixed(2),
      ivaDif: iva_difference.toFixed(2),
      total: taxDistance.toFixed(2),
      isr: null_Isr.toFixed(2),
      iva: null_Iva.toFixed(2),
    };
  };
}

export default Calculatecoms;
