import React, { useState } from "react";
import PropTypes from "prop-types";

import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import MobileDatePicker from "@mui/lab/MobileDatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { addWeeks, add, sub } from "date-fns";
import esLocale from "date-fns/locale/es";

const textFieldStyles = {
  minWidth: "130px",
  maxWidth: "170px",
  height: "32px",
  "& .MuiFilledInput-root": {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
  },
  "& .MuiFilledInput-root:before": {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
  },
};

const inputStyles = {
  height: "32px",
  minWidth: "130px",
  maxWidth: "170px",
  alignItems: "center",
  "& .MuiInputBase-input": {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    maxHeight: "32px",
    minHeight: "32px",
    textAlign: "center"
  },
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: "#BDBDBD",
    fontSize: 14,
    textAlign: "left",
    marginBottom: "2px",
    marginLeft: "35px",
  },
  icon: { fill: "#EA5B13" },
}));

export default function OneDatePicker({ title, withTitle, handleDate }) {
  const classes = useStyles();
  const [value, setValue] = useState(new Date());

  const handleChange = (newValue) => {
    setValue(newValue);
    handleDate(new Date(newValue).toISOString());
  };

  const handleDayPlus = () => {
    let date = add(value, { days: 1 });
    setValue(date);
    handleDate(date.toISOString());
  };

  const handleDayLess = () => {
    let date = sub(value, { days: 1 });
    setValue(date);
    handleDate(date.toISOString());
  };

  return (
    <Box fullWidth sx={{ marginRight: "30px" }}>
      {withTitle && <p className={classes.title}>{title}</p>}
      <IconButton onClick={handleDayLess}>
        <ArrowBackIosIcon color="secondary" fontSize="small" />
      </IconButton>

     
        <LocalizationProvider  dateAdapter={AdapterDateFns} locale={esLocale} >
          <MobileDatePicker
            value={value}
            inputFormat="dd MMMM, yyyy"
            toolbarTitle="Selecciona una fecha"
            disableMaskedInput
            disableFuture
            onChange={handleChange}
            renderInput={(params) => (
              <TextField
                sx={textFieldStyles}
                size="small"
                color="secondary"
                {...params}
                InputProps={{
                  sx: inputStyles
                }}
              />
            )}
          />
        </LocalizationProvider>
      
      <IconButton onClick={handleDayPlus}>
        <ArrowForwardIosIcon color="secondary" fontSize="small" />
      </IconButton>
    </Box>
  );
}

OneDatePicker.defaultProps = {
  withTitle: true,
  title: ""
};

OneDatePicker.propTypes = {
  withTitle: PropTypes.bool,
  title: PropTypes.string
};