import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { getUserModel } from "../Infrastructure/User.reducer";
import { GetPhotoProfile } from "../Infrastructure/User.presenter";
import moment from "moment";
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import FlatButton from '../../DesignSystem/FlatButton';
import editIcon from "../../assets/edit-icon.svg";
import Divider from '@mui/material/Divider';
import { cardStyles, avatarStyles, editBtn, userPhotoContainer, dividerStyles, infoContainerStyles} from "./styles/UserProfile.styles";
import { useStyles } from './styles/UserProfile.styles';

export default function UserProfileUI(){
  const { editIconStyles, userInfoStyles } = useStyles();
  const [photo, setPhoto] = useState(null);
  const user = useSelector(getUserModel);
  
  useEffect(() => {
    
    const getPhoto = async () => {
      const photoProfile = await GetPhotoProfile(user.url_photoProfile);
      console.log("🚀 ~ file: UserProfile.ui.jsx ~ line 24 ~ getPhoto ~ photoProfile", photoProfile)
      setPhoto(photoProfile);
    }

    if (user && user.url_photoProfile) {
      getPhoto();
    }
    
  }, [user]);
  
  const handleClick = () => {
    console.log("Editar perfil");
  }

  const formatedPhone = (phone) => `(${phone.slice(0,3)}) ${phone.slice(3)}`;

  return (
    <Card sx={cardStyles} elevation={0}>
      <Box sx={userPhotoContainer}>
          <Avatar sx={avatarStyles} src={photo} alt=""/>
          <FlatButton title="Editar" icon={<img className={editIconStyles} src={editIcon} alt="icono editar"/>} customStyles={editBtn} onClick={handleClick}/>
      </Box>
      <Box sx={infoContainerStyles}>
          <p className={userInfoStyles}>{`${user.name || ""} ${user.last_name || ""}`}</p>
          <Divider sx={dividerStyles}/>
          <p className={userInfoStyles}>{user.email || "No disponible"}</p>
          <Divider sx={dividerStyles}/>
          <p className={userInfoStyles}>{user.phone_number ? formatedPhone(user.phone_number) : ""}</p>
          <Divider sx={dividerStyles}/>
          <p className={userInfoStyles}>{moment(new Date(user.birthday)).format("DD/MM/YYYY") || "No disponible"}</p>
      </Box>
    </Card>
  );
}