import React, { useState, useEffect } from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import img from "../../assets/store.png";
import TabPanel from "../../DesignSystem/TabPanel";
import BusinessDetailsCard from "./BusinessDetailsCard";
import BusinessOfferCard from "../../DesignSystem/BusinessOfferCard";
import BusinessProducts from "./BusinessProducts";
import BusinessComments from "../../DesignSystem/BusinessComments";
import BasicHeader from "../../DesignSystem/BasicHeader";
import BusinessImage from "./BusinessImage";
import PercentIcon from "@mui/icons-material/Percent";
import { getBusinessInfo, getListOfferProducts } from "../Infrastructure/Business.presenter";
import { GetUserInfo } from "../../User/Infrastructure/User.presenter";
import BusinessLocation from "./BusinessLocation";
import BusinessCommissionsTable from "../../DesignSystem/BusinessCommissionsTable";
import BusinessDocumentscrud from "../../DesignSystem/BusinessDocumentscrud";
import ModalAlert from "../../DesignSystem/ModalAlert";
import { GetPhotoProfile } from "../../User/Infrastructure/User.presenter";
import { headerInfo, principalContainerStyles, typeTabs, useStyles, businessImageStyles } from "./styles/BusinessInfo.styles";
import BusinessPublicChart from "./BusinessPublicChart";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BussinesInfo() {
  const classes = useStyles();
  const id = localStorage.getItem("business");
  const [value, setValue] = useState(0);
  const [businessRender, setBusinessRender] = useState(false);
  const [photo, setPhoto] = useState(null);
  const [offers, setOffers] = useState([]);
  const [business, setBusiness] = useState({
    id: "",
    name: "",
    schedule: "",
    description: "",
    addressCoords: "",
    addressShop: "",
    affiliatorId: "",
    createdAt: "",
    products: "",
    sellerId: "",
    urlDocuments: "",
    urlImage: "",
    urlPdf: "",
    price: 0,
  });
  const [affiliatorInfo, setAffiliatorInfo] = useState({
    id: "",
    name: "",
    lastName: "",
  });
  const [isOpenAlert, setOpenAlert] = useState(false);
  const [titleAlert, setTitleAlert] = useState("Ha ocurrido un error");
  const showAlertModal = () => setOpenAlert(true);

  const handleClickOpen = (alertState) => {
    setOpenAlert(alertState);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getUrlPhoto = async (urlPhoto) => {
    const res = await GetPhotoProfile(urlPhoto);
    setPhoto(res);
  };

  useEffect(() => {
    if (id) {
      fetchDataBusiness(id);
      getListOffers(id);
    }
  }, [id]);

  async function fetchDataBusiness(id) {
    try {
      const response = await getBusinessInfo(id);
      setBusiness({
        ...business,
        id: response.id,
        name: response.shop_name,
        schedule: response.schedule,
        description: response.description,
        addressCoords: response.address_coords,
        addressShop: response.address_shop,
        affiliatorId: response.affiliator_id,
        createdAt: response.createdAt,
        products: response.products,
        sellerId: response.seller_id,
        urlDocuments: response.url_documents,
        urlImage: response.url_image,
        urlPdf: response.url_pdf,
        price: parseInt(response.price),
      });
      setBusinessRender(true);
      fetchAffiliatorData(response.affiliator_id);

      if (response.url_image !== null) {
        getUrlPhoto(response.url_image);
      }
    } catch (error) {
      setTitleAlert("Ha ocurrido un error al obtener la información");
      showAlertModal();
    }
  }

  async function fetchAffiliatorData(id) {
    try {
      const info = await GetUserInfo(id);
      setAffiliatorInfo({
        ...affiliatorInfo,
        id: info.id,
        name: info.name,
        lastName: info.last_name,
      });
    } catch (error) {
      console.log("Afiliador no encontrado");
    }
  }

  async function getListOffers(id) {
    try {
      const { items } = await getListOfferProducts(id);
      setOffers(items);
    } catch (error) {
      console.log("Ha ocurrido un error al obtener las ofertas");
    }
  }

  const TabElement = () => {
    return (
      <Tabs
        textColor="secondary"
        indicatorColor="secondary"
        value={value}
        onChange={handleChange}
      >
        <Tab sx={typeTabs} label="Información" {...a11yProps(0)} />
        <Tab sx={typeTabs} label="Público" {...a11yProps(1)} />
        <Tab sx={typeTabs} label="Estado de cuenta" {...a11yProps(2)} />
        <Tab sx={typeTabs} label="Documentación" {...a11yProps(3)} />
      </Tabs>
    );
  };

  return (
    <Box sx={principalContainerStyles}>
      <BasicHeader tab={<TabElement />}>
        <div className={classes.header}>
          <BusinessImage businessImage={photo} customStyles={businessImageStyles}/>
          <h3 className={classes.businessNameStyles}>{business.name}</h3>
        </div>
      </BasicHeader>
      <Box sx={{ width: "100%" }}>
        <TabPanel value={value} index={0}>
          <Box className={classes.root}>
            <BusinessDetailsCard
              id={id}
              name={business.name}
              description={business.description}
              adviserName={
                affiliatorInfo.name &&
                `${affiliatorInfo.name || ""} ${affiliatorInfo.lastName || ""}`
              }
              schedule={business.schedule}
              createdAt={business.createdAt}
              sellerName={
                business.sellerId &&
                `${business.sellerId.name} ${business.sellerId.last_name}`
              }
              image={photo}
              price={business.price}
            />
            <div>
              <BusinessLocation
                id={id}
                address={business.addressShop}
                addresCoords={business.businessCords}
              />
              {offers.length !== 0 && (
                <div className={classes.offersContainer}>
                  <Box sx={headerInfo}>
                    <PercentIcon
                      sx={{
                        color: "#EA5B13",
                        width: "24px",
                        height: "24px",
                        display: { xs: "none", md: "flex" },
                      }}
                    />
                    <h4 className={classes.titleInfo}>Ofertas</h4>
                  </Box>
                  {offers.map((offer) => (
                    <BusinessOfferCard
                      key={offer.id}
                      id={offer.id}
                      offerPrice={offer.offerprice}
                      realPrice={offer.price}
                      name={offer.product_name}
                      description={offer.description}
                      image={offer.url_image}
                      amount={offer.netcontent}
                    />
                  ))}
                </div>
              )}
              {businessRender && (
                <div className={classes.productsContainer}>
                  <BusinessProducts business={business} />
                </div>
              )}
            </div>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box className={classes.root}>
            <BusinessDetailsCard
              id={id}
              name={business.name}
              description={business.description}
              adviserName={
                affiliatorInfo.name &&
                `${affiliatorInfo.name} ${affiliatorInfo.lastName}`
              }
              schedule={business.schedule}
              createdAt={business.createdAt}
              sellerName={
                business.sellerId !== null &&
                `${business.sellerId.name} ${business.sellerId.last_name}`
              }
              price={business.price}
              image={business.urlImage ? photo : img}
            />
            <div>
              <BusinessPublicChart businessId={id} />
              <BusinessComments businessId={id} />
            </div>
          </Box>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <BusinessCommissionsTable
            businessName={business.name}
            businessId={id}
          />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <BusinessDocumentscrud
            businessName={business.name}
            businessId={id}
          />
        </TabPanel>
      </Box>
      <ModalAlert
        title={titleAlert}
        typeAlert={"error"}
        isOpen={isOpenAlert}
        handleAlert={handleClickOpen}
      />
    </Box>
  );
}
