import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import Card from "@mui/material/Card";
import { makeStyles } from "@mui/styles";
import img from "../assets/Background.png";
import { GetPhotoProfile } from "../User/Infrastructure/User.presenter";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 20,
    border: "1px solid #E5E5E5",
    borderRadius: "10px",
    //maxWidth: 301,
    minHeight: 104,
    height: "auto",
    width: "100%",
    color: "#333",
    "&:hover": {
      cursor: "pointer",
      background: "#E0E0E0",
    },
    [theme.breakpoints.down("md")]: {
      border: "none",
      padding: "5px 0px",
      minHeight: 100,
    },
  },
  productImage: {
    width: 60,
    height: 60,
    borderRadius: 10,
    objectFit: "cover",
  },
  infoContainer: {
    width: "100%",
    textAlign: "left",
  },
  productName: {
    fontSize: "1rem",
    fontWeight: 400,
    marginBottom: 10,
  },
  unit: {
    color: "#4F4F4F",
    fontSize: "0.875rem",
    fontWeight: 400,
  },
  price: {
    fontWeight: 500,
    fontSize: "1rem",
  }
}));

export default function ProductCard({ id, name, description, price, image }) {
  const classes = useStyles();
  const [photo, setPhoto] = useState({});

  const getUrlPhoto = async (urlPhoto) =>{
    const res = await GetPhotoProfile(urlPhoto);
    setPhoto(res);
  }

  useEffect(() => {
    if (image) {
      getUrlPhoto(image);
    }
  }, [image]);

  return (
    <Card id={id} className={classes.root} sx={{ boxShadow: "none" }}>
      <img className={classes.productImage} src={image ? photo : img} alt=""/>
      <div className={classes.infoContainer}>
        <h3 className={classes.productName}>
          {name}
        </h3>
        <p className={classes.unit} >{description === null ? "" : description}</p>
        <p className={classes.price} >${price}</p>
      </div>
    </Card>
  );
}

ProductCard.defaultProps = {
  image: null,
};

ProductCard.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  price: PropTypes.number.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string,
};
