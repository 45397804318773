import * as React from "react";
import Box from "@mui/material/Box";
import { useState } from "react";
import { updateShopDocs } from "../graphql/myMutations";
import { API, graphqlOperation } from "aws-amplify";
import IfoDocs from "./InfoDocs";

export default function DocshopList({
  data,
  viewDoc,
  downloadDoc,
  refreshFiles,
  docsComplete,
  idShop,
}) {
  const [chergeBtn, setCharge] = useState(false);
  const [ineDate, setValueINE] = React.useState(new Date());

  async function refreshDocsObj(item, newstatus) {
    setCharge(true);
    for (var i = 0; i < docsComplete.length; i++) {
      if (item.doc === docsComplete[i].doc) {
        docsComplete[i].status = newstatus;
        if (docsComplete[i].doc === "identificacion") {
          docsComplete[i].dateExpiration = ineDate.toISOString();
        }
      }
    }
    for (var x = 0; x < data.length; x++) {
      if (item.doc === data[x].doc) {
        data[x].status = newstatus;
      }
    }

    try {
      const send = {
        id: idShop,
        url_documents: JSON.stringify(docsComplete),
      };
      const info = await API.graphql(
        graphqlOperation(updateShopDocs, { input: send })
      );
      setCharge(false);
    } catch (e) {
      setCharge(false);
      console.log("ERROR", e);
    }
  }

  const ListShops = docsComplete.map((item) => {
    return (
      <IfoDocs
        document={item}
        display={viewDoc}
        updateFile={refreshFiles}
        validationDoc={refreshDocsObj}
        download={downloadDoc}
        charging={chergeBtn}
        ineDate={ineDate}
        setValueINE={setValueINE}
      />
    );
  });

  return docsComplete.length > 0 ? (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        padding: "10px",
      }}
    >
      {ListShops}
    </Box>
  ) : (
    <Box
      sx={{
        flexGrow: 1,
        width: "100%",
        padding: "10px",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
      }}
    >
      <h1>No hay documentos registrados.</h1>
    </Box>
  );
}
