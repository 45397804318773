import { useState } from 'react';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
import PickersDay from '@mui/lab/PickersDay';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';
import add from 'date-fns/add';

const theme = createTheme({
  palette: {
    primary:
      {
            main: "#004990",
            light: "#D8E3FF",
            dark: "#004990",
            contrastText: "#FFFFFF",
          },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: '#1F1F1F',
          borderRadius: "6px !important",
          border: "1px solid #F2F2F2"
        },
      },
    },
  },
});

const CustomPickersDay = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
      color: "#FFFFFF"
    },
  }),
  ...(isFirstDay && {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF"
  }),
  ...(isLastDay && {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF"
  }),
}));

export default function CustomStaticDatePicker({handleDateChange}) {
  const [value, setValue] = useState(new Date());

  const handleChange = (newValue) => {
    handleDateChange(newValue, add(new Date(newValue), {weeks: 1}));
    setValue(newValue);
  };

  const renderWeekPickerDay = (date, selectedDates, pickersDayProps) => {
    if (!value) {
      return <PickersDay {...pickersDayProps} />;
    }

    const end = add(new Date(value), {weeks: 1,})

    const dayIsBetween = isWithinInterval(date, { start: value, end });
    const isFirstDay = isSameDay(date, value);
    const isLastDay = isSameDay(date, end);

    return (
      <CustomPickersDay
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <StaticDatePicker
          disableFuture
          displayStaticWrapperAs="desktop"
          label="Week picker"
          value={value}
          onChange={handleChange}
          renderDay={renderWeekPickerDay}
          renderInput={(params) => <TextField {...params} />}
          inputFormat="'Week of' MMM d"
        />
      </LocalizationProvider>
    </ThemeProvider>
  );
}