import PropTypes from "prop-types";
import { useHistory } from "react-router";
import { makeStyles } from "@mui/styles";
import Button from "@mui/material/Button";
import { setBusinessPartnerModel } from "../BusinessPartner/Infrastructure/BusinessPartner.reducer";
import { setZoneModel } from "../Zones/Infrastructure/Zone.reducer";
import store from "../Redux/store";

const customStyles = {
  borderRadius: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-around",
};

const useStyles = makeStyles({
  root: {
    height: "78px",
    width: "537px",
  },
  rootLg: {
    height: "78px",
    width: "1050px",
  },
  textStyle: {
    fontWeight: 500,
    fontSize: "24px",
    color: "#333333",
    maxWidth: "253px",
    minWidth: "253px",
    textAlign: "left",
  },
  gainStyle: {
    fontWeight: 500,
    fontSize: "1.5rem",
    color: "#333333",
    textAlign: "right",
  },
  rectangle: ({ color }) => ({
    height: "17px",
    width: "17px",
    borderRadius: "1px",
    background: color,
  }),
});

export default function ButtonZone({
  type,
  id,
  zone,
  gain,
  partner,
  icon,
  color,
}) {
  const classes = useStyles({ color });
  const history = useHistory();

  const savePartner = () => {
    store.dispatch(setZoneModel({ zone_id: id, zone_name: zone }));
    if (partner !== null) {
      store.dispatch(setBusinessPartnerModel(partner));
      localStorage.setItem("partner", partner.id);
    }
    localStorage.setItem("zone", id);
    localStorage.setItem("zone_name", zone);
    history.push("/estados/zonas/negocios");
  };

  return (
    <Button
      sx={customStyles}
      variant="outlined"
      color="gray"
      type="button"
      className={type === "Editor" ? classes.rootLg : classes.root}
      startIcon={icon}
      onClick={savePartner}
    >
      <span className={classes.rectangle} />
      <p className={classes.textStyle}>{zone}</p>
      <p className={classes.gainStyle}>{type === "Editor" ? "" : "$" + gain}</p>
    </Button>
  );
}

ButtonZone.defaultProps = {
  icon: null,
  partner: {
    birthday: "dd/mm/aaaa",
    email: "email@example.com.mx",
    id: "ba8d3745-76ee-44a0-bdf2-6dc17a2ae944",
    last_name: "Apellido",
    name: "Nombre",
  },
};

ButtonZone.propTypes = {
  id: PropTypes.string.isRequired,
  partner: PropTypes.object,
  icon: PropTypes.element,
  zone: PropTypes.string.isRequired,
  gain: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired,
};
