import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import ExportButton from "../../DesignSystem/ExportButton";
import StatementAccountTable from './StatementAccountTable';
import StatementTableTex from './StatementTableText';
import { useStyles, statementContainer, statementSummaryContainer, statementSummaryHeader, stateAccountFooterInfo, dividerStyles, plusIconStyles, textIconStyles, minusIconStyles, statementTableContainer, stateAccountFooterContainer } from "./styles/StatementAccount.styles";
import StatementAccountChart from "./StatementAccountChart.ui";

const PlusIcon = () => <span style={{...textIconStyles, ...plusIconStyles}}> (+) </span>
const MinusIcon = () => <span style={{...textIconStyles, ...minusIconStyles}}> (-) </span>
const EqualIcon = () => <span style={textIconStyles}> = </span>
const CustomDivider = () => <Divider sx={dividerStyles}/>

export default function StatementAccountUI({zoneName}){
  const { statementSummaryHeaderTitle, statementSummaryHeaderSubtitle } = useStyles();

  return(
    <Box sx={statementContainer}>
        <ExportButton onClick={()=> console.log("exportando datos desde estado de cuenta")}/> 
        <Box sx={{width: "100%", height: "393px", background: "#FFFFFF"}}>
          <StatementAccountChart/>
        </Box>
        <Box sx={statementSummaryContainer}>
          <Box sx={statementSummaryHeader}>
            <h4 className={statementSummaryHeaderTitle}>Resumen de estado de cuenta</h4>
            <p className={statementSummaryHeaderSubtitle}>Periodo 00 Enero - 00 Febrero (30 días)</p>
          </Box>
          <Box sx={statementTableContainer}>
            <StatementTableTex text="Saldo anterior" amount="00"/>
            <CustomDivider/>
            <StatementTableTex icon={<PlusIcon/>} text="Abonos" amount="00"/>
            <CustomDivider/>
            <StatementTableTex icon={<MinusIcon/>} text="Cargos" amount="00"/>
            <CustomDivider/>
            <StatementTableTex text="Comisiones" amount="00"/>
            <CustomDivider/>
            <StatementTableTex icon={<EqualIcon/>} text="Saldo final" amount="00"/>
            <CustomDivider/>
            <StatementTableTex text="IVA" amount="00"/>
            <CustomDivider/>
            <StatementTableTex text="Saldo total del periodo" amount="00"/>
            <Box sx={stateAccountFooterContainer}>
              <p style={stateAccountFooterInfo}>Nombre franquicia S.A de C.V México</p>
              <p style={stateAccountFooterInfo}>Dirección, calle, 00. Teléfono 000-00-00. RFC</p>
            </Box>
          </Box>
        </Box>
        <StatementAccountTable zoneName={zoneName}/>
      </Box>
  );
}