import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../DesignSystem/styles/fileviewerCss.css";
import { IconButton } from "@mui/material";
import ImageZoom from "./ImageZoom";
import { btnExitStyles } from "./styles/ModalProfile.styles";
import CloseIcon from "@mui/icons-material/Close";
import { Worker } from "@react-pdf-viewer/core";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import "@react-pdf-viewer/toolbar/lib/styles/index.css";
import { ProgressBar } from "@react-pdf-viewer/core";
import { toolbarPlugin } from "@react-pdf-viewer/toolbar";
import { SelectionMode } from "@react-pdf-viewer/selection-mode";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  borderRadius: "10px",
  bgcolor: "background.paper",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

export default function FileViewerModal({ visibility, url, type, nameFile }) {
  const [open, setOpen] = useState(visibility);
  const toolbarPluginInstance = toolbarPlugin({
    searchPlugin: {
      keyword: "PDF",
    },
    selectionModePlugin: {
      selectionMode: SelectionMode.Hand,
    },
  });
  const { Toolbar } = toolbarPluginInstance;
  useEffect(() => {
    setOpen(visibility);
  }, [visibility]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style, width: "90%", height: "90%" }}>
          <div
            style={{
              width: "95%",
              height: "15%",
              flexDirection: "row",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#F5F8FF",
              marginBottom: "2%",
              borderRadius: "10px",
            }}
          >
            <div
              style={{
                width: "70%",

                display: "flex",
                padding: "1%",
                flexDirection: "column",
              }}
            >
              <p>{nameFile.toUpperCase()}</p>
            </div>
            <div
              style={{
                width: "30%",
                height: "10%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <IconButton sx={btnExitStyles} onClick={handleClose}>
                <CloseIcon />
              </IconButton>
            </div>
          </div>

          {type === "pdf" ? (
            <div
              style={{
                height: "70%",
                justifyContent: "center",
              }}
            >
              <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.1.81/build/pdf.worker.min.js">
                <div
                  style={{
                    border: "1px solid rgba(0, 0, 0, 0.3)",
                    height: "110%",
                  }}
                >
                  <Toolbar />
                  <Viewer
                    theme="auto"
                    fileUrl={url}
                    plugins={[toolbarPluginInstance]}
                    renderLoader={(percentages: number) => (
                      <div style={{ width: "240px" }}>
                        <ProgressBar progress={Math.round(percentages)} />
                      </div>
                    )}
                  />
                </div>
              </Worker>
            </div>
          ) : (
            <div
              style={{
                height: "80%",
                marginTop: "10px",
                marginBottom: "0",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <ImageZoom path={url} />
            </div>
          )}
        </Box>
      </Modal>
    </React.Fragment>
  );
}
