import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "zone",

  initialState: {
    zoneModel: null,
  },

  reducers: {
    setZoneModel: (state, { payload: zoneModel }) => ({
      ...state,
      zoneModel,
    }),
  },
});

export const { setZoneModel } = slice.actions;

export default slice.reducer;

export const getZoneModel = (store) => store.zone.zoneModel;