import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

import { addWeeks, addDays, addYears, add, sub } from "date-fns";
import esLocale from "date-fns/locale/es";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import DateRangePicker from "@mui/lab/DateRangePicker";
import { createTheme, ThemeProvider } from "@mui/material/styles";

import { styled } from '@mui/material/styles';
import PickersDay from '@mui/lab/PickersDay';
import isSameDay from 'date-fns/isSameDay';
import isWithinInterval from 'date-fns/isWithinInterval';

const dateTheme = createTheme({
  palette: {
    primary: {
      main: "#004990",
      light: "#D8E3FF",
      dark: "#004990",
      contrastText: "#FFFFFF",
    },
  },
  components: {
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: "#1F1F1F",
          borderRadius: "6px !important",
          border: "1px solid #F2F2F2",
        },
      },
    },
    MuiPaper:{
      styleOverrides: {
        root: {
          backgroundColor: "#F5F5F5",
          borderRadius: "6px !important",
        },
      },
    }
  },
  typography: {
    h1: {
      fontSize: "1rem",
      fontWeight: "500",
      fontFamily: "Montserrat",
    },
    subtitle2: {
      fontWeight: 500,
      fontSize: "0.875rem",
      fontFamily: "Montserrat",
    },
  },
});

const textFieldStyles = {
  minWidth: "130px",
  maxWidth: "170px",
  height: "32px",
  "& .MuiFilledInput-root": {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
  },
  "& .MuiFilledInput-root:before": {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
  },
};

const inputStyles = {
  height: "32px",
  minWidth: "130px",
  maxWidth: "170px",
  border: "none",
  alignItems: "flex-start",
  "& .MuiInputBase-input": {
    fontFamily: "Montserrat",
    fontSize: "0.875rem",
    border: "none",
    maxHeight: "32px",
    minHeight: "32px",
    textAlign: "center",
  },
};


const useStyles = makeStyles((theme) => ({
  icon: { fill: "#EA5B13" },
  dateContainer: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #E0E0E0",
    justifyContent: "center",
    alignContent: "center",
    borderRadius: "5px",
    minWidth: "300px",
    maxWidth: "340px",
    maxHeight: "32px",
    minHeight: "32px",
  },
}));

const CustomRangePicker = styled(PickersDay, {
  shouldForwardProp: (prop) =>
    prop !== 'dayIsBetween' && prop !== 'isFirstDay' && prop !== 'isLastDay',
})(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.main,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.primary.dark,
      color: "#FFFFFF"
    },
  }),
  ...(isFirstDay && {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF"
  }),
  ...(isLastDay && {
    backgroundColor: theme.palette.primary.main,
    color: "#FFFFFF"
  }),
}));

export default function RangePicker({
  widthDate,
  initDay,
  typeDateRange,
  handleDate,
}) {
  const classes = useStyles({ widthDate });
  const day = new Date();
  const [value, setValue] = useState([initDay, day]);

  function getDaysAfter(date) {
    if (typeDateRange === "Mensual") {
      return date ? add(date, { months: 1 }) : undefined;
    } else if (typeDateRange === "Semanal") {
      return date ? addWeeks(date, 1) : undefined;
    } else if (typeDateRange === "") {
      return date ? addYears(date, 1) : undefined;
    } else if (typeDateRange === "Día") {
      return date ? addDays(date, 1) : undefined;
    }
  }

  const handleDayPlus = () => {
    let date1;
    let date2;

    if (typeDateRange === "Mensual") {
      date1 = add(value[0], { months: 1 });
      date2 = add(value[1], { months: 1 });
    } else if (typeDateRange === "Semanal") {
      date1 = add(value[0], { weeks: 1 });
      date2 = add(value[1], { weeks: 1 });
    } else if (typeDateRange === "Anual") {
      date1 = add(value[0], { years: 1 });
      date2 = add(value[1], { years: 1 });
    } else if (typeDateRange === "Día") {
      date1 = add(value[0], { days: 1 });
      date2 = add(value[1], { days: 1 });
    }

    setValue([date1, date2]);
    handleDate([date1, date2]);
  };

  const handleDayLess = () => {
    let date1;
    let date2;

    if (typeDateRange === "Mensual") {
      date1 = sub(value[0], { months: 1 });
      date2 = sub(value[1], { months: 1 });
    } else if (typeDateRange === "Semanal") {
      date1 = sub(value[0], { weeks: 1 });
      date2 = sub(value[1], { weeks: 1 });
    } else if (typeDateRange === "Anual") {
      date1 = sub(value[0], { years: 1 });
      date2 = sub(value[1], { years: 1 });
    } else if (typeDateRange === "Día") {
      date1 = sub(value[0], { days: 1 });
      date2 = sub(value[1], { days: 1 });
    }

    setValue([date1, date2]);
    handleDate([date1, date2]);
  };

  const renderWeekPickerDay = (date, pickersDayProps) => {
    if (!value[0] || !value[1]) {
      return <PickersDay {...pickersDayProps} />;
    }
    const dayIsBetween = isWithinInterval(date, { start: value[0], end: value[1] });
    const isFirstDay = isSameDay(date, value[0]);
    const isLastDay = isSameDay(date, value[1]);

    return (
      <CustomRangePicker
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    );
  };

  return (
    <Box
      sx={{
        //marginLeft: "30px",
        minWidth: "340px",
        maxWidth: "400px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <IconButton onClick={handleDayLess}>
        <ArrowBackIosIcon color="secondary" fontSize="small" />
      </IconButton>

      <div className={classes.dateContainer}>
        <ThemeProvider theme={dateTheme}>
          <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
            <DateRangePicker
              value={value}
              startText=""
              endText=""
              inputFormat="dd MMMM, yyyy"
              toolbarTitle="Selecciona un fecha"
              disableMaskedInput
              //disableFuture
              maxDate={getDaysAfter(value[0])}
              onChange={(newValue) => {
                console.log("🚀 ~ file: RangePicker.jsx ~ line 246 ~ newValue", newValue)
                setValue(newValue);
                handleDate(newValue);
              }}
              disableCloseOnSelect={true}
              renderDay={renderWeekPickerDay}
              renderInput={(startProps, endProps) => (
                <React.Fragment>
                  <TextField
                    size="small"
                    variant="standard"
                    sx={textFieldStyles}
                    {...startProps}
                    InputProps={{
                      sx: inputStyles,
                      disableUnderline: true,
                    }}
                  />
                  <p>-</p>
                  <TextField
                    size="small"
                    variant="standard"
                    sx={textFieldStyles}
                    {...endProps}
                    InputProps={{
                      sx: inputStyles,
                      disableUnderline: true,
                    }}
                  />
                </React.Fragment>
              )}
            />
          </LocalizationProvider>
        </ThemeProvider>
      </div>

      <IconButton onClick={handleDayPlus}>
        <ArrowForwardIosIcon color="secondary" fontSize="small" />
      </IconButton>
    </Box>
  );
}

RangePicker.defaultProps = {
  widthDate: "200px",
};

RangePicker.propTypes = {
  widthDate: PropTypes.string,
};
