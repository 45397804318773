import { GetListZonesService, GetCountriesListService, GetProfitByZoneService }  from "../Infrastructure/Zone.service";

export async function GetListZonesLogic(country){
  return GetListZonesService(country);
}

export async function GetListCountriesLogic(){
  return GetCountriesListService();
}

export function GetProfitByZoneLogic(info){
  return GetProfitByZoneService(info);
}