import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { Login } from "../Infrastructure/Auth.presenter";
import { GetUserInfo } from "../../User/Infrastructure/User.presenter";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import InputOutlined from "../../DesignSystem/InputOutlined";
import FlatButton from "../../DesignSystem/FlatButton";
import backImage from "../../assets/Background.png";
import logo from "../../assets/Logo.svg";
import ModalAlert from "../../DesignSystem/ModalAlert";
import { useStyles } from "./styles/Login.styles";
import { container, outlineBtn, fieldBtn } from "./styles/Login.styles";
import UseContext from "../../Auth/Context/useContext";
export default function LoginUI() {
  const classes = useStyles();
  const history = useHistory();
  const [values, setValues] = useState({ email: "", password: "" });
  const [sending, setSending] = useState(false);
  const [isOpenAlert, setOpenAlert] = useState(false);
  const [titleAlert, setTitleAlert] = useState("Ha ocurrido un error");
  const showAlertModal = () => setOpenAlert(true);

  const handleClickOpen = (alertState) => {
    setOpenAlert(alertState);
  };

  const handleInputChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSending(true);
      const { valid, info } = await Login(values.email, values.password);
      if (valid) {
        try {
          const currentUser = await GetUserInfo(info.username);
          if (currentUser.role === "admin" || currentUser.role === "editor") {
            setSending(false);
            history.push("/estados");
          } else {
            setTitleAlert("Acceso denegado");
            showAlertModal();
            setSending(false);
          }
        } catch (error) {
          setTitleAlert("Error al obtener su información");
          showAlertModal();
          setSending(false);
        }
      } else {
        setSending(false);
        setTitleAlert("Usuario o contraseña incorrectos");
        showAlertModal();
      }
    } catch (error) {
      setTitleAlert(error);
      showAlertModal();
      setSending(false);
    }
  };

  return (
    <Box sx={container}>
      <div className={classes.blueContainer}>
        <img
          className={classes.backImage}
          src={backImage}
          alt="BackgroundImage"
        />
        <img className={classes.logo} src={logo} alt="logoImg" />
      </div>
      <div className={classes.rightContainer}>
        <form className={classes.formStyles}>
          <h2 className={classes.title}>¡Bienvenido!</h2>
          <InputOutlined
            labelTitle="Correo"
            placeholderText="email@example.com"
            value={values.email}
            name="email"
            onChange={handleInputChange}
          />
          <InputOutlined
            labelTitle="Contraseña"
            placeholderText="Escribe tu contraseña"
            value={values.password}
            name="password"
            onChange={handleInputChange}
            isPassword
          />

          <Typography variant="subtitle2" style={{ marginTop: "21px" }}>
            <Link to="/forgot" className={classes.link}>
              ¿Olvidaste tu contraseña?
            </Link>
          </Typography>

          <FlatButton
            typeButton="submit"
            title={sending ? "Ingresando..." : "Ingresar tec"}
            widthBtn={"100%"}
            isDisable={sending}
            onClick={handleSubmit}
            customStyles={fieldBtn}
          />

          <FlatButton
            typeButton="submit"
            title={sending ? "Ingresando..." : "Ingresar administrador"}
            widthBtn={"100%"}
            isDisable={sending}
            onClick={handleSubmit}
            variant="outlined"
            customStyles={outlineBtn}
          />
        </form>
      </div>
      <ModalAlert
        title={titleAlert}
        typeAlert={"error"}
        isOpen={isOpenAlert}
        handleAlert={handleClickOpen}
      />
    </Box>
  );
}
