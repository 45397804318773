import { makeStyles } from "@mui/styles";

export const statementTableContainer = {
  padding: "19px 72px 4px"
}

export const statementContainer = {
  position: "relative",
  display: "grid",
  gridTemplateColumns: "1fr 1fr",
  gap: "17px",
  marginBottom: "14px"
}

export const statementSummaryContainer = {
  width: "100%",
  minHeight: "393px",
  height: "auto",
  background: "#FFFFFF"
}

export const statementSummaryHeader = {
  background: "#004990",
  padding: "8px 43px",
  boxShadow: "0px 4px 4px rgba(219, 219, 219, 0.25)",
  borderRadius: "5px 5px 0px 0px"
}

export const stateAccountFooterContainer = {
  display: "flex",
  justifyContent: "space-between",
  gap: "15px",
  alignItems: "center",
  marginTop: "19px"
}

export const stateAccountFooterInfo = {
  fontWeight: 400,
  fontSize: "0.8125rem",
  lineHeight: "16px",
  color: "#4F4F4F"
}

export const dividerStyles = {
  marginTop: "11px",
  marginBottom: "9px",
  background: "#F2F2F2",
  border: "1px solid #F2F2F2"
}

export const plusIconStyles = {
  color: "#6CB22E"
}

export const minusIconStyles = {
  color: "#D70000"
}

export const textIconStyles = {
  display: "inline-block",
  marginRight: "4px"
}

const useStyles = makeStyles({
  statementSummaryHeaderTitle:{
    fontWeight: 500,
    color: "#FFFFFF",
    fontSize: "1.125rem",
    lineHeight: "22px"
  },
  statementSummaryHeaderSubtitle:{
    fontWeight: 400,
    color: "#FFFFFF",
    fontSize: "0.875rem",
    lineHeight: "18px"
  }
});

export {useStyles};