import { useEffect, useState } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import getAffiliationsInfo from "../Infrastructure/Reports.presenter";
import ExportButton from "../../DesignSystem/ExportButton";
import CustomSwitch from "../../DesignSystem/CustomSwitch";
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';
import {affiliationsContainer, columnStyle, columnIndexStyle, columnDateStyle, textStyle, success, error, columnExcelStyles} from "./styles/AffiliationsTable.styles";

export default function AffiliationsTable({ zone, betweenDates,zoneName }) {
  const [affiliations, setAffiliations] = useState([]);

  useEffect(() => {
    if ((zone, betweenDates)) {
      fetchAffiliations(zone, betweenDates);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zone, betweenDates]);

  async function fetchAffiliations(currentZone, dates) {
    try {
      const info = await getAffiliationsInfo(zone, dates);
      setAffiliations(info);
    } catch (error) {
      console.log(error.message);
    }
  }

  const handleSwitchChange  = async () =>{
    fetchAffiliations(zone, betweenDates)
  }

  const handleExport = async () => {
    let rows = [];
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Afiliaciones');

    worksheet.columns = [
      { header: 'No.', key: 'numero', width: 20, style: columnExcelStyles},
      { header: 'Fecha', key: 'fecha', width: 30,style: columnExcelStyles},
      { header: 'Nombre de la tienda', key: 'tienda', width: 35,style: columnExcelStyles},
      { header: 'Asesor', key: 'asesor', width: 40,style: columnExcelStyles},
      { header: 'Estado', key: 'estado', width: 25,style: columnExcelStyles},
    ];  

    affiliations.forEach((element, index) => {
      const sellerName = element.seller_id ? `${element.seller_id.name} ${element.seller_id.last_name}` : "Sin registro";
      const formatedDate = element.createdAt ? moment(element.createdAt).format("DD/MM/YYYY") : "Sin fecha";
      rows.push({numero: (index +1), fecha: formatedDate, tienda: element.shop_name, asesor: sellerName, estado: element.status});
    });

    worksheet.addRows(rows);

    workbook.xlsx.writeBuffer().then(function(buffer) {
      const regex = /\s/g
      const formatedZoneName = zoneName.replace(regex, "_");
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `afiliaciaciones_${formatedZoneName || ""}.xlsx`
      );
    });
  }

  return (
    <Box sx={affiliationsContainer}>
      {affiliations.length !== 0 && <ExportButton onClick={handleExport}/>}
      <Paper sx={affiliations.length !== 0 ? success : error}>
        {affiliations.length !== 0 ? (
          <TableContainer sx={{ minHeight: 391 }}>
            <Table stickyHeader aria-label="sticky table" sx={{ fontFamily: "Montserrat" }}>
              <TableHead sx={{ height: "62px" }}>
                <TableRow>
                  <TableCell key={"id"} align={"left"} sx={columnIndexStyle}>No.</TableCell>
                  <TableCell key={"date"} align={"left"} sx={columnDateStyle}>Fecha</TableCell>
                  <TableCell key={"name"} align={"left"} sx={columnStyle}>Nombre de la tienda</TableCell>
                  <TableCell key={"adviser"} align={"center"} sx={columnStyle}>Asesor</TableCell>
                  <TableCell key={"status"} align={"center"} sx={columnStyle}>Estado</TableCell>
                  <TableCell key={"view"} align={"center"} sx={columnStyle}>Visualización</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {affiliations &&
                  affiliations.map((row, index) => {
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id}>
                        <TableCell key={"id"} align={"center"} sx={textStyle}>
                          {index + 1}
                        </TableCell>

                        <TableCell key={"date"} align={"left"} sx={textStyle}>
                          {moment(row.createdAt).format("DD/MM/YYYY")}
                        </TableCell>

                        <TableCell key={"name"} align={"left"} sx={textStyle}>
                          {row.shop_name}
                        </TableCell>

                        <TableCell key={"adviser"} align={"center"} sx={textStyle}>
                          {row.seller_id !== null
                            ? row.seller_id.name
                            : "Sin registro"}{" "}
                          {row.seller_id ? row.seller_id.last_name : ""}
                        </TableCell>

                        <TableCell
                          key={"status"}
                          align={"center"}
                          sx={{
                            fontWeight: 500,
                            fontSize: "1.125rem",
                            fontFamily: "Montserrat",
                            color:
                              row.status === "Completo" ? "#59AF31" : "#9C0707",
                          }}
                        >
                          {row.status}
                        </TableCell>
                        <TableCell
                          key="view"
                          align="center"
                        >
                          <CustomSwitch shopID={row.id} isComplete={row.status} handleSwitchChange={handleSwitchChange}/>
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <h2>Sin registros en estas fechas</h2>
        )}
      </Paper>
    </Box>
  );
}