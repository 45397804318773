import React from "react";
import { makeStyles } from "@mui/styles";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import TuneOutlinedIcon from "@mui/icons-material/TuneOutlined";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioGroup from "@mui/material/RadioGroup";
import Radio from "@mui/material/Radio";

const orderOptions = [
  "Orden alfabético a-z",
  "Orden alfabético z-a",
  "Registros antiguos - a +",
  "Registros recientes + a -",
];

const useStyles = makeStyles((theme) => ({
  order: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    /* [theme.breakpoints.down("md")]:{
      justifyContent: "space-between",
    }, */
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
}));

export default function RadioOrderGroup({ typeOrder, handleValue }) {
  const typeOrderOp = () =>
    order === undefined ? "Orden alfabético a-z" : order;
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [order, setOrder] = React.useState(typeOrder);
  const [value, setValue] = React.useState(typeOrderOp);
  const open = Boolean(anchorEl);

  const handleOrderClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (e) => {
    setOrder(e.target.name);
    setAnchorEl(null);
    handleValue(e.target.name);
  };

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <div className={classes.order}>
      <p>
        Ordenado por: <b>{typeOrderOp()}</b>
      </p>
      <IconButton
        aria-label="Order"
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleOrderClick}
      >
        <TuneOutlinedIcon color="#000" />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 60 * 5,
            width: "25ch",
            borderRadius: 10,
          },
        }}
      >
        <RadioGroup
          aria-labelledby="radio-buttons-order-group"
          name="controlled-radio-buttons-order-group"
          value={value}
          onChange={handleChange}
        >
          {orderOptions.map((option) => (
            <MenuItem key={option}>
              <FormControlLabel
                name={option}
                value={option}
                control={<Radio color="secondary" />}
                label={option}
                onClick={handleClose}
              />
            </MenuItem>
          ))}
        </RadioGroup>
      </Menu>
    </div>
  );
}

RadioOrderGroup.defaultProps = {};

RadioOrderGroup.propTypes = {
  typeOrder: PropTypes.string.isRequired,
};
