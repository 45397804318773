import { makeStyles } from "@mui/styles";

export const boxContainerStyles = {
  background: "white",
  width: "100%",
  //width: { xs: "100%", sm: "100%", md: "90%" },
  height: "auto",
  //maxWidth: "1110px",
  margin: { sm: "0px", md: "auto" },
  minHeight: "913px",
  padding: { xs: "60px 5px", sm: "40px 20px", md: "36px 40px 59px" },
  borderRadius: "10px"
};

export const searchFormControl = {
  display: "flex",
  justifyContent: "center",
  gap: "10px",
  alignItems: { xs: "center", md: "baseline" },
  flexDirection: { xs: "column", md: "row" },
};

export const textContainer = {
  display: "flex",
  justifyContent: "space-between", 
  alignItems: "end"
}


const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 20,
    [theme.breakpoints.down("lg")]: {
      alignItems: "center",
      flexDirection: "row",
      display: "flex",
    },
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "stretch",
    },
  },
  title: {
    lineHeight: "58.09px",
    fontSize: "3rem",
    color: "#333333",
  },
  totals: {
    lineHeight: "25.09px",
    fontSize: "1rem",
    color: "#333333",
  },
  searchContainer: {
    padding: "20px 0px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    [theme.breakpoints.down("lg")]: {
      alignItems: "stretch",
      flexDirection: "column",
    },
  },
  inputStyles: {
    border: "1px solid #E0E0E0",
    borderRadius: 10,
    height: 35,
    padding: "0px 10px",
    fontWeight: 500,
  },
  userContainer: {
    marginTop: 20,
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: "20px 60px",
      justifyItems: "center",
    },
  },
  order: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    [theme.breakpoints.down("lg")]: {
      justifyContent: "flex-start",
    },
    [theme.breakpoints.down("md")]: {
      justifyContent: "space-between",
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "space-between",
  },
  buttonAdd: {
    [theme.breakpoints.up("md")]: {
      width: 213,
    },
  },
  zoneBusinessText:{
    //font-family: Inter;
    fontSize: "3rem",
    fontWeight: 700,
    lineHeight: "58px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  userInfo:{
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "29px",
    letterSpacing: "0em",
    textAlign: "right",
    color: "#828282"
  }
}));

export {useStyles};