/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const accountsByRole = /* GraphQL */ `
  query AccountsByRole(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelaccountFilterInput
    $limit: Int
    $nextToken: String
    $role: String
    $sortDirection: ModelSortDirection
  ) {
    accountsByRole(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      role: $role
      sortDirection: $sortDirection
    ) {
      items {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const campaignsByCreationDate = /* GraphQL */ `
  query CampaignsByCreationDate(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelcampaignFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $sort_attribute: Int
  ) {
    campaignsByCreationDate(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      sort_attribute: $sort_attribute
    ) {
      items {
        business_list {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        category_id {
          campaigns {
            nextToken
          }
          category_name
          category_shop {
            nextToken
          }
          category_user {
            nextToken
          }
          createdAt
          id
          subcategories
          tags
          updatedAt
        }
        clicks
        createdAt
        description
        end_age
        end_campaign
        end_hour
        extra_notification
        gender
        id
        id_marketer {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        interest
        offer_type
        repeat
        repeat_time
        scope
        shopping
        sort_attribute
        start_age
        start_campaign
        start_hour
        status
        subcategory
        title
        type
        updatedAt
        url_image
        zone_list
      }
      nextToken
    }
  }
`;
export const campaignsByStartCampaign = /* GraphQL */ `
  query CampaignsByStartCampaign(
    $filter: ModelactiveCampaignsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $sort_attribute: Int
    $start_campaign: ModelStringKeyConditionInput
  ) {
    campaignsByStartCampaign(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      sort_attribute: $sort_attribute
      start_campaign: $start_campaign
    ) {
      items {
        TTLCampaign
        createdAt
        description
        end_age
        end_campaign
        gender
        id
        interest
        repeat
        repeat_time
        sort_attribute
        start_age
        start_campaign
        status
        title
        type
        updatedAt
        url_image
        zone_list
      }
      nextToken
    }
  }
`;
export const getAccount = /* GraphQL */ `
  query GetAccount($id: ID!) {
    getAccount(id: $id) {
      delivery_transport
      availability
      birthday
      campaign {
        items {
          business_list {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          category_id {
            category_name
            createdAt
            id
            subcategories
            tags
            updatedAt
          }
          clicks
          createdAt
          description
          end_age
          end_campaign
          end_hour
          extra_notification
          gender
          id
          id_marketer {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          interest
          offer_type
          repeat
          repeat_time
          scope
          shopping
          sort_attribute
          start_age
          start_campaign
          start_hour
          status
          subcategory
          title
          type
          updatedAt
          url_image
          zone_list
        }
        nextToken
      }
      categoryUser {
        items {
          category_id {
            category_name
            createdAt
            id
            subcategories
            tags
            updatedAt
          }
          createdAt
          id
          updatedAt
          user_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
        }
        nextToken
      }
      chatsUser {
        items {
          chat_id {
            active
            createdAt
            id
            new_message
            updatedAt
          }
          createdAt
          id
          updatedAt
          user_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
        }
        nextToken
      }
      createdAt
      current_session
      customer_orders {
        items {
          address
          bill_id {
            createdAt
            description
            id
            status
            total
            updatedAt
          }
          canceled
          collects {
            nextToken
          }
          com_customer
          com_shop
          createdAt
          customer_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          delivery_man_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          discount
          discount_array {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          final_total
          history
          id
          level
          method_pay
          nc
          orderDetails
          order_case
          order_type
          payment_id
          percentage
          phase
          products {
            nextToken
          }
          propina
          require_bill
          require_service
          reviews {
            nextToken
          }
          shop_comments
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          shop_idToSort
          status
          total
          tracking_coordinates
          updatedAt
        }
        nextToken
      }
      deleted_at
      delivery_inprocess
      deliveryman_collects {
        items {
          come_date
          createdAt
          deliveryman_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          finished
          id
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          origin_sign
          picking_date
          sign
          type
          updatedAt
        }
        nextToken
      }
      deliveryman_orders {
        items {
          address
          bill_id {
            createdAt
            description
            id
            status
            total
            updatedAt
          }
          canceled
          collects {
            nextToken
          }
          com_customer
          com_shop
          createdAt
          customer_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          delivery_man_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          discount
          discount_array {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          final_total
          history
          id
          level
          method_pay
          nc
          orderDetails
          order_case
          order_type
          payment_id
          percentage
          phase
          products {
            nextToken
          }
          propina
          require_bill
          require_service
          reviews {
            nextToken
          }
          shop_comments
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          shop_idToSort
          status
          total
          tracking_coordinates
          updatedAt
        }
        nextToken
      }
      email
      favorites {
        items {
          createdAt
          id
          product_id {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          user_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
        }
        nextToken
      }
      favorites_shops
      id
      interests
      last_name
      messages {
        items {
          chat_id
          content
          createdAt
          expiration_time
          id
          image_url
          updatedAt
          user_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
        }
        nextToken
      }
      name
      nikucoins
      notification_token
      openPay_id
      payments_vehicle {
        items {
          createdAt
          date
          deliveryman_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          id
          method_pay
          pay
          status
          updatedAt
        }
        nextToken
      }
      pending_chats
      phone_number
      rfc
      role
      sended_reviews {
        items {
          comment
          createdAt
          evaluator_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          id
          note
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          reviewEvaluated_idId
          updatedAt
        }
        nextToken
      }
      sexo
      shops {
        items {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        nextToken
      }
      updatedAt
      url_photoProfile
      user_addresses {
        items {
          active
          address_id {
            city
            country
            createdAt
            id
            interior_number
            latitude
            longitude
            outdoor_number
            postal_code
            street
            town
            updatedAt
          }
          createdAt
          id
          tag
          updatedAt
          user_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          zip_codes
        }
        nextToken
      }
      vehicles {
        items {
          active
          createdAt
          delivery_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          id
          mark
          model
          niv
          price
          status
          temporal
          type_vehicle
          updatedAt
          zone_id {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
        }
        nextToken
      }
      zip_codes
      zone
      zones {
        items {
          active
          bases {
            nextToken
          }
          createdAt
          deliverymen_inZone
          franchisse_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          id
          updatedAt
          vehicles {
            nextToken
          }
          zone_name
          zone_state
        }
        nextToken
      }
    }
  }
`;
export const getActiveCampaigns = /* GraphQL */ `
  query GetActiveCampaigns($id: ID!) {
    getActiveCampaigns(id: $id) {
      TTLCampaign
      createdAt
      description
      end_age
      end_campaign
      gender
      id
      interest
      repeat
      repeat_time
      sort_attribute
      start_age
      start_campaign
      status
      title
      type
      updatedAt
      url_image
      zone_list
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      city
      country
      createdAt
      id
      interior_number
      latitude
      longitude
      outdoor_number
      postal_code
      street
      town
      updatedAt
      user_addresses {
        items {
          active
          address_id {
            city
            country
            createdAt
            id
            interior_number
            latitude
            longitude
            outdoor_number
            postal_code
            street
            town
            updatedAt
          }
          createdAt
          id
          tag
          updatedAt
          user_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          zip_codes
        }
        nextToken
      }
    }
  }
`;
export const getBase = /* GraphQL */ `
  query GetBase($id: ID!) {
    getBase(id: $id) {
      active
      address
      country
      createdAt
      description
      id
      updatedAt
      zone_id {
        active
        bases {
          items {
            active
            address
            country
            createdAt
            description
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        deliverymen_inZone
        franchisse_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        id
        updatedAt
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zone_name
        zone_state
      }
    }
  }
`;
export const getBill = /* GraphQL */ `
  query GetBill($id: ID!) {
    getBill(id: $id) {
      createdAt
      description
      id
      order_id {
        address
        bill_id {
          createdAt
          description
          id
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          status
          total
          updatedAt
        }
        canceled
        collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        com_customer
        com_shop
        createdAt
        customer_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        delivery_man_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        discount
        discount_array {
          createdAt
          for_use
          id
          orders {
            nextToken
          }
          percentage_id {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          used
          value
        }
        final_total
        history
        id
        level
        method_pay
        nc
        orderDetails
        order_case
        order_type
        payment_id
        percentage
        phase
        products {
          items {
            additional_features
            comments
            createdAt
            id
            price
            quantity
            quote_state
            updatedAt
            url_audio
            url_image
          }
          nextToken
        }
        propina
        require_bill
        require_service
        reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        shop_comments
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        shop_idToSort
        status
        total
        tracking_coordinates
        updatedAt
      }
      status
      total
      updatedAt
    }
  }
`;
export const getCampaign = /* GraphQL */ `
  query GetCampaign($id: ID!) {
    getCampaign(id: $id) {
      business_list {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      category_id {
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category_name
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        category_user {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        id
        subcategories
        tags
        updatedAt
      }
      clicks
      createdAt
      description
      end_age
      end_campaign
      end_hour
      extra_notification
      gender
      id
      id_marketer {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      interest
      offer_type
      repeat
      repeat_time
      scope
      shopping
      sort_attribute
      start_age
      start_campaign
      start_hour
      status
      subcategory
      title
      type
      updatedAt
      url_image
      zone_list
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      campaigns {
        items {
          business_list {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          category_id {
            category_name
            createdAt
            id
            subcategories
            tags
            updatedAt
          }
          clicks
          createdAt
          description
          end_age
          end_campaign
          end_hour
          extra_notification
          gender
          id
          id_marketer {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          interest
          offer_type
          repeat
          repeat_time
          scope
          shopping
          sort_attribute
          start_age
          start_campaign
          start_hour
          status
          subcategory
          title
          type
          updatedAt
          url_image
          zone_list
        }
        nextToken
      }
      category_name
      category_shop {
        items {
          aisles
          category_id {
            category_name
            createdAt
            id
            subcategories
            tags
            updatedAt
          }
          createdAt
          id
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          subcategory
          updatedAt
        }
        nextToken
      }
      category_user {
        items {
          category_id {
            category_name
            createdAt
            id
            subcategories
            tags
            updatedAt
          }
          createdAt
          id
          updatedAt
          user_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
        }
        nextToken
      }
      createdAt
      id
      subcategories
      tags
      updatedAt
    }
  }
`;
export const getCategoryShop = /* GraphQL */ `
  query GetCategoryShop($id: ID!) {
    getCategoryShop(id: $id) {
      aisles
      category_id {
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category_name
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        category_user {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        id
        subcategories
        tags
        updatedAt
      }
      createdAt
      id
      shop_id {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      subcategory
      updatedAt
    }
  }
`;
export const getCategoryUser = /* GraphQL */ `
  query GetCategoryUser($id: ID!) {
    getCategoryUser(id: $id) {
      category_id {
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category_name
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        category_user {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        id
        subcategories
        tags
        updatedAt
      }
      createdAt
      id
      updatedAt
      user_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
    }
  }
`;
export const getChat = /* GraphQL */ `
  query GetChat($id: ID!) {
    getChat(id: $id) {
      active
      chatsUser {
        items {
          chat_id {
            active
            createdAt
            id
            new_message
            updatedAt
          }
          createdAt
          id
          updatedAt
          user_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
        }
        nextToken
      }
      createdAt
      id
      new_message
      updatedAt
    }
  }
`;
export const getChatUser = /* GraphQL */ `
  query GetChatUser($id: ID!) {
    getChatUser(id: $id) {
      chat_id {
        active
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        id
        new_message
        updatedAt
      }
      createdAt
      id
      updatedAt
      user_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
    }
  }
`;
export const getCollect = /* GraphQL */ `
  query GetCollect($id: ID!) {
    getCollect(id: $id) {
      come_date
      createdAt
      deliveryman_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      finished
      id
      order_id {
        address
        bill_id {
          createdAt
          description
          id
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          status
          total
          updatedAt
        }
        canceled
        collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        com_customer
        com_shop
        createdAt
        customer_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        delivery_man_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        discount
        discount_array {
          createdAt
          for_use
          id
          orders {
            nextToken
          }
          percentage_id {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          used
          value
        }
        final_total
        history
        id
        level
        method_pay
        nc
        orderDetails
        order_case
        order_type
        payment_id
        percentage
        phase
        products {
          items {
            additional_features
            comments
            createdAt
            id
            price
            quantity
            quote_state
            updatedAt
            url_audio
            url_image
          }
          nextToken
        }
        propina
        require_bill
        require_service
        reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        shop_comments
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        shop_idToSort
        status
        total
        tracking_coordinates
        updatedAt
      }
      origin_sign
      picking_date
      sign
      type
      updatedAt
    }
  }
`;
export const getDiscount = /* GraphQL */ `
  query GetDiscount($id: ID!) {
    getDiscount(id: $id) {
      createdAt
      for_use
      id
      orders {
        items {
          address
          bill_id {
            createdAt
            description
            id
            status
            total
            updatedAt
          }
          canceled
          collects {
            nextToken
          }
          com_customer
          com_shop
          createdAt
          customer_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          delivery_man_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          discount
          discount_array {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          final_total
          history
          id
          level
          method_pay
          nc
          orderDetails
          order_case
          order_type
          payment_id
          percentage
          phase
          products {
            nextToken
          }
          propina
          require_bill
          require_service
          reviews {
            nextToken
          }
          shop_comments
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          shop_idToSort
          status
          total
          tracking_coordinates
          updatedAt
        }
        nextToken
      }
      percentage_id {
        array_ofDiscount
        createdAt
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        id
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        value
      }
      shop_id {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      updatedAt
      used
      value
    }
  }
`;
export const getFavorite = /* GraphQL */ `
  query GetFavorite($id: ID!) {
    getFavorite(id: $id) {
      createdAt
      id
      product_id {
        additional_features
        aisle
        createdAt
        description
        expiry_ticket
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        keywords
        limit_ticket
        mature
        netcontent
        offerprice
        orders {
          items {
            additional_features
            comments
            createdAt
            id
            price
            quantity
            quote_state
            updatedAt
            url_audio
            url_image
          }
          nextToken
        }
        own_deliveryMan
        price
        product_name
        product_type
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        status
        subtag
        tags
        updatedAt
        url_image
      }
      shop_id {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      updatedAt
      user_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      chat_id
      content
      createdAt
      expiration_time
      id
      image_url
      updatedAt
      user_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
    }
  }
`;
export const getOrder = /* GraphQL */ `
  query GetOrder($id: ID!) {
    getOrder(id: $id) {
      address
      bill_id {
        createdAt
        description
        id
        order_id {
          address
          bill_id {
            createdAt
            description
            id
            status
            total
            updatedAt
          }
          canceled
          collects {
            nextToken
          }
          com_customer
          com_shop
          createdAt
          customer_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          delivery_man_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          discount
          discount_array {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          final_total
          history
          id
          level
          method_pay
          nc
          orderDetails
          order_case
          order_type
          payment_id
          percentage
          phase
          products {
            nextToken
          }
          propina
          require_bill
          require_service
          reviews {
            nextToken
          }
          shop_comments
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          shop_idToSort
          status
          total
          tracking_coordinates
          updatedAt
        }
        status
        total
        updatedAt
      }
      canceled
      collects {
        items {
          come_date
          createdAt
          deliveryman_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          finished
          id
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          origin_sign
          picking_date
          sign
          type
          updatedAt
        }
        nextToken
      }
      com_customer
      com_shop
      createdAt
      customer_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      delivery_man_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      discount
      discount_array {
        createdAt
        for_use
        id
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        percentage_id {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        updatedAt
        used
        value
      }
      final_total
      history
      id
      level
      method_pay
      nc
      orderDetails
      order_case
      order_type
      payment_id
      percentage
      phase
      products {
        items {
          additional_features
          comments
          createdAt
          id
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          price
          product_id {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          quantity
          quote_state
          updatedAt
          url_audio
          url_image
        }
        nextToken
      }
      propina
      require_bill
      require_service
      reviews {
        items {
          comment
          createdAt
          evaluator_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          id
          note
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          reviewEvaluated_idId
          updatedAt
        }
        nextToken
      }
      shop_comments
      shop_id {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      shop_idToSort
      status
      total
      tracking_coordinates
      updatedAt
    }
  }
`;
export const getOrderProduct = /* GraphQL */ `
  query GetOrderProduct($id: ID!) {
    getOrderProduct(id: $id) {
      additional_features
      comments
      createdAt
      id
      order_id {
        address
        bill_id {
          createdAt
          description
          id
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          status
          total
          updatedAt
        }
        canceled
        collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        com_customer
        com_shop
        createdAt
        customer_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        delivery_man_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        discount
        discount_array {
          createdAt
          for_use
          id
          orders {
            nextToken
          }
          percentage_id {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          used
          value
        }
        final_total
        history
        id
        level
        method_pay
        nc
        orderDetails
        order_case
        order_type
        payment_id
        percentage
        phase
        products {
          items {
            additional_features
            comments
            createdAt
            id
            price
            quantity
            quote_state
            updatedAt
            url_audio
            url_image
          }
          nextToken
        }
        propina
        require_bill
        require_service
        reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        shop_comments
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        shop_idToSort
        status
        total
        tracking_coordinates
        updatedAt
      }
      price
      product_id {
        additional_features
        aisle
        createdAt
        description
        expiry_ticket
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        keywords
        limit_ticket
        mature
        netcontent
        offerprice
        orders {
          items {
            additional_features
            comments
            createdAt
            id
            price
            quantity
            quote_state
            updatedAt
            url_audio
            url_image
          }
          nextToken
        }
        own_deliveryMan
        price
        product_name
        product_type
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        status
        subtag
        tags
        updatedAt
        url_image
      }
      quantity
      quote_state
      updatedAt
      url_audio
      url_image
    }
  }
`;
export const getPaymentHistory = /* GraphQL */ `
  query GetPaymentHistory($id: ID!) {
    getPaymentHistory(id: $id) {
      createdAt
      frequency
      id
      period
      plan
      shop_id {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      status
      total_amount
      type
      updatedAt
    }
  }
`;
export const getPaymentVehicle = /* GraphQL */ `
  query GetPaymentVehicle($id: ID!) {
    getPaymentVehicle(id: $id) {
      createdAt
      date
      deliveryman_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      id
      method_pay
      pay
      status
      updatedAt
    }
  }
`;
export const getPercentage = /* GraphQL */ `
  query GetPercentage($id: ID!) {
    getPercentage(id: $id) {
      array_ofDiscount
      createdAt
      discounts {
        items {
          createdAt
          for_use
          id
          orders {
            nextToken
          }
          percentage_id {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          used
          value
        }
        nextToken
      }
      id
      shops {
        items {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        nextToken
      }
      updatedAt
      value
    }
  }
`;
export const getProduct = /* GraphQL */ `
  query GetProduct($id: ID!) {
    getProduct(id: $id) {
      additional_features
      aisle
      createdAt
      description
      expiry_ticket
      favorites {
        items {
          createdAt
          id
          product_id {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          user_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
        }
        nextToken
      }
      id
      keywords
      limit_ticket
      mature
      netcontent
      offerprice
      orders {
        items {
          additional_features
          comments
          createdAt
          id
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          price
          product_id {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          quantity
          quote_state
          updatedAt
          url_audio
          url_image
        }
        nextToken
      }
      own_deliveryMan
      price
      product_name
      product_type
      shop_id {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      status
      subtag
      tags
      updatedAt
      url_image
    }
  }
`;
export const getPublicity = /* GraphQL */ `
  query GetPublicity($id: ID!) {
    getPublicity(id: $id) {
      createdAt
      deleted_at
      id
      publicity_name
      shop_id {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      status
      updatedAt
      url_image
    }
  }
`;
export const getReview = /* GraphQL */ `
  query GetReview($id: ID!) {
    getReview(id: $id) {
      comment
      createdAt
      evaluator_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      id
      note
      order_id {
        address
        bill_id {
          createdAt
          description
          id
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          status
          total
          updatedAt
        }
        canceled
        collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        com_customer
        com_shop
        createdAt
        customer_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        delivery_man_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        discount
        discount_array {
          createdAt
          for_use
          id
          orders {
            nextToken
          }
          percentage_id {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          used
          value
        }
        final_total
        history
        id
        level
        method_pay
        nc
        orderDetails
        order_case
        order_type
        payment_id
        percentage
        phase
        products {
          items {
            additional_features
            comments
            createdAt
            id
            price
            quantity
            quote_state
            updatedAt
            url_audio
            url_image
          }
          nextToken
        }
        propina
        require_bill
        require_service
        reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        shop_comments
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        shop_idToSort
        status
        total
        tracking_coordinates
        updatedAt
      }
      reviewEvaluated_idId
      updatedAt
    }
  }
`;
export const getReviewsbyEvaluated = /* GraphQL */ `
  query GetReviewsbyEvaluated(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelreviewFilterInput
    $limit: Int
    $nextToken: String
    $reviewEvaluated_idId: String
    $sortDirection: ModelSortDirection
  ) {
    getReviewsbyEvaluated(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      reviewEvaluated_idId: $reviewEvaluated_idId
      sortDirection: $sortDirection
    ) {
      items {
        comment
        createdAt
        evaluator_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        id
        note
        order_id {
          address
          bill_id {
            createdAt
            description
            id
            status
            total
            updatedAt
          }
          canceled
          collects {
            nextToken
          }
          com_customer
          com_shop
          createdAt
          customer_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          delivery_man_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          discount
          discount_array {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          final_total
          history
          id
          level
          method_pay
          nc
          orderDetails
          order_case
          order_type
          payment_id
          percentage
          phase
          products {
            nextToken
          }
          propina
          require_bill
          require_service
          reviews {
            nextToken
          }
          shop_comments
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          shop_idToSort
          status
          total
          tracking_coordinates
          updatedAt
        }
        reviewEvaluated_idId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getShop = /* GraphQL */ `
  query GetShop($id: ID!) {
    getShop(id: $id) {
      acceptCard
      address_coords
      address_shop
      affiliator_id
      availability
      business_name
      campaigns {
        items {
          business_list {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          category_id {
            category_name
            createdAt
            id
            subcategories
            tags
            updatedAt
          }
          clicks
          createdAt
          description
          end_age
          end_campaign
          end_hour
          extra_notification
          gender
          id
          id_marketer {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          interest
          offer_type
          repeat
          repeat_time
          scope
          shopping
          sort_attribute
          start_age
          start_campaign
          start_hour
          status
          subcategory
          title
          type
          updatedAt
          url_image
          zone_list
        }
        nextToken
      }
      category
      categoryId
      category_shop {
        items {
          aisles
          category_id {
            category_name
            createdAt
            id
            subcategories
            tags
            updatedAt
          }
          createdAt
          id
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          subcategory
          updatedAt
        }
        nextToken
      }
      city
      country
      createdAt
      created_by_affiliator
      deleted_at
      description
      discounts {
        items {
          createdAt
          for_use
          id
          orders {
            nextToken
          }
          percentage_id {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          used
          value
        }
        nextToken
      }
      discountsArray
      favorites {
        items {
          createdAt
          id
          product_id {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          user_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
        }
        nextToken
      }
      id
      interclb
      method_pay
      orders {
        items {
          address
          bill_id {
            createdAt
            description
            id
            status
            total
            updatedAt
          }
          canceled
          collects {
            nextToken
          }
          com_customer
          com_shop
          createdAt
          customer_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          delivery_man_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          discount
          discount_array {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          final_total
          history
          id
          level
          method_pay
          nc
          orderDetails
          order_case
          order_type
          payment_id
          percentage
          phase
          products {
            nextToken
          }
          propina
          require_bill
          require_service
          reviews {
            nextToken
          }
          shop_comments
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          shop_idToSort
          status
          total
          tracking_coordinates
          updatedAt
        }
        nextToken
      }
      paymentsHistory {
        items {
          createdAt
          frequency
          id
          period
          plan
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          status
          total_amount
          type
          updatedAt
        }
        nextToken
      }
      percentage_traded {
        array_ofDiscount
        createdAt
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        id
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        value
      }
      phone_number
      postal_code
      price
      products {
        items {
          additional_features
          aisle
          createdAt
          description
          expiry_ticket
          favorites {
            nextToken
          }
          id
          keywords
          limit_ticket
          mature
          netcontent
          offerprice
          orders {
            nextToken
          }
          own_deliveryMan
          price
          product_name
          product_type
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          status
          subtag
          tags
          updatedAt
          url_image
        }
        nextToken
      }
      publicities {
        items {
          createdAt
          deleted_at
          id
          publicity_name
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          status
          updatedAt
          url_image
        }
        nextToken
      }
      rating
      schedule
      seller_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      shop_name
      shop_type
      showAddress
      status
      subcategory
      updatedAt
      url_documents
      url_image
      url_pdf
      zone
    }
  }
`;
export const getShopByCategory = /* GraphQL */ `
  query GetShopByCategory(
    $categoryId: String
    $filter: ModelshopFilterInput
    $limit: Int
    $nextToken: String
    $postal_code: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
  ) {
    getShopByCategory(
      categoryId: $categoryId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      postal_code: $postal_code
      sortDirection: $sortDirection
    ) {
      items {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      nextToken
    }
  }
`;
export const getShopByCategoryDate = /* GraphQL */ `
  query GetShopByCategoryDate(
    $categoryId: String
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelshopFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getShopByCategoryDate(
      categoryId: $categoryId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      nextToken
    }
  }
`;
export const getShopByCreatedDate = /* GraphQL */ `
  query GetShopByCreatedDate(
    $affiliator_id: String
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelshopFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    getShopByCreatedDate(
      affiliator_id: $affiliator_id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      nextToken
    }
  }
`;
export const getUserAddress = /* GraphQL */ `
  query GetUserAddress($id: ID!) {
    getUserAddress(id: $id) {
      active
      address_id {
        city
        country
        createdAt
        id
        interior_number
        latitude
        longitude
        outdoor_number
        postal_code
        street
        town
        updatedAt
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
      }
      createdAt
      id
      tag
      updatedAt
      user_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      zip_codes
    }
  }
`;
export const getVehicle = /* GraphQL */ `
  query GetVehicle($id: ID!) {
    getVehicle(id: $id) {
      active
      createdAt
      delivery_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      id
      mark
      model
      niv
      price
      status
      temporal
      type_vehicle
      updatedAt
      zone_id {
        active
        bases {
          items {
            active
            address
            country
            createdAt
            description
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        deliverymen_inZone
        franchisse_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        id
        updatedAt
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zone_name
        zone_state
      }
    }
  }
`;
export const getZone = /* GraphQL */ `
  query GetZone($id: ID!) {
    getZone(id: $id) {
      active
      bases {
        items {
          active
          address
          country
          createdAt
          description
          id
          updatedAt
          zone_id {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
        }
        nextToken
      }
      createdAt
      deliverymen_inZone
      franchisse_id {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      id
      updatedAt
      vehicles {
        items {
          active
          createdAt
          delivery_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          id
          mark
          model
          niv
          price
          status
          temporal
          type_vehicle
          updatedAt
          zone_id {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
        }
        nextToken
      }
      zone_name
      zone_state
    }
  }
`;
export const listAccounts = /* GraphQL */ `
  query ListAccounts(
    $filter: ModelaccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        availability
        birthday
        campaign {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        categoryUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        current_session
        customer_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        deleted_at
        delivery_inprocess
        deliveryman_collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        deliveryman_orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        email
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        favorites_shops
        id
        interests
        last_name
        messages {
          items {
            chat_id
            content
            createdAt
            expiration_time
            id
            image_url
            updatedAt
          }
          nextToken
        }
        name
        nikucoins
        notification_token
        openPay_id
        payments_vehicle {
          items {
            createdAt
            date
            id
            method_pay
            pay
            status
            updatedAt
          }
          nextToken
        }
        pending_chats
        phone_number
        rfc
        role
        sended_reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        sexo
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        url_photoProfile
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zip_codes
        zone
        zones {
          items {
            active
            createdAt
            deliverymen_inZone
            id
            updatedAt
            zone_name
            zone_state
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listActiveCampaignss = /* GraphQL */ `
  query ListActiveCampaignss(
    $filter: ModelactiveCampaignsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listActiveCampaignss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        TTLCampaign
        createdAt
        description
        end_age
        end_campaign
        gender
        id
        interest
        repeat
        repeat_time
        sort_attribute
        start_age
        start_campaign
        status
        title
        type
        updatedAt
        url_image
        zone_list
      }
      nextToken
    }
  }
`;
export const listAddresss = /* GraphQL */ `
  query ListAddresss(
    $filter: ModeladdressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        city
        country
        createdAt
        id
        interior_number
        latitude
        longitude
        outdoor_number
        postal_code
        street
        town
        updatedAt
        user_addresses {
          items {
            active
            createdAt
            id
            tag
            updatedAt
            zip_codes
          }
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const listBases = /* GraphQL */ `
  query ListBases(
    $filter: ModelbaseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBases(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        active
        address
        country
        createdAt
        description
        id
        updatedAt
        zone_id {
          active
          bases {
            nextToken
          }
          createdAt
          deliverymen_inZone
          franchisse_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          id
          updatedAt
          vehicles {
            nextToken
          }
          zone_name
          zone_state
        }
      }
      nextToken
    }
  }
`;
export const listBills = /* GraphQL */ `
  query ListBills(
    $filter: ModelbillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        description
        id
        order_id {
          address
          bill_id {
            createdAt
            description
            id
            status
            total
            updatedAt
          }
          canceled
          collects {
            nextToken
          }
          com_customer
          com_shop
          createdAt
          customer_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          delivery_man_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          discount
          discount_array {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          final_total
          history
          id
          level
          method_pay
          nc
          orderDetails
          order_case
          order_type
          payment_id
          percentage
          phase
          products {
            nextToken
          }
          propina
          require_bill
          require_service
          reviews {
            nextToken
          }
          shop_comments
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          shop_idToSort
          status
          total
          tracking_coordinates
          updatedAt
        }
        status
        total
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCampaigns = /* GraphQL */ `
  query ListCampaigns(
    $filter: ModelcampaignFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCampaigns(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        business_list {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        category_id {
          campaigns {
            nextToken
          }
          category_name
          category_shop {
            nextToken
          }
          category_user {
            nextToken
          }
          createdAt
          id
          subcategories
          tags
          updatedAt
        }
        clicks
        createdAt
        description
        end_age
        end_campaign
        end_hour
        extra_notification
        gender
        id
        id_marketer {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        interest
        offer_type
        repeat
        repeat_time
        scope
        shopping
        sort_attribute
        start_age
        start_campaign
        start_hour
        status
        subcategory
        title
        type
        updatedAt
        url_image
        zone_list
      }
      nextToken
    }
  }
`;
export const listCategoryShops = /* GraphQL */ `
  query ListCategoryShops(
    $filter: ModelcategoryShopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        aisles
        category_id {
          campaigns {
            nextToken
          }
          category_name
          category_shop {
            nextToken
          }
          category_user {
            nextToken
          }
          createdAt
          id
          subcategories
          tags
          updatedAt
        }
        createdAt
        id
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        subcategory
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCategoryUsers = /* GraphQL */ `
  query ListCategoryUsers(
    $filter: ModelcategoryUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategoryUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        category_id {
          campaigns {
            nextToken
          }
          category_name
          category_shop {
            nextToken
          }
          category_user {
            nextToken
          }
          createdAt
          id
          subcategories
          tags
          updatedAt
        }
        createdAt
        id
        updatedAt
        user_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelcategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category_name
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        category_user {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        id
        subcategories
        tags
        updatedAt
      }
      nextToken
    }
  }
`;
export const listChatUsers = /* GraphQL */ `
  query ListChatUsers(
    $filter: ModelchatUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChatUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        chat_id {
          active
          chatsUser {
            nextToken
          }
          createdAt
          id
          new_message
          updatedAt
        }
        createdAt
        id
        updatedAt
        user_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listChats = /* GraphQL */ `
  query ListChats(
    $filter: ModelchatFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listChats(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        active
        chatsUser {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        id
        new_message
        updatedAt
      }
      nextToken
    }
  }
`;
export const listCollects = /* GraphQL */ `
  query ListCollects(
    $filter: ModelcollectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCollects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        come_date
        createdAt
        deliveryman_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        finished
        id
        order_id {
          address
          bill_id {
            createdAt
            description
            id
            status
            total
            updatedAt
          }
          canceled
          collects {
            nextToken
          }
          com_customer
          com_shop
          createdAt
          customer_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          delivery_man_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          discount
          discount_array {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          final_total
          history
          id
          level
          method_pay
          nc
          orderDetails
          order_case
          order_type
          payment_id
          percentage
          phase
          products {
            nextToken
          }
          propina
          require_bill
          require_service
          reviews {
            nextToken
          }
          shop_comments
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          shop_idToSort
          status
          total
          tracking_coordinates
          updatedAt
        }
        origin_sign
        picking_date
        sign
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const listDiscounts = /* GraphQL */ `
  query ListDiscounts(
    $filter: ModeldiscountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDiscounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        for_use
        id
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        percentage_id {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        updatedAt
        used
        value
      }
      nextToken
    }
  }
`;
export const listFavorites = /* GraphQL */ `
  query ListFavorites(
    $filter: ModelfavoriteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        id
        product_id {
          additional_features
          aisle
          createdAt
          description
          expiry_ticket
          favorites {
            nextToken
          }
          id
          keywords
          limit_ticket
          mature
          netcontent
          offerprice
          orders {
            nextToken
          }
          own_deliveryMan
          price
          product_name
          product_type
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          status
          subtag
          tags
          updatedAt
          url_image
        }
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        updatedAt
        user_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelmessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        chat_id
        content
        createdAt
        expiration_time
        id
        image_url
        updatedAt
        user_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const listOrderProducts = /* GraphQL */ `
  query ListOrderProducts(
    $filter: ModelorderProductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrderProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        additional_features
        comments
        createdAt
        id
        order_id {
          address
          bill_id {
            createdAt
            description
            id
            status
            total
            updatedAt
          }
          canceled
          collects {
            nextToken
          }
          com_customer
          com_shop
          createdAt
          customer_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          delivery_man_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          discount
          discount_array {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          final_total
          history
          id
          level
          method_pay
          nc
          orderDetails
          order_case
          order_type
          payment_id
          percentage
          phase
          products {
            nextToken
          }
          propina
          require_bill
          require_service
          reviews {
            nextToken
          }
          shop_comments
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          shop_idToSort
          status
          total
          tracking_coordinates
          updatedAt
        }
        price
        product_id {
          additional_features
          aisle
          createdAt
          description
          expiry_ticket
          favorites {
            nextToken
          }
          id
          keywords
          limit_ticket
          mature
          netcontent
          offerprice
          orders {
            nextToken
          }
          own_deliveryMan
          price
          product_name
          product_type
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          status
          subtag
          tags
          updatedAt
          url_image
        }
        quantity
        quote_state
        updatedAt
        url_audio
        url_image
      }
      nextToken
    }
  }
`;
export const listOrders = /* GraphQL */ `
  query ListOrders(
    $filter: ModelorderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrders(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        address
        bill_id {
          createdAt
          description
          id
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          status
          total
          updatedAt
        }
        canceled
        collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        com_customer
        com_shop
        createdAt
        customer_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        delivery_man_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        discount
        discount_array {
          createdAt
          for_use
          id
          orders {
            nextToken
          }
          percentage_id {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          used
          value
        }
        final_total
        history
        id
        level
        method_pay
        nc
        orderDetails
        order_case
        order_type
        payment_id
        percentage
        phase
        products {
          items {
            additional_features
            comments
            createdAt
            id
            price
            quantity
            quote_state
            updatedAt
            url_audio
            url_image
          }
          nextToken
        }
        propina
        require_bill
        require_service
        reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        shop_comments
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        shop_idToSort
        status
        total
        tracking_coordinates
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPaymentHistorys = /* GraphQL */ `
  query ListPaymentHistorys(
    $filter: ModelpaymentHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        frequency
        id
        period
        plan
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        status
        total_amount
        type
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPaymentVehicles = /* GraphQL */ `
  query ListPaymentVehicles(
    $filter: ModelpaymentVehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        date
        deliveryman_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        id
        method_pay
        pay
        status
        updatedAt
      }
      nextToken
    }
  }
`;
export const listPercentages = /* GraphQL */ `
  query ListPercentages(
    $filter: ModelpercentageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPercentages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        array_ofDiscount
        createdAt
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        id
        shops {
          items {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          nextToken
        }
        updatedAt
        value
      }
      nextToken
    }
  }
`;
export const listProducts = /* GraphQL */ `
  query ListProducts(
    $filter: ModelproductFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        additional_features
        aisle
        createdAt
        description
        expiry_ticket
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        keywords
        limit_ticket
        mature
        netcontent
        offerprice
        orders {
          items {
            additional_features
            comments
            createdAt
            id
            price
            quantity
            quote_state
            updatedAt
            url_audio
            url_image
          }
          nextToken
        }
        own_deliveryMan
        price
        product_name
        product_type
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        status
        subtag
        tags
        updatedAt
        url_image
      }
      nextToken
    }
  }
`;
export const listPublicitys = /* GraphQL */ `
  query ListPublicitys(
    $filter: ModelpublicityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPublicitys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        deleted_at
        id
        publicity_name
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        status
        updatedAt
        url_image
      }
      nextToken
    }
  }
`;
export const listReviews = /* GraphQL */ `
  query ListReviews(
    $filter: ModelreviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        comment
        createdAt
        evaluator_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        id
        note
        order_id {
          address
          bill_id {
            createdAt
            description
            id
            status
            total
            updatedAt
          }
          canceled
          collects {
            nextToken
          }
          com_customer
          com_shop
          createdAt
          customer_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          delivery_man_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          discount
          discount_array {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          final_total
          history
          id
          level
          method_pay
          nc
          orderDetails
          order_case
          order_type
          payment_id
          percentage
          phase
          products {
            nextToken
          }
          propina
          require_bill
          require_service
          reviews {
            nextToken
          }
          shop_comments
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          shop_idToSort
          status
          total
          tracking_coordinates
          updatedAt
        }
        reviewEvaluated_idId
        updatedAt
      }
      nextToken
    }
  }
`;
export const listShops = /* GraphQL */ `
  query ListShops(
    $filter: ModelshopFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listShops(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        acceptCard
        address_coords
        address_shop
        affiliator_id
        availability
        business_name
        campaigns {
          items {
            clicks
            createdAt
            description
            end_age
            end_campaign
            end_hour
            extra_notification
            gender
            id
            interest
            offer_type
            repeat
            repeat_time
            scope
            shopping
            sort_attribute
            start_age
            start_campaign
            start_hour
            status
            subcategory
            title
            type
            updatedAt
            url_image
            zone_list
          }
          nextToken
        }
        category
        categoryId
        category_shop {
          items {
            aisles
            createdAt
            id
            subcategory
            updatedAt
          }
          nextToken
        }
        city
        country
        createdAt
        created_by_affiliator
        deleted_at
        description
        discounts {
          items {
            createdAt
            for_use
            id
            updatedAt
            used
            value
          }
          nextToken
        }
        discountsArray
        favorites {
          items {
            createdAt
            id
            updatedAt
          }
          nextToken
        }
        id
        interclb
        method_pay
        orders {
          items {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          nextToken
        }
        paymentsHistory {
          items {
            createdAt
            frequency
            id
            period
            plan
            status
            total_amount
            type
            updatedAt
          }
          nextToken
        }
        percentage_traded {
          array_ofDiscount
          createdAt
          discounts {
            nextToken
          }
          id
          shops {
            nextToken
          }
          updatedAt
          value
        }
        phone_number
        postal_code
        price
        products {
          items {
            additional_features
            aisle
            createdAt
            description
            expiry_ticket
            id
            keywords
            limit_ticket
            mature
            netcontent
            offerprice
            own_deliveryMan
            price
            product_name
            product_type
            status
            subtag
            tags
            updatedAt
            url_image
          }
          nextToken
        }
        publicities {
          items {
            createdAt
            deleted_at
            id
            publicity_name
            status
            updatedAt
            url_image
          }
          nextToken
        }
        rating
        schedule
        seller_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        shop_name
        shop_type
        showAddress
        status
        subcategory
        updatedAt
        url_documents
        url_image
        url_pdf
        zone
      }
      nextToken
    }
  }
`;
export const listUserAddresss = /* GraphQL */ `
  query ListUserAddresss(
    $filter: ModeluserAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        active
        address_id {
          city
          country
          createdAt
          id
          interior_number
          latitude
          longitude
          outdoor_number
          postal_code
          street
          town
          updatedAt
          user_addresses {
            nextToken
          }
        }
        createdAt
        id
        tag
        updatedAt
        user_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        zip_codes
      }
      nextToken
    }
  }
`;
export const listVehicles = /* GraphQL */ `
  query ListVehicles(
    $filter: ModelvehicleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVehicles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        active
        createdAt
        delivery_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        id
        mark
        model
        niv
        price
        status
        temporal
        type_vehicle
        updatedAt
        zone_id {
          active
          bases {
            nextToken
          }
          createdAt
          deliverymen_inZone
          franchisse_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          id
          updatedAt
          vehicles {
            nextToken
          }
          zone_name
          zone_state
        }
      }
      nextToken
    }
  }
`;
export const listZones = /* GraphQL */ `
  query ListZones(
    $filter: ModelzoneFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listZones(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        active
        bases {
          items {
            active
            address
            country
            createdAt
            description
            id
            updatedAt
          }
          nextToken
        }
        createdAt
        deliverymen_inZone
        franchisse_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        id
        updatedAt
        vehicles {
          items {
            active
            createdAt
            id
            mark
            model
            niv
            price
            status
            temporal
            type_vehicle
            updatedAt
          }
          nextToken
        }
        zone_name
        zone_state
      }
      nextToken
    }
  }
`;
export const messageByCreationDate = /* GraphQL */ `
  query MessageByCreationDate(
    $chat_id: String
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelmessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    messageByCreationDate(
      chat_id: $chat_id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        chat_id
        content
        createdAt
        expiration_time
        id
        image_url
        updatedAt
        user_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
      }
      nextToken
    }
  }
`;
export const ordersByShopId = /* GraphQL */ `
  query OrdersByShopId(
    $filter: ModelorderFilterInput
    $limit: Int
    $nextToken: String
    $shop_idToSort: String
    $sortDirection: ModelSortDirection
    $updatedAt: ModelStringKeyConditionInput
  ) {
    ordersByShopId(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      shop_idToSort: $shop_idToSort
      sortDirection: $sortDirection
      updatedAt: $updatedAt
    ) {
      items {
        address
        bill_id {
          createdAt
          description
          id
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          status
          total
          updatedAt
        }
        canceled
        collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        com_customer
        com_shop
        createdAt
        customer_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        delivery_man_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        discount
        discount_array {
          createdAt
          for_use
          id
          orders {
            nextToken
          }
          percentage_id {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          used
          value
        }
        final_total
        history
        id
        level
        method_pay
        nc
        orderDetails
        order_case
        order_type
        payment_id
        percentage
        phase
        products {
          items {
            additional_features
            comments
            createdAt
            id
            price
            quantity
            quote_state
            updatedAt
            url_audio
            url_image
          }
          nextToken
        }
        propina
        require_bill
        require_service
        reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        shop_comments
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        shop_idToSort
        status
        total
        tracking_coordinates
        updatedAt
      }
      nextToken
    }
  }
`;
export const ordersByUpdatedDate = /* GraphQL */ `
  query OrdersByUpdatedDate(
    $filter: ModelorderFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $status: String
    $updatedAt: ModelStringKeyConditionInput
  ) {
    ordersByUpdatedDate(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      status: $status
      updatedAt: $updatedAt
    ) {
      items {
        address
        bill_id {
          createdAt
          description
          id
          order_id {
            address
            canceled
            com_customer
            com_shop
            createdAt
            discount
            final_total
            history
            id
            level
            method_pay
            nc
            orderDetails
            order_case
            order_type
            payment_id
            percentage
            phase
            propina
            require_bill
            require_service
            shop_comments
            shop_idToSort
            status
            total
            tracking_coordinates
            updatedAt
          }
          status
          total
          updatedAt
        }
        canceled
        collects {
          items {
            come_date
            createdAt
            finished
            id
            origin_sign
            picking_date
            sign
            type
            updatedAt
          }
          nextToken
        }
        com_customer
        com_shop
        createdAt
        customer_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        delivery_man_id {
          availability
          birthday
          campaign {
            nextToken
          }
          categoryUser {
            nextToken
          }
          chatsUser {
            nextToken
          }
          createdAt
          current_session
          customer_orders {
            nextToken
          }
          deleted_at
          delivery_inprocess
          deliveryman_collects {
            nextToken
          }
          deliveryman_orders {
            nextToken
          }
          email
          favorites {
            nextToken
          }
          favorites_shops
          id
          interests
          last_name
          messages {
            nextToken
          }
          name
          nikucoins
          notification_token
          openPay_id
          payments_vehicle {
            nextToken
          }
          pending_chats
          phone_number
          rfc
          role
          sended_reviews {
            nextToken
          }
          sexo
          shops {
            nextToken
          }
          updatedAt
          url_photoProfile
          user_addresses {
            nextToken
          }
          vehicles {
            nextToken
          }
          zip_codes
          zone
          zones {
            nextToken
          }
        }
        discount
        discount_array {
          createdAt
          for_use
          id
          orders {
            nextToken
          }
          percentage_id {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          shop_id {
            acceptCard
            address_coords
            address_shop
            affiliator_id
            availability
            business_name
            category
            categoryId
            city
            country
            createdAt
            created_by_affiliator
            deleted_at
            description
            discountsArray
            id
            interclb
            method_pay
            phone_number
            postal_code
            price
            rating
            schedule
            shop_name
            shop_type
            showAddress
            status
            subcategory
            updatedAt
            url_documents
            url_image
            url_pdf
            zone
          }
          updatedAt
          used
          value
        }
        final_total
        history
        id
        level
        method_pay
        nc
        orderDetails
        order_case
        order_type
        payment_id
        percentage
        phase
        products {
          items {
            additional_features
            comments
            createdAt
            id
            price
            quantity
            quote_state
            updatedAt
            url_audio
            url_image
          }
          nextToken
        }
        propina
        require_bill
        require_service
        reviews {
          items {
            comment
            createdAt
            id
            note
            reviewEvaluated_idId
            updatedAt
          }
          nextToken
        }
        shop_comments
        shop_id {
          acceptCard
          address_coords
          address_shop
          affiliator_id
          availability
          business_name
          campaigns {
            nextToken
          }
          category
          categoryId
          category_shop {
            nextToken
          }
          city
          country
          createdAt
          created_by_affiliator
          deleted_at
          description
          discounts {
            nextToken
          }
          discountsArray
          favorites {
            nextToken
          }
          id
          interclb
          method_pay
          orders {
            nextToken
          }
          paymentsHistory {
            nextToken
          }
          percentage_traded {
            array_ofDiscount
            createdAt
            id
            updatedAt
            value
          }
          phone_number
          postal_code
          price
          products {
            nextToken
          }
          publicities {
            nextToken
          }
          rating
          schedule
          seller_id {
            availability
            birthday
            createdAt
            current_session
            deleted_at
            delivery_inprocess
            email
            favorites_shops
            id
            interests
            last_name
            name
            nikucoins
            notification_token
            openPay_id
            pending_chats
            phone_number
            rfc
            role
            sexo
            updatedAt
            url_photoProfile
            zip_codes
            zone
          }
          shop_name
          shop_type
          showAddress
          status
          subcategory
          updatedAt
          url_documents
          url_image
          url_pdf
          zone
        }
        shop_idToSort
        status
        total
        tracking_coordinates
        updatedAt
      }
      nextToken
    }
  }
`;
