import { makeStyles } from "@mui/styles";

export const containerStyles = {
  width: "100%",
  maxWidth: "1148px",
  margin: "auto",
  height: "auto",
}

export const textContainer = {
  marginTop: "15px",
  display: "flex",
  justifyContent: "space-between", 
  alignItems: "end"
}

export const reportsTabsContainer = {
  width: "100%",
  maxWidth: "1148px",
  minHeight: "172px",
  background: "#FFFFFF",
  borderRadius: "5px",
  padding: "25px 37px 15px 44px",
  marginBottom: "35px"
}

export const customBtn = {
  width: "185px",
  height: "32px",
  background: "#F6F6F6",
}


export const tabStylesTest = {
  width: "100%",
  "& .MuiTabs-scroller .MuiTabs-flexContainer": {
    justifyContent: "space-between",
  },
  "& .MuiTabs-scroller .MuiTabs-flexContainer .MuiButtonBase-root":{
    fontFamily: "Montserrat",
    width: "185px",
    minHeight: "32px",
    borderRadius: "5px",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "17px",
    letterSpacing: "0em",
    textAlign: "center",
    textTransform: "capitalize",
    color: "#333333",
  },
  "& .MuiTabs-scroller .MuiTabs-indicator":{
    display: "none"
  }
}

export const dividerStyles = {
  marginTop: "15px",
  marginBottom: "12px",
  background: "#F2F2F2",
  border: "1px solid #F2F2F2"
}

export const reportsTabsFooter = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center"
}

const useStyles = makeStyles({
  labelDatePicker:{
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "17px",
    color: "#BDBDBD",
    marginLeft: "10px",
    marginBottom: "4px",
  },
  selectedTab:{
    background: "#EA5B13 !important",
    color: "#FFFFFF !important"
  },
  disableTab:{
    color: "rgba(0, 0, 0, 0.38) !important"
  },
  zoneBusinessText:{
    fontSize: "3rem",
    fontWeight: 700,
    lineHeight: "58px",
    letterSpacing: "0em",
    textAlign: "center",
  },
  userInfo:{
    fontSize: "1.5rem",
    fontWeight: 500,
    lineHeight: "29px",
    letterSpacing: "0em",
    textAlign: "right",
    color: "#828282"
  }
})

export {useStyles};