import moment from "moment";
import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Box from "@mui/material/Box";
import ChevronLeftOutlinedIcon from "@mui/icons-material/ChevronLeftOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import AccessTimeOutlinedIcon from "@mui/icons-material/AccessTimeOutlined";
import { Divider } from "@mui/material";
import AttachMoneyRoundedIcon from "@mui/icons-material/AttachMoneyRounded";
import RatingStars from "../../DesignSystem/RatingStars";
import BusinessImage from "./BusinessImage";
import { getRatingShop } from "../Infrastructure/Business.presenter";
import CollapseButton from "../../DesignSystem/CollapseButton";
import MenuButton from "../../DesignSystem/MenuButton";
import AccessTimeRoundedIcon from "@mui/icons-material/AccessTimeRounded";
import FmdGoodOutlinedIcon from "@mui/icons-material/FmdGoodOutlined";
import shopBag from "../../assets/shopBagGray.svg";
import FolderOutlinedIcon from "@mui/icons-material/FolderOutlined";
import BusinessDetailsClb from "../UI/BusinessDetailClb";
import {
  useStyles,
  scheduleStyles,
  stats,
  infoContainer,
  infoContainerMobile,
  headerInfo,
  priceStyles,
  scheduleContainer,
  businessImageStyles,
} from "./styles/BusinessDetailsCard.styles";
import BusinessSwitch from "./BusinessSwitchAvail";
import { GetRStatusShop } from "../../graphql/myQueries";
import { API, graphqlOperation } from "aws-amplify";

const setPriceIcons = (num) => {
  let price = [];
  for (let i = 0; i < num; i++) {
    price.push(<AttachMoneyRoundedIcon key={i} sx={priceStyles} />);
  }
  return price;
};

export default function BusinessDetailsCard({
  id,
  image,
  name,
  description,
  price,
  ownerName = "No disponible",
  adviserName,
  createdAt,
  sellerName,
  schedule,
}) {
  const classes = useStyles();
  const history = useHistory();
  const formatedDate = moment(new Date(createdAt)).format("DD/MM/YYYY");
  const [stars, setStars] = useState(0);
  const [idShop, setIdShop] = useState("");

  useEffect(() => {
    setIdShop(id);
    const shopRating = async () => {
      try {
        const response = await getRatingShop(id);
        if (isNaN(response)) {
          setStars(0);
        } else {
          setStars(response.toFixed(2));
        }
      } catch (error) {
        console.log("error en rating shop", error);
      }
    };
    shopRating();
  }, [id]);

  return (
    <div className={classes.principalContainer}>
      <div className={classes.headerHelp}>
        <ChevronLeftOutlinedIcon
          sx={{
            width: "24px",
            height: "24px",
            display: { md: "none" },
            color: "#004990",
          }}
          onClick={() => history.push(`/negocios`)}
        />
        <h4 style={{ color: "#004990", fontSize: "0.875rem", fontWeigh: 700 }}>
          Ayuda
        </h4>
      </div>
      <BusinessImage businessImage={image} customStyles={businessImageStyles} />

      <Box sx={stats}>
        {(!schedule ||
          typeof schedule === "string" ||
          schedule.length === 0) && (
          <p className={`${classes.businessHours} ${classes.centerAlign}`}>
            <AccessTimeOutlinedIcon sx={scheduleStyles} /> - - -{" "}
          </p>
        )}
        <div className={classes.businessPrice}>
          {price !== 0 && setPriceIcons(price)}
        </div>
        <div className={classes.centerAlign}>
          <RatingStars rating={stars} />
        </div>
      </Box>

      <h3 className={classes.businessName}>{name}</h3>
      <Box sx={{ alignSelf: "start" }}>
        <p className={classes.businessDescription}>
          {description || "Sin descripción"}
        </p>
      </Box>
      <Divider />

      <BusinessSwitch idshop={idShop} />
      <BusinessDetailsClb idshop={idShop} />

      <div className={classes.menuButtons}>
        <CollapseButton
          title="Ver información"
          icon={<InfoOutlinedIcon sx={{ color: "#4F4F4F" }} />}
        >
          <Box sx={infoContainerMobile}>
            <Box sx={headerInfo}>
              <p className={`${classes.key} ${classes.text}`}>Encargado:</p>
              <p className={`${classes.value} ${classes.text}`}>
                {sellerName || "No disponible"}
              </p>
            </Box>
            <Box sx={headerInfo}>
              <p className={`${classes.key} ${classes.text}`}>Dueño:</p>
              <p className={`${classes.value} ${classes.text}`}>
                {ownerName || "No disponible"}
              </p>
            </Box>
            <Box sx={headerInfo}>
              <p className={`${classes.key} ${classes.text}`}>Afiliador:</p>
              <p className={`${classes.value} ${classes.text}`}>
                {adviserName || "No dispobible"}
              </p>
            </Box>
            <Box sx={headerInfo}>
              <p className={`${classes.key} ${classes.text}`}>
                Fecha de afiliación:
              </p>
              <p className={`${classes.value} ${classes.text}`}>
                {formatedDate}
              </p>
            </Box>
          </Box>
        </CollapseButton>
        <MenuButton
          title="Abrir ubicación"
          icon={<FmdGoodOutlinedIcon sx={{ color: "#4F4F4F" }} />}
          onClick={() => history.push(`/negocio/${id}/ubicacion`)}
        />
        <MenuButton
          title="Ver productos"
          icon={<img src={shopBag} alt="Shop bag" />}
          onClick={() => history.push(`/negocio/${id}/productos`)}
        />
        <MenuButton
          title="Ver reportes"
          icon={<FolderOutlinedIcon sx={{ color: "#4F4F4F" }} />}
          onClick={() => null}
        />
      </div>
      <Box sx={infoContainer}>
        <Box sx={headerInfo}>
          <InfoOutlinedIcon
            sx={{ color: "#EA5B13", width: "24px", height: "24px" }}
          />
          <h4 className={classes.titleInfo}>Información</h4>
        </Box>
        <Box sx={headerInfo}>
          <p className={classes.key}>Encargado:</p>
          <p className={classes.value}>{sellerName || "No disponible"}</p>
        </Box>
        <Box sx={headerInfo}>
          <p className={classes.key}>Dueño:</p>
          <p className={classes.value}>{ownerName || "No disponible"}</p>
        </Box>
        <Box sx={headerInfo}>
          <p className={classes.key}>Afiliador:</p>
          <p className={classes.value}>{adviserName || "No disponible"}</p>
        </Box>
        <Box sx={headerInfo}>
          <p className={classes.key}>Fecha de afiliación:</p>
          <p className={classes.value}>{formatedDate || "No disponible"}</p>
        </Box>
      </Box>
      {typeof schedule !== "string" && schedule.length !== 0 && (
        <Box sx={scheduleContainer}>
          <Box sx={headerInfo}>
            <AccessTimeRoundedIcon
              sx={{ color: "#EA5B13", width: "24px", height: "24px" }}
            />
            <h4 className={classes.titleInfo}>Horario</h4>
          </Box>
          {schedule.map((day, index) => (
            <p key={index} style={{ marginTop: "7px", marginBottom: "7px" }}>
              {day}
            </p>
          ))}
        </Box>
      )}
    </div>
  );
}
