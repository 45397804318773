import {
  GetBusinessListService,
  GetBusinessListByCategoryService,
  GetBusinessListByNameService,
  GetListCategoriesService,
  getListOfferProductsService,
  getListReviewsService,
} from "../Infrastructure/Business.service";

export async function GetBusinessListLogic(zone) {
  return await GetBusinessListService(zone);
}

export async function getListOfferProductsLogic(id) {
  return getListOfferProductsService(id);
}

export async function getListReviewsLogic(id) {
  return getListReviewsService(id);
}

export async function GetBusinessListByCategoryLogic(zone, category) {
  return await GetBusinessListByCategoryService(zone, category);
}

export async function GetBusinessListByNameLogic(zone, name) {
  return await GetBusinessListByNameService(zone, name);
}

export async function GetListCategoriesLogic() {
  return await GetListCategoriesService();
}
