import LoginLogic from "../Application/Login.logic";
import LogoutLogic from "../Application/Logout.logic";

export async function Login(email, password) {
  try{
    const login = await LoginLogic(email, password);
    localStorage.setItem("userid", login.username);
    return {valid: true, info: login};
  }catch(error){
    return {valid: false, info: error};
  }
}

export async function Logout() {
  return LogoutLogic();
}