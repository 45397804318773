import {useState} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import MenuButton from "./MenuButton";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Collapse from "@mui/material/Collapse";

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#000",
  },
  content: {
    width: "100%",
    border: "1px solid #F2F2F2",
    borderRadius: "5px",
  }
}));

export default function CollapseButton({title, icon, children}){
  const classes = useStyles();
  const [subOpen, setOpen] = useState(false);

  const handleContent = () => {
    setOpen(!subOpen);
  };

  return(
    <div className={classes.content} >
      <MenuButton title={title} onClick={handleContent} icon={icon} />
      <Box>
        <Collapse in={subOpen} timeout="auto" unmountOnExit>
        <Divider sx={{ margin: "10px 0px" }} />
          {children}
        </Collapse>
      </Box>
    </div>
  );
}

CollapseButton.defaultProps = {

};

CollapseButton.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.element.isRequired,
  children: PropTypes.element.isRequired,
};
