import { useState, useEffect} from "react";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {tableContainerStyles, tableHeadCellStyles, tableCellStyles} from "./styles/StatementAccountTable.styles";

import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { saveAs } from 'file-saver';

const data = [
  {
    ID: 1,
    fecha: "01/03/2021",
    franquicia: "e19podba-a598-416c-a359-bcfe1bdd1977",
    descripcion : "00.00",
    cargos: "00",
    abonos: "00.00",
    saldo: "00.00",
  },
  {
    ID: 2,
    fecha: "01/04/2021",
    franquicia: "e9oo7dba-a598-416c-a359-bcfe1bdd1977",
    descripcion : "00.00",
    cargos: "00",
    abonos: "00.00",
    saldo: "00.00",
  },
  {
    ID: 3,
    fecha: "30/06/2021",
    franquicia: "e440d7dba-a598-416c-a359-bcfe1bdd1977",
    descripcion : "00.00",
    cargos: "00",
    abonos: "00.00",
    saldo: "00.00",
  },
  {
    ID: 4,
    fecha: "21/08/2021",
    franquicia: "e9o34dba-a598-416c-a359-bcfe1bdd1977",
    descripcion : "00.00",
    cargos: "00",
    abonos: "00.00",
    saldo: "00.00",
  },
  {
    ID: 5,
    fecha: "14/10/2021",
    franquicia: "eolm2dba-a598-416c-a359-bcfe1bdd1977",
    descripcion : "00.00",
    cargos: "00",
    abonos: "00.00",
    saldo: "00.00",
  },
  {
    ID: 6,
    fecha: "01/11/2021",
    franquicia: "e7yg3dba-a598-416c-a359-bcfe1bdd1977",
    descripcion : "00.00",
    cargos: "20",
    abonos: "00.00",
    saldo: "30.00",
  },
  {
    ID: 7,
    fecha: "11/11/2021",
    franquicia: "e3mb6dba-a598-416c-a359-bcfe1bdd1977",
    descripcion : "00.00",
    cargos: "01",
    abonos: "00.00",
    saldo: "990.00",
  },
  {
    ID: 8,
    fecha: "28/11/2021",
    franquicia: "ei6mqdba-a598-416c-a359-bcfe1bdd1977",
    descripcion : "$00.00",
    cargos: "11",
    abonos: "600.00",
    saldo: "4055.00",
  },
  {
    ID: 9,
    fecha: "01/02/2022",
    franquicia: "e8ur4dba-a598-416c-a359-bcfe1bdd1977",
    descripcion : "00.00",
    cargos: "10",
    abonos: "300.00",
    saldo: "9989.00",
  }
];

const columnStyles = {
  /* font:{
    bold: true,
  }, */
  alignment: { 
    vertical: 'middle', 
    horizontal: 'center' 
  }
}

//revisar
let rows =[];

export default function StatementAccountTable({zoneName}){
  const [loading, setLoading] = useState(true);
  const [info, setInfo] = useState(data);

  const handleExport = async () => {
    const workbook = new ExcelJS.Workbook();// libro
    const worksheet = workbook.addWorksheet('Estado de cuenta'); //hoja

    worksheet.columns = [
      { header: 'Fecha', key: 'fecha', width: 20, style: columnStyles},
      { header: 'Franquicia', key: 'franquicia', width: 30,style: columnStyles},
      { header: 'Descripción', key: 'descripcion', width: 25,style: columnStyles},
      { header: 'Cargos', key: 'cargos', width: 25,style: columnStyles},
      { header: 'Abonos', key: 'abonos', width: 25,style: columnStyles},
      { header: 'Saldo', key: 'saldo', width: 25,style: columnStyles},
    ];  

    info.forEach((element) => {
      rows.push({fecha: element.fecha, franquicia: `${element.franquicia.slice(0,6)}...`, descripcion: element.descripcion, cargos: `${element.cargos}%`, abonos: element.abonos, saldo: element.saldo})
    })

    worksheet.addRows(rows, "n");

    workbook.xlsx.writeBuffer().then(function(buffer) {
      const regex = /\s/g
      const formatedZoneName = zoneName.replace(regex, "_");
      saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        `estados_de_cuenta_${formatedZoneName || ""}.xlsx`
      );
    });
  }

  useEffect(() => {
    const getInfo = async () => {
      setInfo(data)
      setLoading(false);
    }

    getInfo();
  }, []);

  return !loading && (
    <TableContainer sx={tableContainerStyles} component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell sx={tableHeadCellStyles}>Fecha</TableCell>
            <TableCell sx={tableHeadCellStyles} align="center">Franquicia</TableCell>
            <TableCell sx={tableHeadCellStyles} align="center">Descripción</TableCell>
            <TableCell sx={tableHeadCellStyles} align="center">Cargos</TableCell>
            <TableCell sx={tableHeadCellStyles} align="center">Abonos</TableCell>
            <TableCell sx={tableHeadCellStyles} align="center">Saldo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.ID}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell sx={tableCellStyles} component="th" scope="row">
                {row.fecha}
              </TableCell>
              <TableCell sx={tableCellStyles} align="center"><span style={{display: "inline-block", height: "8px", width: "8px", background: "red", borderRadius: "50%", marginRight: "4px", marginBottom: "2px"}}></span>{`#${row.franquicia.slice(0,6)}`}</TableCell>
              <TableCell sx={tableCellStyles} align="center">{row.descripcion}</TableCell>
              <TableCell sx={tableCellStyles} align="center">{`${row.cargos}%`}</TableCell>
              <TableCell sx={tableCellStyles} align="center">{`$${row.abonos}`}</TableCell>
              <TableCell sx={tableCellStyles} align="center">{`$${row.saldo}`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}