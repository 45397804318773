import { useState, useEffect } from "react";
import { makeStyles } from "@mui/styles";
import moment from "moment";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import TabPanel from "./TabPanel";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import DocumentsByShopTable from "./DocumentsByShopTable";
import { sub } from "date-fns";
import { getProfitShop } from "../Business/Infrastructure/Business.presenter";
import HelpIcon from "@mui/icons-material/Help";
import { Button, Icon } from "@mui/material";
import { updateAccountDocs, updateShopDocs } from "../graphql/myMutations";
import { API, graphqlOperation } from "aws-amplify";

const commissionContent = {
  background: "white",
  width: "100%",
  height: "auto",
  borderRadius: "10px",
  marginTop: { md: "20px", lg: "20px" },
  minHeight: "913px",
  padding: { xs: "60px 5px", sm: "40px 20px", md: "36px 40px 59px" },
};

const typeTabs = {
  fontWeight: 700,
  fontFamily: "Montserrat",
  color: "#000",
  textTransform: "none",
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  emptyComissions: {
    marginTop: "60px",
    textAlign: "center",
    fontSize: "1.4rem",
  },
}));

export default function BusinessCommissionsTable({ businessName, businessId }) {
  //INSTANCIA DE LA CLASE STATUS ACCOUNT

  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [firstDate, setFirstDate] = useState(sub(new Date(), { months: 1 }));
  const [secondDate, setSecondDate] = useState(new Date());
  const [docs, setDocs] = useState([]);

  const getBetweenDates = (value) => {
    setFirstDate(moment(value[0]).utc().format("YYYY-MM-DD"));
    setSecondDate(moment(value[1]).utc().format("YYYY-MM-DD"));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    if (businessId) {
      fetchDocuments(businessId);
    }
  }, [businessId, firstDate, secondDate]);

  function addFisica(docs) {
    var arre = [];
    for (var i in docs) {
      if (docs[i].doc === "clabe_documentos") {
        arre.push(docs[i]);
      } else if (docs[i].doc === "comprobante_domicilio") {
        arre.push(docs[i]);
      } else if (docs[i].doc === "constancia_fiscal_id") {
        arre.push(docs[i]);
      } else if (docs[i].doc === "contrato_fisica") {
        arre.push(docs[i]);
      } else if (docs[i].doc === "identificacion") {
        arre.push(docs[i]);
      }
    }
    return arre;
  }
  function addMoral(docs) {
    var arre = [];
    for (var i in docs) {
      if (docs[i].doc === "acta_constitutiva") {
        arre.push(docs[i]);
      } else if (docs[i].doc === "comprobante_domicilio_moral") {
        arre.push(docs[i]);
      } else if (docs[i].doc === "constancia_fiscal_moral") {
        arre.push(docs[i]);
      } else if (docs[i].doc === "contrato_moral") {
        arre.push(docs[i]);
      } else if (docs[i].doc === "identificacion_representante") {
        arre.push(docs[i]);
      }
    }
    return arre;
  }

  function checKDocuments(documenteslegals) {
    if (documenteslegals !== null) {
      var arreFisica = [];
      var arreMoral = [];

      for (var i = 0; i < documenteslegals.length; i++) {
        var list = documenteslegals[i];

        if (list.doc === "contrato_fisica" && list.url != "") {
          arreFisica = addFisica(documenteslegals);
        } else if (list.doc === "contrato_moral" && list.url != "") {
          arreMoral = addMoral(documenteslegals);
        }
      }

      if (arreFisica.length > 0) {
        setDocs(arreFisica);
      } else if (arreMoral.length > 0) {
        setDocs(arreMoral);
      } else {
        setDocs([]);
      }
    } else {
      //No existe el json en la bd
      setDocs({});
    }
  }

  async function updateDocs(ID) {
    const data = [
      { doc: "acta_constitutiva", status: "sended", url: "" },
      {
        doc: "clabe_documentos",
        status: "sended",
        url: "clabe_documentos_SuperJpTiendaDemo",
      },
      {
        doc: "comprobante_domicilio",
        status: "sended",
        url: "comprobante_domicilio_SuperJpTiendaDemo",
      },
      { doc: "comprobante_domicilio_moral", status: "sended", url: "" },
      {
        doc: "constancia_fiscal",
        status: "sended",
        url: "constancia_situacion_fin_SuperJpTiendaDemo",
      },
      { doc: "constancia_fiscal_moral", status: "sended", url: "" },
      {
        doc: "contrato_fisica",
        status: "sended",
        url: "contratoFisico_SuperJpTiendaDemo",
      },
      { doc: "contrato_moral", status: "sended", url: "" },
      {
        doc: "identificacion",
        status: "sended",
        url: "identificacion_SuperJpTiendaDemo",
        dateExpiration: "2022-09-02T23:35:45.948Z",
      },
      { doc: "identificacion_representante", status: "sended", url: "" },
    ];

    const send = {
      id: ID,
      url_documents: JSON.stringify(data),
    };
    const info = await API.graphql(
      graphqlOperation(updateShopDocs, { input: send })
    );
    /* console.log(
      "🚀 ~ file: BusinessDocumentscrud.jsx ~ line 164 ~ updateDocs ~ info",
      info
    ); */
  }

  //Obtener órdenes por negocio
  async function fetchDocuments(id) {
    // updateDocs(id);
    try {
      const info = await getProfitShop({ id, dates: [firstDate, secondDate] });
      var resDocs = JSON.parse(info.data.getShop.url_documents ?? {});
      /*      console.log(resDocs); */

      checKDocuments(resDocs);
    } catch (error) {
      console.log("Errores ", error);
    }
  }

  return (
    <Box sx={commissionContent}>
      <Box className={classes.headerContainer}></Box>
      <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          textColor="secondary"
          indicatorColor="secondary"
          value={value}
          onChange={handleChange}
        >
          <Tab sx={typeTabs} label="Documentos registrados" {...a11yProps(0)} />
          <div
            style={{
              justifyContent: "flex-end",
              alignItems: "center",
              display: "flex",
              width: "100%",
            }}
          >
            <Button
              sx={{ backgroundColor: "#EA5B13", borderRadius: 5 }}
              variant={"contained"}
            >
              <Icon component={HelpIcon} />
            </Button>
          </div>
        </Tabs>
      </Box>

      <div>
        <TabPanel value={value} index={0}>
          <DocumentsByShopTable
            businessId={businessId}
            businessName={businessName}
            data={docs}
          />
        </TabPanel>
      </div>
    </Box>
  );
}
