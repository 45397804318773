import React from 'react';
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import Button from '@mui/material/Button';
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const buttonStyles = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  height: "78px",
  borderRadius: "15px",
  marginTop: "26px"
};

const useStyles = makeStyles((theme) => ({
  text: {
    color: "#4F4F4F",
    fontSize: "0.875rem",
    fontWeight: 500
  },
}));

export default function MenuButton({title, onClick}) {
  const classes = useStyles();

  return (
    <Button
      sx={buttonStyles}
      variant="outlined"
      key={title}
      color="gray"
      endIcon={<ArrowForwardIosIcon color="secondary" />}
      onClick={onClick}
      fullWidth
    >
      <p className={classes.text}>{title}</p>
    </Button>
  );
}

MenuButton.defaultProps = {

};

MenuButton.propTypes = {
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};