import { LogoutService } from "../Infrastructure/Auth.service";
import store from "../../Redux/store";
import { setUserModel } from "../../User/Infrastructure/User.reducer";
import { setBusinessPartnerModel } from "../../BusinessPartner/Infrastructure/BusinessPartner.reducer";

export default async function LogoutLogic() {
  localStorage.removeItem("userid");
  localStorage.removeItem("role");
  localStorage.removeItem("zone");
  localStorage.removeItem("partner");
  localStorage.removeItem("zone_name");
  localStorage.removeItem("business");
  store.dispatch(setUserModel({}));
  store.dispatch(setBusinessPartnerModel({})); 
  return LogoutService();
  /* localStorage.removeItem("zone");
  store.dispatch(setUserModel({}));
  store.dispatch(setAdviserModel({}));*/
}
