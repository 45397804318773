import {
  getBusinessInfoLogic,
  getGeoLocationLogic,
  getRatingShopLogic,
  getOrdersByShopLogic,
  getProfitShopLogic,
  GetPublicDataLogic,
  getclbLogic
} from "../Application/GetBusinessInfo.logic";
import {
  getListOfferProductsLogic,
  getListReviewsLogic,
  GetBusinessListLogic,
  GetBusinessListByCategoryLogic,
  GetBusinessListByNameLogic,
  GetListCategoriesLogic
} from "../Application/GetBusinessList.logic";

import GetOrdersWithFilterLogic from "../Application/GetOrders.logic";
import updateShopStatusLogic from "../Application/UpdateShopStatus.logic"



export async function getClbShop(id){
 return getclbLogic(id);
}

export async function getBusinessInfo(id) {
  return getBusinessInfoLogic(id);
}

export async function getGeoLocationShop(id) {
  return getGeoLocationLogic(id);
}

export async function getRatingShop(id) {
  return getRatingShopLogic(id);
}

export async function getListOfferProducts(id) {
  return getListOfferProductsLogic(id);
}

export async function getListReviews(id) {
  return getListReviewsLogic(id);
}

export async function getOrdersByShop(id) {
  return getOrdersByShopLogic(id);
}

export async function GetBusinessList(zone) {
  return await GetBusinessListLogic(zone);
}

export async function GetBusinessListByCategory(zone, category) {
  return await GetBusinessListByCategoryLogic(zone, category);
}

export async function GetBusinessListByName(zone, name) {
  return await GetBusinessListByNameLogic(zone, name);
}

export async function GetListCategories(){
  return await GetListCategoriesLogic();
}

export async function GetPublicData(id_shop) {
  return await GetPublicDataLogic(id_shop);
}

export async function GetOrdersWithFilter(zone, updatedAt, status){
  return await GetOrdersWithFilterLogic(zone, updatedAt, status);
}

export function getProfitShop(data){
  return getProfitShopLogic(data); 
}

export function updateShopStatus(data){
  return updateShopStatusLogic(data);
}